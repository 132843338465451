import { useFieldState } from 'context/field'
import { useInputAutoResize } from 'hooks/useInputAutoResize'
import { useWrappedRef } from 'hooks/useWrappedRef'
import { forwardRef, FunctionComponent } from 'react'
import {
  TextInput as BaseTextInput,
  TextInputProps,
  View,
  NativeSyntheticEvent,
  TextInputKeyPressEventData
} from 'react-native'
import { ViewStyle } from 'react-native-phone-input'
import { useStyles } from './TextInput.styles'

export const TextInput: FunctionComponent<
  TextInputProps & { containerStyle?: ViewStyle }
> = (props) => {
  const styles = useStyles({ multiline: props.multiline })
  const autoResizeProps = useInputAutoResize(props)

  return (
    <View style={[styles.container, props.containerStyle]}>
      <RawTextInput {...props} {...autoResizeProps} />
    </View>
  )
}

export const RawTextInput = forwardRef<BaseTextInput, TextInputProps>(
  ({ onKeyPress, style, ...props }, ref) => {
    const field = useFieldState()
    const styles = useStyles({ multiline: props.multiline })
    const wrappedRep = useWrappedRef(ref)

    const handleKeyPress = (
      e: NativeSyntheticEvent<TextInputKeyPressEventData>
    ) => {
      if (e.nativeEvent.key === 'Enter') {
        setTimeout(() => field.submit())
        return true
      }

      return onKeyPress?.(e)
    }

    return (
      <BaseTextInput
        data-replicated-value={props.value}
        ref={wrappedRep.ref}
        style={[styles.text, style]}
        {...props}
        onKeyPress={handleKeyPress}
      />
    )
  }
)
