import { Text, TextProps } from 'components/Text/Text'
import { FunctionComponent } from 'react'
import { IconType, ICON_CODEPOINTS } from './Icon.types'

export { IconType }

export type IconProps = Omit<TextProps, 'children'> & {
  type: IconType
  inherit?: boolean
}

export const Icon: FunctionComponent<IconProps> = ({
  style,
  inherit,
  type,
  ...props
}) => (
  <Text inherit={inherit} style={[style, { fontFamily: 'Icons' }]} {...props}>
    {JSON.parse(`"\\u${Number(ICON_CODEPOINTS[type]).toString(16)}"`)}
  </Text>
)
