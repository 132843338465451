import { themed } from 'hooks/useTheme'
import type { ImageStyle, ViewStyle } from 'react-native'

interface Styles {
  container: ViewStyle
  image: ImageStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ misc, colors }): Styles => ({
    container: {
      backgroundColor: colors.overlay
    },

    image: { width: '100%', aspectRatio: 1, borderRadius: misc.imageRadius }
  })
)
