import { Button } from 'components/Button/Button'
import { Field } from 'components/Field/Field'
import { Spacer } from 'components/Spacer/Spacer'
import { PhoneInput, usePhoneInputRef } from 'components/TextInput/PhoneInput'
import { useField, useForm } from 'hooks/useForm'
import { FunctionComponent, useEffect } from 'react'
import * as v from 'utils/validation'
import omit from 'lodash/omit'
import { useAsync } from 'hooks/useAsync'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { useNavigation } from 'hooks/useNavigation'
import { IconType } from 'components/Icon/Icon.types'
import { useService } from 'hooks/useService'
import { sendCampaignInviteService } from 'services/campaigns'

export const SendCampaignInviteForm: FunctionComponent<{
  campaignId: number
  prefillNumber: string
}> = ({ campaignId, prefillNumber }) => {
  const { pop } = useNavigation()
  const phoneRef = usePhoneInputRef()

  const mobileNumber = useField<string>(prefillNumber, 'mobile number', [
    v.required(),
    v.mobileNumber(phoneRef)
  ])

  useEffect(() => {
    phoneRef.current?.setValue(prefillNumber)
    setTimeout(() => mobileNumber.onChange(prefillNumber))
  }, [prefillNumber])

  const sendCampaignInvite = useService(sendCampaignInviteService)

  const sendInvite = useAsync<void, []>(async () => {
    await sendCampaignInvite({ campaignId, mobileNumber: values.mobileNumber })
    pop()
  })

  const { submit, isValid, values } = useForm({ mobileNumber }, sendInvite)

  return (
    <>
      <Field field={mobileNumber} label="Invite mobile number">
        <PhoneInput
          ref={phoneRef}
          onChangePhoneNumber={mobileNumber.onChange}
          initialValue={prefillNumber}
          textProps={{
            maxLength: 15,
            ...omit(mobileNumber.props.textInput, 'onChangeText', 'value')
          }}
        />
      </Field>

      <Spacer />

      <ErrorDisplay error={sendInvite.error} />

      <Button
        type="primary"
        onPress={submit}
        iconLeft={IconType.Envelope}
        loading={sendInvite.isLoading}
        disabled={!isValid}
      >
        Send invite
      </Button>
    </>
  )
}
