import { BlurView, BlurViewProps } from 'expo-blur'
import { forwardRef } from 'react'
import { View } from 'react-native'

type MaybeBlurViewProps = BlurViewProps & { blur?: boolean }

export const MaybeBlurView = forwardRef<View, MaybeBlurViewProps>(
  ({ blur, ...props }, ref) =>
    blur ? <BlurView {...props} ref={ref} /> : <View {...props} ref={ref} />
)
