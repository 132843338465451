import { themed } from 'hooks/useTheme'
import { Platform, RefreshControlProps, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface Styles {
  refreshControlProps: Partial<RefreshControlProps>
  style: ViewStyle
}

export const { use: useStyles } = themed<
  Styles,
  { spaced: boolean; safe: boolean }
>(({ palette, colors }, { spaced, safe }): Styles => {
  const insets = useSafeAreaInsets()

  return {
    refreshControlProps: {
      tintColor: colors.text.default,
      colors: Platform.select({
        android: [palette.background, palette.contrast, palette.accent]
      }),
      progressViewOffset: ((safe && insets.top) || 0) + (spaced ? 100 : 0)
    },

    style: {}
  }
})
