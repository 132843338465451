import { themed } from 'hooks/useTheme'
import type { ImageStyle, ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'

interface Styles {
  container: ViewStyle
  image: ImageStyle
  tag: ViewStyle
  errorTag: ViewStyle
  tagText: TextStyle
  dropDownButton: ViewStyle
  dropDownButtonIcon: TextStyle
}

export const { use: useStyles } = themed<Styles, { disabled: boolean }>(
  ({ misc, palette, colors }, { disabled }): Styles => ({
    container: {
      aspectRatio: 1,
      overflow: 'hidden',
      borderRadius: misc.imageRadius,
      backgroundColor: palette.imageBackground,
      position: 'relative'
    },

    image: { opacity: disabled ? 0.3 : 1, flex: 1 },

    tag: {
      borderRadius: misc.imageRadius,
      backgroundColor: palette.background,
      paddingHorizontal: 15,
      padding: 7
    },

    errorTag: { backgroundColor: palette.error },

    tagText: {
      color: colors.text.light,
      fontSize: 12,
      letterSpacing: 1,
      textTransform: 'uppercase'
    },

    dropDownButton: {
      position: 'absolute',
      right: 25,
      top: 5,
      padding: 8,
      borderRadius: misc.imageRadius
    },

    dropDownButtonIcon: {
      fontSize: 14,
      textShadowColor: 'rgba(0, 0, 0, 1)',
      textShadowRadius: 5,
      textShadowOffset: { width: 1, height: 1 }
    }
  })
)
