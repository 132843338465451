import { themed } from 'hooks/useTheme'
import { TextStyle } from 'react-native-phone-input'
import color from 'tinycolor2'

interface Styles {
  text: TextStyle
  divider: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ fonts, colors }): Styles => ({
    text: { color: colors.text.light, fontFamily: fonts.light },

    divider: { color: color(colors.text.default).setAlpha(0.2).toRgbString() }
  })
)
