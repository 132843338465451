import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import { withResult } from 'hoc/withAsyncResult'
import { useService } from 'hooks/useService'
import { getEntryByIdService } from 'services/entries'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { Spacer } from 'components/Spacer/Spacer'
import { ScrollView } from 'react-native'
import { Fragment, useEffect, useRef } from 'react'
import { EntryChoiceLabels } from 'components/EntryChoiceLabels/EntryChoiceLabels'
import { EntryImages } from './EntryImages/EntryImages'
import { ScrollViewWithHeadImage } from 'components/ScrollViewWithHeadImage/ScrollViewWithHeadImage'
import { DetailedEntryInfo } from 'types/entries'
import { IconType } from 'components/Icon/Icon.types'
import { RowWithIcon } from 'components/RowWithIcon/RowWithIcon'
import { TextBitDisplay } from './TextBitDisplay/TextBitDisplay'
import { FooterActions } from 'components/FooterActions/FooterActions'
import { useEntryPolling, useEntryViewState } from './EntryView.hooks'
import { useNavigation } from 'hooks/useNavigation'
import { getEntryImageDropDownItems } from 'utils/entry-image'

export const EntryView = withResult(
  ({ route }: RouteProps<'Entry'>) => {
    const getEntryById = useService(getEntryByIdService)
    const canEditRef = useRef(false)

    return async (entryData?: DetailedEntryInfo) => {
      if (entryData) {
        return { entry: entryData, canEdit: canEditRef.current }
      }

      const { entry, canEdit } = (await getEntryById({ id: route.params.id }))
        .data

      canEditRef.current = canEdit ?? canEditRef.current

      return { entry, canEdit }
    }
  },
  ({ entry, canEdit, reload, isReloading }) => {
    const scrollView = useRef<ScrollView>(null)
    const {
      displayData,
      isEditMode,
      textBitProps,
      startEdit,
      cancelEdit,
      saveEdit,
      editData,
      setEditData
    } = useEntryViewState(entry, reload)
    const navigation = useNavigation()

    const { isLoading } = useEntryPolling(entry, reload, isReloading)

    useEffect(() => {
      scrollView.current?.scrollTo({ y: 0, animated: false })
    }, [entry.defaultImage?.url])

    return (
      <>
        <DefaultLayout safe={false}>
          <ScrollViewWithHeadImage
            ref={scrollView}
            scrollEventThrottle={20}
            reload={reload}
            isLoading={isLoading}
            headImage={{
              source: { uri: entry.defaultImage.url ?? undefined },
              contentRelevant: true,
              actions: !!entry.defaultImage.url
                ? getEntryImageDropDownItems({
                    entryId: entry.id,
                    imageId: entry.defaultImage.id,
                    navigation
                  })
                : undefined
            }}
          >
            <DefaultLayout.Content>
              <RowWithIcon
                icon={
                  canEdit && !isLoading && !isEditMode ? IconType.Edit : null
                }
                onPress={startEdit}
              >
                <TextBitDisplay
                  {...textBitProps}
                  type="displayName"
                  onChange={(value) =>
                    setEditData({ ...editData, displayName: value })
                  }
                  value={displayData.displayName}
                />
              </RowWithIcon>

              <Spacer size={0.5} />

              <EntryChoiceLabels choices={entry.choices} />

              <Spacer />

              {displayData.textBits.map(({ value, type }, i) => {
                return (
                  <Fragment key={i}>
                    <TextBitDisplay
                      {...textBitProps}
                      value={value}
                      onChange={(value) =>
                        setEditData({
                          ...editData,
                          textBits: [
                            ...editData.textBits.slice(0, i),
                            { ...editData.textBits[i]!, value },
                            ...editData.textBits.slice(i + 1)
                          ]
                        })
                      }
                      type={type}
                      key={i}
                    />

                    <Spacer />
                  </Fragment>
                )
              })}
              <EntryImages entry={entry} onChangeEntry={reload} />
            </DefaultLayout.Content>
          </ScrollViewWithHeadImage>
        </DefaultLayout>

        <FooterActions
          show={isEditMode}
          buttons={[
            { iconLeft: IconType.Cross, text: 'Cancel', onPress: cancelEdit },
            {
              iconLeft: IconType.Tick,
              type: 'primary',
              text: 'Save',
              onPress: saveEdit
            }
          ]}
        />
      </>
    )
  }
)
