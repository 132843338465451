import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import headImage from './assets/head-image.png'
import { Text } from 'components/Text/Text'
import { Subtitle, Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'

export const OfflineView: FunctionComponent = () => (
  <DefaultLayout headImage={{ source: headImage }}>
    <DefaultLayout.Content>
      <Title center>Oops!</Title>
      <Subtitle center>You’re offline :(</Subtitle>

      <Spacer />

      <Text center>
        Dungeon minion requires an active internet connection to work.
      </Text>
    </DefaultLayout.Content>
  </DefaultLayout>
)
