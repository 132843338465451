import { useEffect, useRef, useState } from 'react'
import { TextInputProps } from 'react-native'
import { AsyncAction } from './useAsync'
import { useDeferred } from './useDeferred'

export type Validator<T> = (val: T, key: string) => null | string

export const useField = <T>(
  initial: T,
  key: string,
  validators: Validator<T>[] = []
) => {
  const [value, setValue] = useState<T>(initial)
  const [focused, setFocused] = useState(false)
  const [pristine, setPristine] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [valid, setValid] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const submitRef = useRef<() => void>(() => {})

  const onFocus = () => setFocused(true)

  const onBlur = () => {
    setFocused(false)
    setPristine(false)
    validate(value)
  }

  const validate = (newValue: T = value) => {
    for (const validator of validators) {
      const err = validator(newValue, key)

      if (err) {
        setValid(false)
        setError(err)
        return false
      }
    }

    setValid(true)
    setError(null)

    return true
  }

  const onChange = (value: T) => {
    setValue(value)
    validate(value)
  }

  const textInputProps: TextInputProps = {
    onChangeText: onChange as (val: string) => void,
    value: value as string,
    onFocus,
    onBlur
  }

  return {
    available: true,
    value,
    onChange,
    onFocus,
    onBlur,
    focused,
    pristine,
    submitted,
    setSubmitted,
    valid,
    error,
    submitRef,
    submit: submitRef.current,
    props: { textInput: textInputProps }
  }
}

export type FieldState<T> = ReturnType<typeof useField<T>>

export const useForm = <T extends {}>(
  fields: { [Key in keyof T]: FieldState<T[Key]> },
  onValidSubmit?: AsyncAction<void, []> | (() => void)
) => {
  const fieldsArr = Object.values(fields) as FieldState<any>[]
  const fieldKeys = Object.keys(fields) as Array<keyof T>
  const getIsValid = () => !fieldsArr.find(({ valid }) => !valid)
  const isValid = getIsValid()
  const valuesArr = fieldsArr.map(({ value }) => value)
  const values = fieldKeys.reduce(
    (cur, key) => ({ ...cur, [key]: fields[key]?.value }),
    {}
  ) as T

  const submit = useDeferred(() => {
    fieldsArr.forEach(
      ({ submitted, setSubmitted }) => !submitted && setSubmitted(true)
    )

    isValid &&
      onValidSubmit &&
      ('start' in onValidSubmit ? onValidSubmit.start : onValidSubmit)?.()
  })

  for (const field of fieldsArr) {
    if (field?.submitRef) {
      field.submitRef.current = submit
    }
  }

  useEffect(() => {
    onValidSubmit && 'reset' in onValidSubmit && onValidSubmit?.reset()
  }, valuesArr)

  return { fields, submit, isValid, values }
}

export type FormState = ReturnType<typeof useForm>
