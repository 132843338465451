import { Portal } from '@gorhom/portal'
import { BottomTabBar, BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { Link } from '@react-navigation/native'
import { Logo, LogoSize } from 'components/Logo/Logo'
import { NavigationIcon } from 'components/NavigationIcon/NavigationIcon'
import { Text } from 'components/Text/Text'
import { useAuth } from 'hooks/useAuth'
import { useMedia } from 'hooks/useMedia'
import { FunctionComponent } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AppNavigationRoutes } from '../AppNavigation.types'
import { useStyles } from './HeaderNavigation.styles'

export const ResponsiveTabBar: FunctionComponent<BottomTabBarProps> = (
  props
) => {
  const { isDesktop } = useMedia()

  if (isDesktop) {
    return <HeaderNavigation {...props} />
  }

  return <BottomTabBar {...props} />
}

export const HeaderNavigation: FunctionComponent<BottomTabBarProps> = ({
  state: { routes, index: activeIndex }
}) => {
  const styles = useStyles()
  const { user } = useAuth()

  return (
    <Portal hostName="navigation-start">
      <View style={styles.header}>
        <SafeAreaView>
          <View style={styles.inner}>
            <Link to={{ screen: 'Main' }}>
              <Logo size={LogoSize.Small} />
            </Link>

            <View style={styles.navigation}>
              {routes.map(({ name, params }, index) => {
                const isActive = activeIndex === index
                const screenName = name as keyof AppNavigationRoutes

                return (
                  <Link
                    to={{ screen: screenName, params: params as {} }}
                    style={[styles.navLink, isActive && styles.navLinkActive]}
                    key={name}
                  >
                    <NavigationIcon
                      screenName={screenName}
                      style={[
                        styles.navLinkIcon,
                        isActive && styles.navLinkIconActive
                      ]}
                    />

                    <Text
                      style={[
                        styles.navLinkText,
                        isActive && styles.navLinkTextActive
                      ]}
                    >
                      {name}
                      {user && screenName === 'Credits'
                        ? ` (${user.credits})`
                        : null}
                    </Text>
                  </Link>
                )
              })}
            </View>
          </View>
        </SafeAreaView>
      </View>
    </Portal>
  )
}
