import { useKeyboard } from 'hooks/useKeyboard'
import { FunctionComponent, useEffect, useRef } from 'react'
import { Animated, Easing, Platform, ViewProps } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

type FooterOverlayProps = ViewProps

export const FooterOverlay: FunctionComponent<FooterOverlayProps> = ({
  style,
  ...props
}) => {
  const { keyboardHeight, keyboardWillBeShown } = useKeyboard()
  const insets = useSafeAreaInsets()
  const translateY = useRef(new Animated.Value(insets.bottom)).current

  if (Platform.OS === 'ios') {
    useEffect(() => {
      if (!!keyboardHeight) {
        translateY.setValue(keyboardHeight)
      }
    }, [keyboardHeight])

    useEffect(() => {
      Animated.timing(translateY, {
        toValue: keyboardWillBeShown ? insets.bottom : keyboardHeight,
        duration: 500,
        useNativeDriver: true,
        easing: Easing.in(Easing.elastic(1))
      }).start()
    }, [keyboardWillBeShown])
  }

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          width: '100%',
          bottom: 0,
          paddingBottom:
            Platform.select({ ios: keyboardHeight, default: 0 }) +
            insets.bottom,
          transform: [{ translateY }]
        },
        style
      ]}
      {...props}
    />
  )
}
