import { DarkTheme } from '@react-navigation/native'
import {
  LinkingSreensConfig,
  StackNavigatorOptions,
  TabNavigatorOptions
} from './AppNavigation.types'
import {
  TabIcon,
  TabLabel,
  TabsBackground,
  TabBar
} from './AppNavigation.partials'

export const AppNavigationTheme: typeof DarkTheme = { ...DarkTheme }

export const defaultStackNavigatorProps: StackNavigatorOptions = {
  screenOptions: { headerShown: false, fullScreenGestureEnabled: true }
}

export const defaultTabNavigatorProps: TabNavigatorOptions = {
  tabBar: (props) => <TabBar {...props} />,
  screenOptions: ({ route }) => ({
    headerShown: false,
    tabBarBackground: () => <TabsBackground />,
    tabBarStyle: { borderTopWidth: 0 },
    tabBarIcon: ({ focused }) => <TabIcon route={route} focused={focused} />,
    tabBarLabel: ({ focused }) => <TabLabel route={route} focused={focused} />
  })
}

export const LINKING_ROUTES_CONFIG: LinkingSreensConfig = {
  Home: {
    screens: { Main: '/me', Campaigns: '/campaigns', Account: '/account' }
  },
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  VerificationCode: '/auth/code',
  NewEntry: '/generate',
  NewEntryByType: '/generate/:type?',
  ListUserEntries: '/entries/me',
  Entry: '/entry/:id',
  ViewEntryImage: '/entry/:entryId/images/:imageId',
  CreateCampaign: '/campaigns/new',
  CampaignInvites: '/campaigns/:id/invites',
  SendCampaignInvite: '/campaigns/:id/invites/new',
  ViewCampaignEntries: '/campaigns/:id/entries',
  PrivacyPolicy: '/privacy-policy',
  Credits: '/credits'
}
