export interface GenerationOption {
  type: string
  id: string
  label: string
  tags?: string[]
  dependsOn?: Array<[string, string[]]>
}

export interface GeneratorTreeStepOptions {
  title: string
  optional?: boolean
  showImages?: boolean
  multiple?: boolean
  showLabels?: boolean
}

export interface GeneratorTreeOptions {
  name: string
  description: string
  steps: Record<string, GeneratorTreeStepOptions>
}

export type GenerationTypesMap = Record<string, GeneratorTreeOptions>

export type RandomValue = { random: true }

export type GenerationChoices = Record<
  string,
  GenerationChoiceValue | GenerationChoiceValue[]
>

export type GenerationChoiceValue = string | RandomValue | null

export const RANDOM_VALUE: RandomValue = { random: true }
