import { FunctionComponent, useState } from 'react'
import { Pressable, PressableProps, View, ViewProps } from 'react-native'

const OPACITY_PRESSED = 0.8

type MaybePressableProps = (ViewProps & PressableProps) & {
  feedback?: 'opacity' | null
  disabled?: boolean
}

export const MaybePressable: FunctionComponent<MaybePressableProps> = ({
  feedback = 'opacity',
  disabled = false,
  onPress,
  ...baseProps
}) => {
  const [isPressed, setIsPressed] = useState(false)
  const style: MaybePressableProps['style'] = [
    baseProps.style,
    feedback === 'opacity'
      ? { opacity: isPressed && !disabled ? OPACITY_PRESSED : undefined }
      : null
  ]
  const props = { ...baseProps, style }

  if (onPress) {
    return (
      <Pressable
        {...props}
        style={style}
        onPress={disabled ? undefined : onPress}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
      />
    )
  }

  return <View {...props} />
}
