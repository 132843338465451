import { themed } from 'hooks/useTheme'
import { TextStyle, ViewStyle } from 'react-native'

interface Styles {
  container: ViewStyle
  title?: string
  text: TextStyle
}

export const { use: useStyles } = themed<Styles, boolean>(
  ({ palette, misc, colors }, fullScreen): Styles => ({
    container: {
      backgroundColor: palette.error,
      borderRadius: misc.radius,
      alignSelf: 'stretch',
      marginBottom: 20,
      paddingVertical: 10,
      paddingHorizontal: 20,

      ...(fullScreen
        ? {
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 0
          }
        : {})
    },

    text: { color: colors.text.default }
  })
)
