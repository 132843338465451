import { useFonts } from 'hooks/useFonts'
import { FunctionComponent, PropsWithChildren } from 'react'

export const FontsProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children
}) => {
  if (!useFonts()) {
    return null
  }

  return <>{children}</>
}
