import { LinearGradientProps } from 'expo-linear-gradient'
import { themed } from 'hooks/useTheme'
import { Dimensions, ImageStyle, ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'
import color from 'tinycolor2'

interface Styles {
  scrollView: ViewStyle
  scrollContent: ViewStyle
  container: ViewStyle
  exitTrap: ViewStyle
  spacer: ViewStyle
  option: ViewStyle
  optionImage: ImageStyle
  labelContainer: ViewStyle
  label: TextStyle
  labelGradientProps: LinearGradientProps
  imageLabel: TextStyle
  tag: ViewStyle
  tagLabel: TextStyle
  closeButton: ViewStyle
}

export const { use: useStyles } = themed<
  Styles,
  { showImages?: boolean; selected?: boolean }
>(
  ({ palette, misc, colors }, { showImages, selected }): Styles => ({
    scrollView: {
      flexGrow: 0,
      maxWidth: 500,
      maxHeight: Dimensions.get('window').height - 200,
      marginHorizontal: 10,
      borderRadius: misc.radius,
      backgroundColor: colors.overlay,
      marginBottom: 20
    },

    scrollContent: {
      // flexWrap: 'wrap',
      // flexDirection: 'row',
    },

    container: {
      borderRadius: misc.radius,
      alignContent: 'center',
      marginBottom: -5
    },

    exitTrap: { position: 'absolute', width: '100%', height: '100%' },

    spacer: { width: '100%', flexGrow: 1 },

    option: {
      borderRadius: misc.imageRadius,
      overflow: 'hidden',
      position: 'relative',
      borderWidth: 1,
      borderColor: 'transparent',
      ...(showImages ? { width: '100%', aspectRatio: 1 } : {}),
      ...(showImages && selected ? { borderColor: palette.accent } : {})
    },

    labelContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-end'
    },

    labelGradientProps: {
      style: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.7
      },
      colors: [
        color(palette.black).setAlpha(0).toRgbString(),
        color(palette.black).setAlpha(0).toRgbString(),
        color(palette.black).setAlpha(1).toRgbString(),
        color(palette.black).setAlpha(1).toRgbString()
      ]
    },

    label: {
      fontSize: 14,
      color: selected ? palette.accent : colors.text.overlay,
      textAlign: 'center'
    },

    imageLabel: {
      textAlign: 'center',
      marginBottom: 5,
      paddingHorizontal: 5
    },

    optionImage: { flex: 1 },

    tag: {
      borderRadius: misc.imageRadius,
      justifyContent: 'center',
      paddingVertical: 8,
      paddingHorizontal: 15,
      borderWidth: 1,
      borderColor: palette.mid,
      ...(selected
        ? { borderColor: palette.accent, backgroundColor: palette.accent }
        : {})
    },

    tagLabel: selected ? { color: colors.overlay } : {},

    closeButton: {
      marginTop: 5,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  })
)
