import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import headImage from './assets/head-image.png'
import { Text } from 'components/Text/Text'
import { Subtitle, Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'
import { SignUpForm } from './SignUpView.form'
import { FooterFormWrap } from 'components/FooterFormWrap/FooterFormWrap'

export const SignUpView: FunctionComponent = () => (
  <DefaultLayout headImage={{ source: headImage, subtle: true }}>
    <DefaultLayout.Content>
      <Title center>Sign up</Title>
      <Subtitle center>It takes a minute</Subtitle>

      <Spacer />

      <Text center>
        Enter your mobile number below and receive an activation code.
      </Text>
    </DefaultLayout.Content>

    <FooterFormWrap>
      <DefaultLayout.Footer>
        <SignUpForm />
      </DefaultLayout.Footer>
    </FooterFormWrap>
  </DefaultLayout>
)
