import { Text } from 'components/Text/Text'
import { themed } from 'hooks/useTheme'
import { FunctionComponent } from 'react'
import { TextProps, TextStyle, View, ViewStyle, ViewProps } from 'react-native'

interface Styles {
  container: ViewStyle
  item: TextStyle
  itemText: TextStyle
  bullet: TextStyle
}

const List: FunctionComponent<ViewProps> = ({ style, ...props }) => (
  <View style={[useStyles().container, style]} {...props} />
)

const Item: FunctionComponent<TextProps> = ({ style, ...props }) => {
  const styles = useStyles()

  return (
    <View style={styles.item}>
      <Text style={styles.bullet}>•</Text>

      <Text style={[styles.itemText, style]} {...props} />
    </View>
  )
}

const { use: useStyles } = themed<Styles>(
  ({ colors }): Styles => ({
    container: {},
    item: { flexDirection: 'row' },
    itemText: { color: colors.text.light },
    bullet: { opacity: 0.3, marginRight: 10 }
  })
)

export const BulletList = Object.assign(List, { Item })
