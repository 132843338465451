import { AxiosResponse } from 'axios'
import { getApi, typedService } from 'core/api'
import { useAuthToken } from './useAuthToken'

export const useService = <P, R>(
  service: ReturnType<typeof typedService<P, R>>
) => {
  const token = useAuthToken()
  const api = getApi(token)

  return async (payload: P): Promise<AxiosResponse<R>> => service(api, payload)
}
