import { Subtitle, Title } from 'components/Heading/Heading'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { listCampaignInvitesService } from 'services/campaigns'
import { Text } from 'components/Text/Text'
import { Spacer } from 'components/Spacer/Spacer'
import { Button } from 'components/Button/Button'
import { IconType } from 'components/Icon/Icon.types'
import { useNavigation } from 'hooks/useNavigation'
import { withResult } from 'hoc/withAsyncResult'
import { useTabFocus } from 'hooks/useTabFocus'
import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import {
  ItemsListItem,
  SectionItemsList,
  SectionListData
} from 'components/ItemsList/ItemsList'
import { CampaignInvite, CampaignUserInfo } from 'types/campaigns'
import { notNil } from 'utils/object'
import { useStyles } from './CampaignInvitesView.styles'

export const CampaignInvitesView = withResult(
  ({ route }: RouteProps<'CampaignInvites'>) => {
    const listCampaignInvites = useService(listCampaignInvitesService)

    return async () =>
      (await listCampaignInvites({ campaignId: route.params.id })).data
  },
  ({ campaign, invites, users, reload }) => {
    const { push } = useNavigation()
    const styles = useStyles()
    const showList = !!(invites.length || users.length)

    useTabFocus(() => {
      reload()
    })

    return (
      <DefaultLayout toBottomEdge>
        <DefaultLayout.Header>
          <Title center>Campaign invites</Title>
          <Spacer />
          <Subtitle>{campaign.title}</Subtitle>
        </DefaultLayout.Header>

        {!showList ? (
          <DefaultLayout.Content>
            <Text center>
              No invites for this campaign yet
              {'\n\n'}
              Invite one party memeber to start sharing creations
            </Text>

            <Spacer />
          </DefaultLayout.Content>
        ) : (
          <SectionItemsList<any>
            onRefresh={reload}
            stretch={false}
            sections={[
              invites.length
                ? ({
                    title: 'Invites',
                    data: invites,
                    renderItem: ({ item: invite }) => (
                      <ItemsListItem icon={IconType.Envelope} key={invite.id}>
                        <Text style={styles.displayNameText}>
                          {invite.mobileNumber}
                        </Text>

                        <Text style={styles.statusText}>
                          <Text style={styles.statusLabel}>Status:</Text>{' '}
                          Pending
                        </Text>
                      </ItemsListItem>
                    )
                  } as SectionListData<CampaignInvite>)
                : null,

              users.length
                ? ({
                    title: 'Members',
                    data: users,
                    renderItem: ({ item: user }) => (
                      <ItemsListItem icon={IconType.User} key={user.id}>
                        <Text style={styles.displayNameText}>
                          {user.displayName}
                          {user.isOwner && ` (Owner)`}
                        </Text>

                        <Text style={styles.statusText}>
                          <Text style={styles.statusLabel}>Status:</Text> Joined
                        </Text>
                      </ItemsListItem>
                    )
                  } as SectionListData<CampaignUserInfo>)
                : null
            ].filter(notNil)}
          />
        )}

        <DefaultLayout.Footer>
          <Button
            type="primary"
            iconLeft={IconType.Plus}
            onPress={() => push('SendCampaignInvite', { id: campaign.id })}
          >
            New invite
          </Button>
        </DefaultLayout.Footer>
      </DefaultLayout>
    )
  }
)
