import AsyncStorage from '@react-native-async-storage/async-storage'
import { AppStorageData, INITIAL_APP_STORAGE_STATE } from 'context/app-storage'
import { AppStorageContext } from 'context/app-storage'
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'

const APP_STORAGE_KEY = '@app-storage'
const APP_STORAGE_VERSION = 1

export const AppStorageProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children
}) => {
  const [data, setData] = useState<AppStorageData>({
    ...INITIAL_APP_STORAGE_STATE.data
  })
  const [error, setError] = useState<Error | null>(null)
  const [isInitialised, setIsInitialised] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    init()
  }, [])

  const clearStorage = async () => {
    setData(INITIAL_APP_STORAGE_STATE.data)
    await AsyncStorage.removeItem(APP_STORAGE_KEY)
  }

  const init = async () => {
    try {
      setError(null)

      const rawData = await AsyncStorage.getItem(APP_STORAGE_KEY)
      const loadedData = rawData ? JSON.parse(rawData) : null

      loadedData &&
        data.version !== null &&
        data.version !== APP_STORAGE_VERSION &&
        (await clearStorage())

      loadedData && setData(loadedData)
      setIsLoading(false)
      setIsInitialised(true)
    } catch (error) {
      setError(error as Error)
      setIsLoading(false)
    }
  }

  const setValue = async (value: Partial<AppStorageData>) => {
    const updatedData: AppStorageData = {
      ...data,
      ...value,
      version: APP_STORAGE_VERSION
    }

    try {
      await AsyncStorage.setItem(APP_STORAGE_KEY, JSON.stringify(updatedData))
      setData(updatedData)
    } catch (error) {
      setError(error as Error)
    }
  }

  return (
    <AppStorageContext.Provider
      value={{ data, isInitialised, isLoading, error, setValue }}
    >
      {children}
    </AppStorageContext.Provider>
  )
}
