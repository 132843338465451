import { themed } from 'hooks/useTheme'
import type { ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'
import color from 'tinycolor2'

interface Styles {
  container: ViewStyle
  icon: TextStyle
}

export const { use: useStyles } = themed<
  Styles,
  { isPressed: boolean; disabled: boolean }
>(
  ({ palette, misc, colors }, { disabled }): Styles => ({
    container: {
      width: 45,
      aspectRatio: 1,
      backgroundColor: color(palette.foreground)
        .setAlpha(disabled ? 0.3 : 1)
        .toRgbString(),
      borderRadius: misc.radius * 0.8,
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.5
    },

    icon: {
      fontSize: 25,
      color: disabled ? palette.background : colors.text.default
    }
  })
)
