import { AbsoluteCenter } from 'components/AbsoluteCenter/AbsoluteCenter'
import { FadingSpinner } from 'components/FadingSpinner/FadingSpinner'
import { forwardRef, useEffect, useRef, useState } from 'react'
import {
  Animated,
  Easing,
  Image,
  ImageProps,
  ImageStyle,
  Platform,
  View,
  ViewProps
} from 'react-native'
import { useStyles } from './FadingImage.styles'

type AnimatedImage = Animated.AnimatedComponent<typeof Image>

export type FadingImageVariant = 'default' | 'dark'

const DEFAULT_FADE_MS = 200

type FadingImageProps = ImageProps & {
  opacity?: number
  canBlur?: boolean
  blur?: number
  onLoadChange?: (hasLoaded: boolean) => void
}

export const FadingImage = forwardRef<AnimatedImage, FadingImageProps>(
  (
    {
      fadeDuration = DEFAULT_FADE_MS,
      onLoadChange,
      opacity: maxOpacity = 1,
      canBlur: userCanBlur = false,
      blur: blurTo = 0,
      ...props
    },
    ref
  ) => {
    const canBlur = userCanBlur && Platform.OS === 'ios'
    const [hasLoaded, setHasLoaded] = useState(false)
    const opacity = useRef(new Animated.Value(0)).current
    const blur = useRef(new Animated.Value(canBlur ? blurTo : 0)).current
    const useNativeDriver = !canBlur

    useEffect(() => {
      opacity.stopAnimation()

      Animated.timing(opacity, {
        toValue: +hasLoaded * maxOpacity,
        duration: fadeDuration,
        easing: Easing.out(Easing.quad),
        useNativeDriver
      }).start()
    }, [hasLoaded, maxOpacity])

    useEffect(() => {
      if (!canBlur) {
        return
      }

      blur.stopAnimation()

      Animated.timing(blur, {
        toValue: blurTo,
        duration: fadeDuration,
        easing: Easing.out(Easing.quad),
        useNativeDriver
      }).start()
    }, [blurTo])

    return (
      <Animated.Image
        ref={ref}
        {...props}
        blurRadius={blur}
        fadeDuration={fadeDuration}
        onLoadStart={() => {
          if (Platform.OS === 'web' || Platform.OS === 'android') {
            return
          }

          setHasLoaded(false)
          onLoadChange?.(false)
          return props.onLoadEnd?.()
        }}
        onLoadEnd={() => {
          setHasLoaded(true)
          onLoadChange?.(true)
          return props.onLoadEnd?.()
        }}
        style={[
          { opacity },
          props.style,
          Platform.OS === 'web'
            ? ({ filter: `blur(${blurTo}px)` } as ImageStyle)
            : undefined
        ]}
      />
    )
  }
)

export const FadingImageView = forwardRef<
  AnimatedImage,
  ImageProps & { containerProps?: ViewProps; variant?: FadingImageVariant }
>(({ containerProps = {}, variant, ...props }, ref) => {
  const styles = useStyles()
  const [hasLoaded, setHasLoaded] = useState(false)

  return (
    <View
      {...containerProps}
      style={[
        styles.default.container,
        variant && styles[variant]?.container,
        containerProps.style
      ]}
    >
      <FadingImage
        {...props}
        onLoadChange={setHasLoaded}
        ref={ref}
        style={[
          styles.default.image,
          variant && styles[variant]?.image,
          props.style
        ]}
      />

      <AbsoluteCenter>
        <FadingSpinner
          show={!hasLoaded}
          size="small"
          {...styles.default.spinnerProps}
          {...((variant && styles[variant]?.spinnerProps) || {})}
        />
      </AbsoluteCenter>
    </View>
  )
})
