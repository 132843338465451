import { themed } from 'hooks/useTheme'
import { FunctionComponent } from 'react'
import { Text as BaseText, TextProps, TextStyle } from 'react-native'

export type { TextProps, TextStyle }

interface ExtraTextProps {
  center?: boolean
  bold?: boolean
  lightColor?: boolean
  light?: boolean
  italic?: boolean
  inherit?: boolean
}

export const Text: FunctionComponent<TextProps & ExtraTextProps> = ({
  style,
  inherit,
  ...props
}) => <BaseText style={[!inherit && useStyles(props), style]} {...props} />

const { use: useStyles } = themed<TextStyle, ExtraTextProps>(
  (
    { colors, fonts },
    { center, bold, lightColor, light, italic }: ExtraTextProps
  ): TextStyle => ({
    color: lightColor ? colors.text.light : colors.text.default,
    fontSize: 16,
    fontWeight: 'normal',
    fontFamily: light
      ? fonts.light
      : italic
      ? fonts.mainItalic
      : bold
      ? fonts.bold
      : fonts.main,
    textAlign: center ? 'center' : undefined
  })
)
