import { typedService } from 'core/api'
import type { PrivateUserInfo, UserPricing } from 'types/user'

export const requestSignInService = typedService<
  { mobileNumber: string },
  void
>('post', 'auth/login')

export const verifyCodeService = typedService<
  { code: string },
  | {
      action: 'activation' | 'login'
      user: PrivateUserInfo
      token: string
      pricing: UserPricing
    }
  | { action: 'deletion' }
>('post', 'auth/verify')
