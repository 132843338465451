import { Title } from 'components/Heading/Heading'
import { EntriesList } from 'components/EntriesList/EntriesList'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { listUserEntriesService } from 'services/entries'
import { Text } from 'components/Text/Text'
import { Spacer } from 'components/Spacer/Spacer'
import { Button } from 'components/Button/Button'
import { IconType } from 'components/Icon/Icon.types'
import { useNavigation } from 'hooks/useNavigation'
import { withResult } from 'hoc/withAsyncResult'
import { useTabFocus } from 'hooks/useTabFocus'
import { listCampaignsService } from 'services/campaigns'
import headImage from './assets/head-image.png'

export const ListUserEntriesView = withResult(
  () => {
    const listUserEntries = useService(listUserEntriesService)
    const listUserCampaigns = useService(listCampaignsService)

    return async () => {
      const [entriesRes, campaignsRes] = await Promise.all([
        listUserEntries(),
        listUserCampaigns()
      ])

      return {
        entries: entriesRes.data.entries,
        campaigns: campaignsRes.data.campaigns
      }
    }
  },
  ({ entries, campaigns, reload, isReloading }) => {
    const { navigate } = useNavigation()

    useTabFocus(() => {
      reload()
    })

    return (
      <DefaultLayout toBottomEdge headImage={{ source: headImage, subtle: 4 }}>
        <DefaultLayout.Header>
          <Title center>Creations</Title>
        </DefaultLayout.Header>

        {!entries?.length ? (
          <>
            <DefaultLayout.Content>
              <Text center>
                There’s nothing here yet.
                {'\n\n'}
                Create your first character, it only takes a minute!
              </Text>

              <Spacer />
            </DefaultLayout.Content>

            <DefaultLayout.Footer>
              <Button
                type="primary"
                iconLeft={IconType.Plus}
                onPress={() =>
                  navigate('NewEntryByType', { type: 'character' })
                }
              >
                New character
              </Button>
            </DefaultLayout.Footer>
          </>
        ) : (
          <EntriesList
            isLoading={isReloading}
            entries={entries}
            campaigns={campaigns}
            reload={reload}
          />
        )}
      </DefaultLayout>
    )
  }
)
