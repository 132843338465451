import { useEffect, useRef } from 'react'

export const usePolling = (
  dependant: unknown,
  condition: () => boolean,
  fn: () => void,
  interval = 200
) => {
  const timeout = useRef<NodeJS.Timer | null>(null)

  const repeat = () => {
    if (!condition()) {
      return
    }

    timeout.current = setTimeout(() => {
      fn()
      repeat()
    }, interval)
  }

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }

    repeat()

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [dependant])
}
