import { themed } from 'hooks/useTheme'
import { Platform } from 'react-native'
import { TextStyle, ViewStyle } from 'react-native-phone-input'

interface Styles {
  container: ViewStyle
  input: TextStyle
  cell: TextStyle
  focusCell: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ palette, misc, fonts, colors }): Styles => ({
    container: {
      flexDirection: 'row',
      maxWidth: 500,
      alignSelf: 'stretch'
    },

    input: {
      position: 'absolute',
      opacity: 0,
      width: '100%',
      height: '100%'
    },

    cell: {
      flex: 1,
      height: 60,
      lineHeight: Platform.OS === 'android' ? 37 : 60,
      backgroundColor: colors.input,
      marginHorizontal: 5,
      borderRadius: misc.radius,
      overflow: 'hidden',
      textAlign: 'center',
      textAlignVertical: 'center',
      fontSize: 30,
      fontFamily: fonts.main,
      color: colors.text.default
    },

    focusCell: { backgroundColor: palette.accent }
  })
)
