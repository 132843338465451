import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import headImage from './assets/head-image.png'
import { Text } from 'components/Text/Text'
import { Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'
import { SignInForm } from './SignInView.form'
import { FooterFormWrap } from 'components/FooterFormWrap/FooterFormWrap'

export const SignInView: FunctionComponent = () => (
  <DefaultLayout headImage={{ source: headImage, subtle: true }}>
    <DefaultLayout.Content>
      <Title center>Sign in</Title>

      <Spacer />

      <Text center>
        Enter your mobile number below and receive a sign in code.
      </Text>
    </DefaultLayout.Content>

    <FooterFormWrap>
      <DefaultLayout.Footer>
        <SignInForm />
      </DefaultLayout.Footer>
    </FooterFormWrap>
  </DefaultLayout>
)
