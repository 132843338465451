import { useFieldState } from 'context/field'
import { themed } from 'hooks/useTheme'
import { Platform, TextStyle, ViewStyle } from 'react-native'

interface Styles {
  container: ViewStyle
  text: TextStyle
  phoneContainer: ViewStyle
  phoneText: TextStyle
}

const TEXT_INPUT_HEIGHT = 65

export const { use: useStyles } = themed<Styles, { multiline?: boolean }>(
  ({ colors, palette, misc }, { multiline = false } = {}): Styles => {
    const field = useFieldState()

    return {
      container: {
        backgroundColor: colors.input,
        borderColor: 'transparent',
        alignSelf: 'stretch',
        height: multiline ? undefined : TEXT_INPUT_HEIGHT,
        borderRadius: misc.radius,
        borderWidth: 1,

        ...(field.available && !field.pristine && !field.valid
          ? { borderColor: palette.error }
          : {}),

        ...(field.focused ? { borderColor: palette.mid } : {}),

        ...(field.available && field.valid
          ? { borderColor: palette.success }
          : {})
      },

      text: {
        paddingHorizontal: 20,
        flex: multiline ? undefined : 1,
        color: colors.text.light,
        fontSize: 18,
        paddingTop: multiline ? 20 : 0,
        paddingBottom: multiline ? 20 : 0,
        lineHeight: multiline
          ? undefined
          : Platform.select({ web: TEXT_INPUT_HEIGHT }),
        ...Platform.select({ web: { outline: 0 } })
      },

      phoneContainer: {
        paddingLeft: 20
      },

      phoneText: {
        paddingLeft: 0
      }
    }
  }
)
