export enum IconType {
  Alert = "alert",
  Book = "book",
  CaretLeft = "caret-left",
  CaretRight = "caret-right",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  Clear = "clear",
  Cog = "cog",
  Credit = "credit",
  Cross = "cross",
  D20 = "d20",
  Dice_02 = "dice-02",
  Dice_03 = "dice-03",
  Dice = "dice",
  DotsVertical = "dots-vertical",
  Dots = "dots",
  Download = "download",
  Edit = "edit",
  Envelope = "envelope",
  Eye = "eye",
  Hamburger = "hamburger",
  List = "list",
  Magic = "magic",
  Magnifier = "magnifier",
  Menu = "menu",
  Minus = "minus",
  Mobile = "mobile",
  Plus = "plus",
  Reload = "reload",
  SignIn = "sign-in",
  SignOut = "sign-out",
  Tick = "tick",
  Trash = "trash",
  User = "user",
}

export const ICON_CODEPOINTS: { [key in IconType]: string } = {
  [IconType.Alert]: "61697",
  [IconType.Book]: "61698",
  [IconType.CaretLeft]: "61699",
  [IconType.CaretRight]: "61700",
  [IconType.ChevronLeft]: "61701",
  [IconType.ChevronRight]: "61702",
  [IconType.Clear]: "61703",
  [IconType.Cog]: "61704",
  [IconType.Credit]: "61705",
  [IconType.Cross]: "61706",
  [IconType.D20]: "61707",
  [IconType.Dice_02]: "61708",
  [IconType.Dice_03]: "61709",
  [IconType.Dice]: "61710",
  [IconType.DotsVertical]: "61711",
  [IconType.Dots]: "61712",
  [IconType.Download]: "61713",
  [IconType.Edit]: "61714",
  [IconType.Envelope]: "61715",
  [IconType.Eye]: "61716",
  [IconType.Hamburger]: "61717",
  [IconType.List]: "61718",
  [IconType.Magic]: "61719",
  [IconType.Magnifier]: "61720",
  [IconType.Menu]: "61721",
  [IconType.Minus]: "61722",
  [IconType.Mobile]: "61723",
  [IconType.Plus]: "61724",
  [IconType.Reload]: "61725",
  [IconType.SignIn]: "61726",
  [IconType.SignOut]: "61727",
  [IconType.Tick]: "61728",
  [IconType.Trash]: "61729",
  [IconType.User]: "61730",
};
