import { HeadImage, HeadImageProps } from 'components/HeadImage/HeadImage'
import {
  MaybeSafeView,
  MaybeSafeViewProps
} from 'components/MaybeSafeView/MaybeSafeView'
import { StatusBar } from 'expo-status-bar'
import { forwardRef } from 'react'
import { ScrollView, ScrollViewProps, View } from 'react-native'
import { useStyles } from './DefaultLayout.styles'

const Layout = forwardRef<
  View,
  MaybeSafeViewProps & { headImage?: HeadImageProps; toBottomEdge?: boolean }
>(({ style, headImage, safe = true, toBottomEdge, ...props }, ref) => {
  const styles = useStyles()

  return (
    <>
      <View style={styles.background} />

      <StatusBar {...styles.statusBarProps} />

      {headImage && <HeadImage {...headImage} />}

      <View style={styles.outer}>
        <MaybeSafeView
          safe={safe}
          style={[styles.container, style]}
          edges={toBottomEdge ? ['top', 'right', 'left'] : undefined}
          {...props}
          ref={ref}
        />
      </View>
    </>
  )
})

const Content = forwardRef<View, MaybeSafeViewProps>(
  ({ style, ...props }, ref) => (
    <MaybeSafeView style={[useStyles().content, style]} {...props} ref={ref} />
  )
)

const Header = forwardRef<View, MaybeSafeViewProps>(
  ({ style, ...props }, ref) => (
    <MaybeSafeView style={[useStyles().header, style]} {...props} ref={ref} />
  )
)

const HeaderAndContent = forwardRef<View, MaybeSafeViewProps>(
  ({ style, ...props }, ref) => (
    <MaybeSafeView
      style={[useStyles().headerAndContent, style]}
      {...props}
      ref={ref}
    />
  )
)

const ScrollContent = forwardRef<ScrollView, ScrollViewProps>(
  ({ style, ...props }, ref) => (
    <ScrollView
      style={[useStyles().scrollContent, style]}
      {...props}
      ref={ref}
    />
  )
)

const Footer = forwardRef<View, MaybeSafeViewProps>(
  ({ style, ...props }, ref) => (
    <MaybeSafeView style={[useStyles().footer, style]} {...props} ref={ref} />
  )
)

export const DefaultLayout = Object.assign(Layout, {
  Content,
  ScrollContent,
  Header,
  HeaderAndContent,
  Footer
})
