import { useDimensions } from '@react-native-community/hooks'

export interface MediaOptions {
  isTablet: boolean
  isDesktop: boolean
}

export const useMedia = (): MediaOptions => {
  const { window } = useDimensions()

  return { isTablet: window.width > 600, isDesktop: window.width > 1000 }
}
