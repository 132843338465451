import { themed } from 'hooks/useTheme'
import { SpinnerVariant } from './Spinner'
import color from 'tinycolor2'

type Styles = Record<SpinnerVariant, { color: string }>

export const { use: useStyles } = themed<Styles>(
  ({ palette, colors }): Styles => ({
    default: { color: colors.text.default },
    foreground: { color: palette.foreground },
    subtle: { color: color(colors.text.default).setAlpha(0.2).toRgbString() }
  })
)
