import { RawErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { Icon } from 'components/Icon/Icon'
import { IconType } from 'components/Icon/Icon.types'
import { Text } from 'components/Text/Text'
import { ToastState, TOAST_TRANSITION_MS, useToaster } from 'context/toaster'
import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react'
import { Animated, Easing, Pressable, View } from 'react-native'
import { useStyles } from './Toast.styles'

export type ToastType = 'error' | 'success'

export type ToastOptions = PropsWithChildren<{
  type: ToastType
  text?: string
  error?: Error
}>

const ICONS: Partial<Record<ToastType, IconType>> = {
  error: IconType.Alert,
  success: IconType.Tick
}

export const Toast: FunctionComponent<ToastState> = ({
  children,
  text,
  type,
  error,
  id,
  status
}) => {
  const styles = useStyles()
  const show = useRef(new Animated.Value(0)).current
  const { closeToast } = useToaster()
  const icon = ICONS[type]

  useEffect(() => {
    Animated.timing(show, {
      toValue: +(status === 'show'),
      easing: Easing.out(Easing.ease),
      duration: TOAST_TRANSITION_MS,
      useNativeDriver: true
    }).start()
  }, [status])

  const textStyles = [styles.base.text, styles[type].text]

  return (
    <Animated.View
      style={[styles.base.container, styles[type].container, { opacity: show }]}
    >
      <Pressable
        style={[styles.base.inner, styles[type].inner]}
        onPress={() => closeToast(id)}
      >
        {icon && (
          <Icon
            style={[...textStyles, styles.base.icon, styles[type].icon]}
            type={icon}
          />
        )}

        <View style={[styles.base.content, styles[type].content]}>
          {children}
          {text && <Text style={textStyles}>{text}</Text>}
          {error && (
            <RawErrorDisplay error={error || null} style={textStyles} />
          )}
        </View>
      </Pressable>
    </Animated.View>
  )
}
