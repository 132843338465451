import { PURE_CHOICES } from 'constants/features'
import {
  GenerationChoices,
  GenerationChoiceValue,
  GenerationOption,
  GeneratorTreeOptions,
  GeneratorTreeStepOptions,
  RANDOM_VALUE
} from 'types/generation'
import { pickRandom } from 'utils/array'
import { isRandom } from 'utils/choices'

const getTagsFromChoices = (
  options: GenerationOption[],
  choices: GenerationChoices
) => {
  const tags: string[] = []

  for (const step of Object.keys(choices)) {
    const value = choices[step]

    if (!!value && !isRandom(value)) {
      const match = options.find((o) => o.type === step && o.id === value)

      if (match && match.tags) {
        tags.push(...match.tags)
      }
    }
  }

  return tags
}

export const getAvailableGenerationOptions = (
  step: string,
  options: GenerationOption[],
  choices: GenerationChoices
): GenerationOption[] => {
  const tags = getTagsFromChoices(options, choices)

  return [
    ...options.filter(
      (option) =>
        option.type === step &&
        (!option.dependsOn || hasDependant(option.dependsOn, choices, tags))
    )
  ]
}

export const getStepChoices = (
  steps: Record<string, GeneratorTreeStepOptions>,
  step: string,
  options: GenerationOption[],
  choices: GenerationChoices,
  includeMeta = true
): Array<{ label: string; value: GenerationChoiceValue }> => {
  const { optional } = steps[step] || {}
  const pureChoices = getAvailableGenerationOptions(step, options, choices).map(
    (option) => ({ label: option.label, value: option.id })
  )

  return includeMeta
    ? [
        { label: 'Random', value: RANDOM_VALUE },
        ...(optional ? [{ label: 'None', value: null }] : []),
        ...pureChoices
      ]
    : pureChoices
}

const hasDependant = (
  dependsOn: Exclude<GenerationOption['dependsOn'], undefined>,
  choices: GenerationChoices,
  tags: string[]
) => {
  for (const dependency of dependsOn) {
    const [target, values] = dependency

    if (target === 'tags') {
      if (!values.find((v) => !!tags.includes(v))) {
        return false
      }
    } else {
      const choice = choices[target as string]

      if (!choice || typeof choice !== 'string' || !values.includes(choice)) {
        return false
      }
    }
  }

  return true
}

export const getRandomChoices = (
  steps: GeneratorTreeOptions['steps'],
  options: GenerationOption[],
  choices: GenerationChoices
) => {
  if (PURE_CHOICES) {
    return Object.keys(steps).reduce((cur, step) => {
      const { value } = pickRandom(
        getStepChoices(steps, step, options, cur, false)
      ) || { value: null }

      return {
        ...choices,
        ...cur,
        [step]: steps[step]?.multiple ? [value] : value
      }
    }, {})
  }

  return Object.keys(steps).reduce(
    (cur, step) => ({ ...cur, [step]: RANDOM_VALUE }),
    {}
  )
}

export const getOptionalClearedChoices = (
  steps: GeneratorTreeOptions['steps'],
  choices: GenerationChoices
) =>
  Object.keys(steps).reduce(
    (cur, step) => ({
      ...cur,
      [step]: steps[step]?.optional ? null : choices[step]!
    }),
    { ...choices }
  )

export const getChoicesPayload = (
  steps: GeneratorTreeOptions['steps'],
  options: GenerationOption[],
  choices: GenerationChoices
): Record<string, string | null> =>
  Object.keys(steps).reduce((cur, step) => {
    const curValue = choices[step]
    const { optional, multiple } = steps[step] || {}

    if (isRandom(curValue || null)) {
      const available = getAvailableGenerationOptions(step, options, {
        ...choices,
        ...cur
      })

      const randomPick =
        pickRandom(optional ? [null, ...available] : available)?.id || null

      return { ...cur, [step]: multiple ? [randomPick] : randomPick }
    }

    return { ...cur, [step]: curValue }
  }, {})
