import { ThemeContext } from 'context/theme'
import { useContext } from 'react'
import { Theme } from 'types/theme'
import { MediaOptions, useMedia } from './useMedia'

export const themed = <T, Arg = void>(
  fn: (theme: Theme & { media: MediaOptions }, arg: Arg) => T
): { use: (arg: Arg) => T } => ({
  use: (arg: Arg) => {
    const media = useMedia()
    return fn({ ...useContext(ThemeContext).theme, media }, arg)
  }
})

export const useTheme = () => useContext(ThemeContext).theme
