import { IconType } from 'components/Icon/Icon'
import { ItemsListItem } from 'components/ItemsList/ItemsList'
import { Text } from 'components/Text/Text'
import { FunctionComponent } from 'react'
import { getChoiceImageUrl, isRandom } from 'utils/choices'
import { WizardState } from '../../GenerateWizardView.types'
import { useStyles } from './ChoiceButton.styles'
import { notNil } from 'utils/object'

interface ChoiceButtonProps {
  step: string
  wizardState: WizardState
  onPress: () => void
  onPressEmpty?: () => void
  onPressRandom?: () => void
  showImages?: boolean
}

export const ChoiceButton: FunctionComponent<ChoiceButtonProps> = ({
  wizardState: { getStepConfig, choices, getChoiceData },
  step,
  onPress,
  onPressEmpty,
  showImages,
  onPressRandom
}) => {
  const { title, showLabels = true, multiple } = getStepConfig(step) || {}
  const styles = useStyles()
  const value = choices[step] || null
  const label = getChoiceData(step, value)?.label

  return (
    <ItemsListItem
      onPress={onPress}
      imageSource={{
        uri: getChoiceImageUrl(
          step,
          showImages ? value : 'default',
          '140',
          'jpeg'
        )
      }}
      inlineActions={[
        onPressEmpty && {
          icon: IconType.Clear,
          disabled: !value,
          onPress: onPressEmpty
        },
        onPressRandom && {
          icon: IconType.Dice,
          disabled: isRandom(value),
          onPress: onPressRandom
        }
      ].filter(notNil)}
    >
      <Text style={styles.label}>{title}</Text>
      <Text style={(isRandom(value) || !value) && styles.metaValue}>
        {value === null || isRandom(value) || (showLabels && !multiple)
          ? label
          : 'Selected'}
      </Text>
    </ItemsListItem>
  )
}
