import { themed } from 'hooks/useTheme'
import { TextStyle } from 'react-native-phone-input'

interface Styles {
  label: TextStyle
  metaValue: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ fonts }): Styles => ({
    label: { fontFamily: fonts.bold, marginRight: 20 },
    metaValue: { fontFamily: fonts.mainItalic, opacity: 0.5 }
  })
)
