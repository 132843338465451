import { Button } from 'components/Button/Button'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { Title } from 'components/Heading/Heading'
import { IconType } from 'components/Icon/Icon.types'
import { Spacer } from 'components/Spacer/Spacer'
import { useNavigation } from 'hooks/useNavigation'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'

interface ErrorViewProps {
  error: Error
  title?: string
  retry?: () => void
}

export const ErrorView: FunctionComponent<ErrorViewProps> = ({
  error,
  title = 'Oops!',
  retry
}) => {
  const { popToTop } = useNavigation()

  return (
    <DefaultLayout>
      <DefaultLayout.Content>
        <Title center>{title}</Title>
        <Spacer />
        <ErrorDisplay error={error} />
      </DefaultLayout.Content>

      <DefaultLayout.Footer>
        <Button
          type="primary"
          onPress={retry || popToTop}
          iconLeft={retry ? IconType.Reload : IconType.CaretLeft}
        >
          {retry ? 'Retry' : 'Back'}
        </Button>
      </DefaultLayout.Footer>
    </DefaultLayout>
  )
}
