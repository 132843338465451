import { LegalPage } from 'components/LegalPage/LegalPage'
import { FunctionComponent } from 'react'
import { PrivacyPolicy } from './markup/PrivacyPolicy'

export const PrivacyPolicyView: FunctionComponent = () => (
  <LegalPage
    title="Privacy policy"
    path="privacy-policy"
    content={PrivacyPolicy}
  />
)
