export const Z_INDEX = {
  BACKGROUND: 0,
  HEAD_IMAGE: 0,
  FOREGROUND: 10,
  FOOTER_FORM_WRAP: 20,
  DROP_DOWN: 50,
  MODAL: 100,
  TOASTER: 110
}

export const MS = {
  OVERLAY_FADE: 200
}

export const HEADER_HEIGHT = 90

export const BASE_UI_IMAGES_URL =
  'https://dm-app-ui-images.s3.us-west-2.amazonaws.com'
