import { forwardRef } from 'react'
import { View, ViewProps } from 'react-native'

export const Row = forwardRef<View, ViewProps>(({ style, ...props }, ref) => (
  <View
    style={[{ flexDirection: 'row', alignItems: 'center' }, style]}
    {...props}
    ref={ref}
  />
))
