import { API_URL, DEV } from '@env'
import Constants from 'expo-constants'

const required = (value?: string) => {
  if (!value) {
    throw new Error(`Missing required configuration key: ${value}`)
  }

  return value
}

export const getConfig = () => ({
  apiUrl: required(Constants.expoConfig?.extra?.apiUrl || API_URL),
  dev: DEV === 'true'
})
