import { typedService } from 'core/api'
import { DetailedEntryInfo } from 'types/entries'
import {
  GenerationOption,
  GenerationTypesMap,
  GeneratorTreeOptions
} from 'types/generation'

export const loadGenerationTypesService = typedService<
  void,
  { types: GenerationTypesMap }
>('get', 'generation/types')

export const loadGenerationOptionsService = typedService<
  { type: string },
  { tree: GeneratorTreeOptions; options: GenerationOption[] }
>('get', 'generation/{type}/options')

export const generateEntryService = typedService<
  { type: string; choices: Record<string, string | null> },
  { entry: DetailedEntryInfo }
>('post', 'generation/{type}', { reloadAuth: true })
