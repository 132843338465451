import { Button } from 'components/Button/Button'
import { DropDown } from 'components/DropDown/DropDown'
import { Subtitle, Title } from 'components/Heading/Heading'
import { IconType } from 'components/Icon/Icon.types'
import { Spacer } from 'components/Spacer/Spacer'
import { useAuth } from 'hooks/useAuth'
import { useNavigation } from 'hooks/useNavigation'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent, useState } from 'react'
import { requestUserDeletionService } from 'services/user'
import headImage from './assets/head-image.png'

export const AccountView: FunctionComponent = () => {
  const { signOut } = useAuth()
  const [confirmingAction, setConfirmingAction] = useState<
    'delete-account' | null
  >(null)
  const requestUserDeletion = useService(requestUserDeletionService)
  const { navigate } = useNavigation()
  const confirm = async () => {
    if (confirmingAction === 'delete-account') {
      await requestUserDeletion()
      navigate('VerificationCode')
    }
  }

  return (
    <>
      <DropDown
        close={() => setConfirmingAction(null)}
        items={[{ label: 'Confirm', icon: IconType.Tick, onSelect: confirm }]}
        isShown={!!confirmingAction}
      />

      <DefaultLayout
        toBottomEdge
        headImage={{ source: headImage, subtle: true }}
      >
        <DefaultLayout.HeaderAndContent>
          <Title center>Account</Title>
          <Subtitle center>User settings and actions</Subtitle>
        </DefaultLayout.HeaderAndContent>

        <DefaultLayout.Footer>
          <Button
            outline
            type="danger"
            iconLeft={IconType.Trash}
            onPress={() => setConfirmingAction('delete-account')}
          >
            Delete account
          </Button>

          <Spacer />

          <Button type="bright" iconLeft={IconType.SignOut} onPress={signOut}>
            Sign out
          </Button>
        </DefaultLayout.Footer>
      </DefaultLayout>
    </>
  )
}
