import { Title } from 'components/Heading/Heading'
import { useTheme } from 'hooks/useTheme'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import type { LegalPageProps } from './LegalPage'

export const LegalPage: FunctionComponent<LegalPageProps> = ({
  title,
  content: Content
}) => {
  const { colors, fonts, palette } = useTheme()

  return (
    <>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
        .legal-content {
            color: ${colors.text.default};
            font-family: ${fonts.main};
        }

        a {
            color: ${palette.accent};
            text-decoration: none;
        }
    `
        }}
      />

      <DefaultLayout>
        <DefaultLayout.Header>
          <Title>{title}</Title>
        </DefaultLayout.Header>

        <DefaultLayout.ScrollContent>
          <DefaultLayout.Content>
            <div className="legal-content">
              <Content />
            </div>
          </DefaultLayout.Content>
        </DefaultLayout.ScrollContent>
      </DefaultLayout>
    </>
  )
}
