import { themed } from 'hooks/useTheme'
import type { ViewStyle } from 'react-native'

interface Styles {
  container: ViewStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ sizes }): Styles => ({
    container: {
      maxWidth: '100%',
      width: sizes.smallContentWidth,
      alignSelf: 'center'
    }
  })
)
