import { Ref, useRef } from 'react'

export const useWrappedRef = <T>(
  ref: Ref<T> | undefined
): { ref: (val: T) => void; getCurrent: () => T | undefined } => {
  const innerRef = useRef<T>()

  const outFn = (newVal: T) => {
    innerRef.current = newVal

    if (typeof ref === 'function') {
      ref(newVal)
    } else if (ref && 'current' in ref) {
      Object.defineProperty(ref, 'current', { value: newVal })
    }
  }

  return { ref: outFn, getCurrent: () => innerRef.current }
}
