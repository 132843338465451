import { Icon, IconType } from 'components/Icon/Icon'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { FunctionComponent, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useStyles } from './IconButton.styles'

export interface IconButtonProps {
  icon: IconType
  onPress?: () => void
  disabled?: boolean
  style?: StyleProp<ViewStyle>
}

export const IconButton: FunctionComponent<IconButtonProps> = ({
  icon,
  disabled = false,
  onPress,
  style
}) => {
  const [isPressed, setIsPressed] = useState(false)
  const styles = useStyles({ disabled, isPressed })

  return (
    <MaybePressable
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
      onPress={onPress}
      disabled={disabled}
      style={[styles.container, style]}
    >
      <Icon type={icon} style={styles.icon} />
    </MaybePressable>
  )
}
