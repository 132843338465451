import { useToaster } from 'context/toaster'
import { FunctionComponent } from 'react'
import { View } from 'react-native'
import { Toast } from './Toast'
import { useStyles } from './ToasterOverlay.styles'

export const ToasterOverlay: FunctionComponent = () => {
  const { toasts } = useToaster()
  const styles = useStyles()

  if (toasts.length) {
    return (
      <View style={styles.container}>
        {toasts.map((props) => (
          <Toast key={props.id} {...props} />
        ))}
      </View>
    )
  }

  return null
}
