import { themed } from 'hooks/useTheme'
import { Platform, ViewStyle } from 'react-native'

interface Styles {
  absoluteCover: ViewStyle
  container: ViewStyle
  inner: ViewStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ palette }): Styles => ({
    absoluteCover: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    },

    container: Platform.select({
      default: {},
      android: { backgroundColor: palette.background },
      web: { backdropFilter: 'blur(5px)' } as ViewStyle
    }),

    inner: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)
