import { FunctionComponent, useState } from 'react'
import { Animated, ImageSourcePropType, View } from 'react-native'
import { FadingImage } from 'components/FadingImage/FadingImage'
import { FadingMaskView } from 'components/FadingMaskView/FadingMaskView'
import { DropDownButton, DropDownItemProps } from 'components/DropDown/DropDown'
import { useStyles } from './HeadImage.styles'

export type HeadImageProps = (
  | { source: ImageSourcePropType; pollUrl?: undefined }
  | { pollUrl: string; source?: undefined }
) & {
  subtle?: boolean | number
  contentRelevant?: boolean
  backgroundOpacity?: number
  canBlur?: boolean
  blur?: number
  inBackground?: number
  actions?: DropDownItemProps[]
  blurred?: boolean
}

export const HeadImage: FunctionComponent<HeadImageProps> = ({
  source,
  subtle,
  contentRelevant = false,
  blur = 0,
  blurred,
  actions
}) => {
  const styles = useStyles({ contentRelevant })
  const opacity = subtle ? 0.3 / Number(subtle) : 1
  const [clickableHeight, setClickableHeight] = useState(0)

  const rendered = (
    <>
      <Animated.View
        style={styles.container}
        onLayout={({ nativeEvent: { layout } }) =>
          setClickableHeight(layout.height)
        }
      >
        <FadingMaskView style={styles.mask} type={styles.maskType}>
          {source && (
            <FadingImage
              canBlur
              source={source}
              style={styles.image}
              opacity={opacity}
              blur={blur}
            />
          )}
        </FadingMaskView>
      </Animated.View>

      {actions && !blurred && (
        <View
          style={[
            styles.dropDownButtonContainer,
            { height: clickableHeight - styles.clickTrapSpacing }
          ]}
        >
          <DropDownButton
            icon={null}
            style={styles.dropDownButton}
            items={actions}
          />
        </View>
      )}
    </>
  )

  return rendered
}
