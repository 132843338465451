import { typedService } from 'core/api'
import { PrivateUserInfo, UserPricing } from 'types/user'

export const registerUserService = typedService<
  { displayName: string; mobileNumber: string },
  void
>('post', 'users')

export const getCurrentUserService = typedService<
  void,
  { user: PrivateUserInfo; pricing: UserPricing }
>('get', 'users/me')

export const requestUserDeletionService = typedService<void, void>(
  'delete',
  'users/me'
)
