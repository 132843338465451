import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import { Text } from 'components/Text/Text'
import { Subtitle, Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'
import { StatusScreenOptions } from 'types/app-status'
import { ImageSourcePropType, Linking } from 'react-native'
import { Button } from 'components/Button/Button'
import { IconType } from 'components/Icon/Icon'

interface StatusScreenProps {
  defaultScreenOptions: StatusScreenOptions
  headImage?: ImageSourcePropType
  screenOptions?: StatusScreenOptions
}

export const StatusScreen: FunctionComponent<StatusScreenProps> = ({
  defaultScreenOptions,
  screenOptions = defaultScreenOptions,
  headImage
}) => (
  <DefaultLayout
    headImage={headImage ? { source: headImage, subtle: true } : undefined}
  >
    <DefaultLayout.Content>
      {screenOptions.title && <Title center>{screenOptions.title}</Title>}

      {screenOptions.subtitle && (
        <Subtitle center>{screenOptions.subtitle}</Subtitle>
      )}

      <Spacer />

      <Text center>{screenOptions.text}</Text>
    </DefaultLayout.Content>

    {screenOptions.link && (
      <DefaultLayout.Footer>
        <Button
          type="primary"
          iconRight={IconType.CaretRight}
          onPress={() => Linking.openURL(screenOptions.link!.url)}
        >
          {screenOptions.link.text}
        </Button>
      </DefaultLayout.Footer>
    )}
  </DefaultLayout>
)

export const getStatusScreen =
  (fixedProps: Omit<StatusScreenProps, 'screenOptions'>) =>
  (props: Pick<StatusScreenProps, 'screenOptions'>) =>
    <StatusScreen {...fixedProps} {...props} />
