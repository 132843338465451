import { Z_INDEX } from 'constants/ui'
import { themed } from 'hooks/useTheme'
import { Dimensions, Platform, ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'
import { DropDownFrom } from './DropDown'

interface Styles {
  button: ViewStyle
  buttonIconOpen: TextStyle
  wrapper: ViewStyle
  exitTrap: ViewStyle
  container: ViewStyle
  item: ViewStyle
  itemInner: ViewStyle
  itemIcon: TextStyle
  itemLabel: TextStyle
  confirmation: ViewStyle
  confirmationLabel: TextStyle
}

export const { use: useStyles } = themed<
  Styles,
  {
    from?: DropDownFrom
    active?: boolean
    disabled?: boolean
    tight?: boolean
    textAlign?: 'left' | 'center'
  } | void
>(
  (
    { palette, colors, fonts, sizes },
    { from = 'bottom', active, disabled, tight, textAlign = 'center' } = {}
  ): Styles => {
    const textColor = active ? palette.accent : colors.text.overlay

    const fontSize = tight ? 14 : 18

    return {
      button: {
        position: 'relative',
        zIndex: Z_INDEX.FOREGROUND,
        padding: 20,
        marginRight: -20
      },

      buttonIconOpen: { zIndex: Z_INDEX.DROP_DOWN },

      wrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%'
      },

      exitTrap: { flex: 1 },

      container: {
        backgroundColor: colors.overlay,
        position: Platform.select({
          web: 'fixed',
          default: 'absolute'
        }) as ViewStyle['position'],
        width: '100%',
        paddingVertical: 20,
        maxHeight: Dimensions.get('window').height * 0.7,

        ...(from === 'top' ? { top: 0 } : { bottom: 0 })
      },

      item: { alignSelf: 'stretch', opacity: disabled && !active ? 0.3 : 1 },

      itemInner: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: tight ? 10 : 30,
        paddingHorizontal: 30
      },

      itemIcon: {
        opacity: 0.2,
        marginRight: 10,
        color: textColor,
        fontSize
      },

      itemLabel: {
        fontSize,
        color: textColor,
        textAlign,
        width: textAlign === 'left' ? '100%' : undefined,
        maxWidth: textAlign === 'left' ? sizes.smallContentWidth : undefined
      },

      confirmation: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },

      confirmationLabel: { fontFamily: fonts.mainItalic }
    }
  }
)
