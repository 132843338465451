import { Spinner, SpinnerProps } from 'components/Spinner/Spinner'
import { forwardRef, useEffect, useRef } from 'react'
import { ActivityIndicator, Animated, ViewProps } from 'react-native'

export type FadingSpinnerProps = ViewProps & {
  show?: boolean
  size?: SpinnerProps['size']
  color?: SpinnerProps['color']
  variant?: SpinnerProps['variant']
  spinnerProps?: Omit<SpinnerProps, 'size' | 'color'>
}

export const FadingSpinner = forwardRef<ActivityIndicator, FadingSpinnerProps>(
  ({ show = true, spinnerProps, size, color, variant, ...props }, ref) => {
    const opacity = useRef(new Animated.Value(0)).current

    useEffect(() => {
      Animated.timing(opacity, {
        toValue: +show,
        duration: 200,
        useNativeDriver: true
      }).start()
    }, [show])

    return (
      <Animated.View {...props} style={[props.style, { opacity }]}>
        <Spinner
          {...spinnerProps}
          size={size}
          color={color}
          variant={variant}
          ref={ref}
        />
      </Animated.View>
    )
  }
)
