import { FunctionComponent } from 'react'
import { View } from 'react-native'

const SPACER_UNIT = 10

interface SpacerProps {
  size?: number
  horizontal?: boolean
}

export const Spacer: FunctionComponent<SpacerProps> = ({
  size = 2,
  horizontal = false
}) => <View style={{ [horizontal ? 'width' : 'height']: SPACER_UNIT * size }} />
