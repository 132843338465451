import { getConfig } from 'core/config'
import { Linking } from 'react-native'

export const downloadEntryImage = ({
  entryId,
  imageId
}: Record<'entryId' | 'imageId', number>) =>
  Linking.openURL(
    `${getConfig().apiUrl}/entries/${entryId}/images/${imageId}/download`
  )

export const getDownloadEntryImageUrl = ({
  entryId,
  imageId
}: Record<'entryId' | 'imageId', number>) =>
  `${getConfig().apiUrl}/entries/${entryId}/images/${imageId}/download`
