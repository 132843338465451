import { useAsync } from 'hooks/useAsync'
import { FunctionComponent } from 'react'
import { ErrorView } from 'view/ErrorView/ErrorView'
import { LoadingView } from 'view/LoadingView/LoadingView'

export function withResult<P, T>(
  useGetAsyncLoader: (props: P) => () => Promise<T>,
  Wrapped: FunctionComponent<T & { reload: () => void; isReloading: boolean }>
) {
  const Component: FunctionComponent<P> = (props) => {
    const asyncLoader = useGetAsyncLoader(props)
    const {
      error,
      result,
      isLoading: isReloading,
      start
    } = useAsync(asyncLoader, {
      initRun: true,
      initWith: []
    })

    if (error) {
      return <ErrorView error={error} retry={start} />
    }

    if (!result) {
      return <LoadingView />
    }

    return <Wrapped {...result} reload={start} isReloading={isReloading} />
  }

  return Component
}
