import type { FunctionComponent } from 'react'
import { BlurView } from 'expo-blur'
import { Platform, Pressable, StyleProp, ViewStyle } from 'react-native'
import { FadingView } from 'components/FadingView/FadingView'
import { MS } from 'constants/ui'
import { useStyles } from './Overlay.styles'

interface OverlayProps {
  opacity?: number
  blur?: number
  style?: StyleProp<ViewStyle>
  show?: boolean
  onPress?: () => void
  duration?: number
}

const DEFAULT_BLUR = 40

export const Overlay: FunctionComponent<OverlayProps> = ({
  style,
  onPress,
  opacity = 1,
  blur = DEFAULT_BLUR,
  duration = MS.OVERLAY_FADE,
  show
}) => {
  const styles = useStyles()

  return (
    <FadingView
      duration={duration}
      show={show}
      showOpacity={Platform.select({ default: 1, android: opacity })}
      style={[styles.absoluteCover, styles.container, style]}
    >
      <Pressable style={[styles.absoluteCover, styles.inner]} onPress={onPress}>
        {Platform.select({
          ios: (
            <BlurView
              style={styles.absoluteCover}
              intensity={blur}
              tint="dark"
            />
          )
        })}
      </Pressable>
    </FadingView>
  )
}
