import headImage from './assets/head-image.png'
import { getStatusScreen } from 'components/StatusScreen/StatusScreen'
import { Platform } from 'react-native'
import { APP_STORE_LINKS } from 'constants/app-store'

export const UpgradeRequiredView = getStatusScreen({
  headImage,
  defaultScreenOptions: {
    title: 'Update required',
    subtitle: 'App version no longer supported',
    text: [
      'This version of the app is no longer supported.',
      'Please upgrade to the latest version.'
    ].join('\n'),
    link: Platform.select({
      ios: { text: 'Update', url: APP_STORE_LINKS.APPLE },
      android: { text: 'Update', url: APP_STORE_LINKS.GOOGLE }
    })
  }
})
