import { useState, useRef, useEffect } from 'react'

export const useSafeState = <V>(defaultValue: V): [V, (updated: V) => void] => {
  const [value, setValue] = useState<V>(defaultValue)
  const isMounted = useRef(true)
  const setValueIfMounted = (updated: V) =>
    isMounted.current && setValue(updated)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return [value, setValueIfMounted]
}
