import { SpinnerProps } from 'components/Spinner/Spinner'
import { themed } from 'hooks/useTheme'
import type { ImageStyle, ViewStyle } from 'react-native'
import { FadingImageVariant } from './FadingImage'

type Styles = Record<
  FadingImageVariant,
  { container: ViewStyle; image: ImageStyle; spinnerProps: SpinnerProps }
>

export const { use: useStyles } = themed<Styles>(
  ({ palette, misc }): Styles => ({
    default: {
      container: {
        borderRadius: misc.imageRadius,
        overflow: 'hidden',
        backgroundColor: palette.imageBackground
      },
      image: { flex: 1 },
      spinnerProps: { variant: 'foreground' }
    },

    dark: {
      container: { backgroundColor: palette.black },
      image: {},
      spinnerProps: { variant: 'subtle' }
    }
  })
)
