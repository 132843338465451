import { ImageDisplay } from 'components/ImageDisplay/ImageDisplay'
import { Grid } from 'components/Grid/Grid'
import { FunctionComponent } from 'react'
import { DetailedEntryInfo } from 'types/entries'
import { useService } from 'hooks/useService'
import { setDefaultImageService } from 'services/entries'
import { useAsync } from 'hooks/useAsync'
import { ImageInfo } from 'types/images'
import { useStyles } from './EntryImages.styles'
import { useToaster } from 'context/toaster'
import { getEntryImageDropDownItems } from 'utils/entry-image'
import { useNavigation } from 'hooks/useNavigation'

interface EntryImagesProps {
  entry: DetailedEntryInfo
  onChangeEntry: (entry: DetailedEntryInfo) => void
}

export const EntryImages: FunctionComponent<EntryImagesProps> = (props) => (
  <Grid columns={2} columnsDesktop={4} style={useStyles().container}>
    {props.entry.images.map((image) => (
      <EntryImageItem {...props} image={image} key={image.id} />
    ))}
  </Grid>
)

export const EntryImageItem: FunctionComponent<
  EntryImagesProps & { image: ImageInfo }
> = ({ entry, onChangeEntry, image }) => {
  const isDefault = image.id === entry.defaultImage.id
  const setDefault = useService(setDefaultImageService)
  const { showToast } = useToaster()
  const navigation = useNavigation()
  const setDefaultAction = useAsync(async () => {
    try {
      onChangeEntry(
        (await setDefault({ imageId: image.id, entryId: entry.id })).data.entry
      )
    } catch (error) {
      showToast({ type: 'error', error: error as Error })
    }
  })
  const isLoading = setDefaultAction.isLoading

  return (
    <ImageDisplay
      onPress={isDefault ? null : setDefaultAction.start}
      key={image.url}
      source={{ uri: image.url }}
      disabled={isDefault || isLoading}
      tag={isDefault ? 'default' : undefined}
      isLoading={isLoading}
      actions={getEntryImageDropDownItems({
        entryId: entry.id,
        imageId: image.id,
        navigation
      })}
    />
  )
}
