import {
  IBMPlexSans_300Light_Italic,
  IBMPlexSans_300Light,
  IBMPlexSans_400Regular_Italic,
  IBMPlexSans_400Regular,
  IBMPlexSans_600SemiBold_Italic,
  IBMPlexSans_600SemiBold
} from '@expo-google-fonts/ibm-plex-sans'
import {
  IBMPlexSerif_600SemiBold,
  IBMPlexSerif_600SemiBold_Italic
} from '@expo-google-fonts/ibm-plex-serif'
import { useFonts as _useFonts } from 'expo-font'
import Icons from '../../assets/Icons.ttf'

export const useFonts = () => {
  let [fontsLoaded] = _useFonts({
    IBMPlexSans_300Light_Italic,
    IBMPlexSans_300Light,
    IBMPlexSans_400Regular_Italic,
    IBMPlexSans_400Regular,
    IBMPlexSans_600SemiBold_Italic,
    IBMPlexSans_600SemiBold,
    IBMPlexSerif_600SemiBold_Italic,
    IBMPlexSerif_600SemiBold,
    Icons
  })

  return fontsLoaded
}
