import { useDeferred } from 'hooks/useDeferred'
import omit from 'lodash/omit'
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'

interface ModalResultContext {
  getNextId: () => string
  setResolver: (id: string, resolve: Resolver) => void
  resolve: (id: string, value: any) => void
}

type Resolver = (value: any | null) => void

export const ModalResult = createContext<ModalResultContext>({
  getNextId: () => '',
  setResolver: () => null,
  resolve: () => null
})

export const ModalResultProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children
}) => {
  const [increment, setIncrement] = useState(0)
  const [resolvers, setResolvers] = useState<
    Record<string, (value: any | null) => void>
  >({})
  const getNextId = useDeferred(() => {
    setIncrement(increment + 1)
    return increment.toString()
  })
  const setResolver = useDeferred((resultId: string, resolve: Resolver) =>
    setResolvers({ ...resolvers, [resultId]: resolve })
  )

  const resolve = (resultId: string, value: any) => {
    resolvers[resultId]?.(value)
    setResolvers(omit(resolvers, resultId))
  }

  return (
    <ModalResult.Provider value={{ getNextId, setResolver, resolve }}>
      {children}
    </ModalResult.Provider>
  )
}

export const useModalResult = () => {
  const { getNextId, setResolver } = useContext(ModalResult)

  const expectResult = (): {
    result: Promise<any | null>
    resultId: string
  } => {
    const resultId = getNextId()

    return {
      result: new Promise<any | null>((resolve) => {
        setResolver(resultId, resolve)
      }),
      resultId
    }
  }

  return { expectResult }
}

export const useModalResultId = (resultId: string) => {
  const { resolve } = useContext(ModalResult)

  useEffect(() => {
    return () => {
      resolve(resultId, null)
    }
  }, [])

  return { onComplete: (value: any | null) => resolve(resultId, value) }
}
