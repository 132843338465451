import AsyncStorage from '@react-native-async-storage/async-storage'
import { getConfig } from 'core/config'
import { useEffect, useState } from 'react'
import { Linking, Platform } from 'react-native'

// Development mode only

const PERSISTENCE_ENABLED = false
const PERSISTENCE_KEY = 'navigation-state'

export const useNavigationPersistence = () => {
  const persistState = (state: any) => {
    AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
  }

  if (!getConfig().dev || !PERSISTENCE_ENABLED) {
    return { isLoading: false, skip: true, initialState: undefined }
  }

  const [isReady, setIsReady] = useState(false)
  const [initialState, setInitialState] = useState<any>()

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL()

        if (Platform.OS === 'web' || initialUrl == null) {
          const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY)
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined

          if (state !== undefined) {
            setInitialState(state)
          }
        }
      } finally {
        setIsReady(true)
      }
    }

    if (!isReady) {
      restoreState()
    }
  }, [isReady])

  if (!isReady) {
    return {
      isLoading: true,
      skip: false,
      initialState: undefined,
      persistState
    }
  }

  return { isLoading: false, skip: false, initialState, persistState }
}
