import { themed } from 'hooks/useTheme'
import { TextStyle } from 'react-native'

interface Styles {
  displayNameText: TextStyle
  statusLabel: TextStyle
  statusText: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ colors, fonts }): Styles => ({
    displayNameText: { fontFamily: fonts.bold },
    statusLabel: {},
    statusText: { color: colors.text.light, fontFamily: fonts.mainItalic }
  })
)
