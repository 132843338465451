import { useDeferred } from 'hooks/useDeferred'
import { useSafeState } from 'hooks/useSafeState'
import { forwardRef, useEffect, useRef } from 'react'
import { Animated, Easing, View, ViewProps } from 'react-native'

const DEFAULT_FADE_MS = 200

type FadingViewProps = ViewProps & {
  show?: boolean
  fadeIn?: boolean
  duration?: number
  unmount?: boolean
} & Partial<
    Record<
      | 'hideOpacity'
      | 'showOpacity'
      | 'translateX'
      | 'translateY'
      | 'scaleX'
      | 'scaleY',
      number
    >
  >

export const FadingView = forwardRef<View, FadingViewProps>(
  (
    {
      show = true,
      fadeIn,
      duration = DEFAULT_FADE_MS,
      hideOpacity = 0,
      showOpacity = 1,
      translateX = 0,
      translateY = 0,
      scaleX = 1,
      scaleY = 1,
      style,
      unmount = false,
      ...props
    },
    ref
  ) => {
    const value = useRef(new Animated.Value(+(fadeIn ? false : show))).current
    const hideTimeout = useRef<NodeJS.Timer | null>(null)
    const [shouldRender, setShouldRender] = useSafeState(show)

    const update = useDeferred(() => {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current)
      }

      if (show) {
        setShouldRender(true)
      } else {
        setTimeout(() => setShouldRender(false), duration)
      }

      Animated.timing(value, {
        toValue: +show,
        useNativeDriver: true,
        easing: Easing.out(Easing.quad),
        duration
      }).start()
    })

    useEffect(() => {
      fadeIn && show && update()
    }, [])

    useEffect(() => {
      update()
    }, [show])

    const interpolate = (from: number, to: number) =>
      value.interpolate({ inputRange: [0, 1], outputRange: [from, to] })

    if (!shouldRender && unmount) {
      return null
    }

    return (
      <Animated.View
        ref={ref}
        pointerEvents={show ? 'auto' : 'none'}
        {...props}
        style={[
          style,
          {
            opacity: interpolate(hideOpacity, showOpacity),
            transform: [
              { translateX: interpolate(translateX, 0) },
              { translateY: interpolate(translateY, 0) },
              { scaleX: interpolate(scaleX, 1) },
              { scaleY: interpolate(scaleY, 1) }
            ]
          }
        ]}
      />
    )
  }
)
