import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
  AppNavigationTheme,
  defaultStackNavigatorProps,
  LINKING_ROUTES_CONFIG
} from './AppNavigation.constants'
import { FunctionComponent } from 'react'
import { SignUpView } from 'view/SignUpView/SignUpView'
import { OfflineView } from 'view/OfflineView/OfflineView'
import { VerificationCodeView } from 'view/VerificationCodeView/VerificationCodeView'
import { AppNavigationRoutes } from './AppNavigation.types'
import { useNetInfo } from '@react-native-community/netinfo'
import { LoadingView } from 'view/LoadingView/LoadingView'
import { useAppStorage } from 'hooks/useAppStorage'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { SignInView } from 'view/SignInView/SignInView'
import { GenerateWizardView } from 'view/GenerateWizardView/GenerateWizardView'
import { EntryView } from 'view/EntryView/EntryView'
import { ListUserEntriesView } from 'view/ListUserEntriesView/ListUserEntriesView'
import { useNavigationPersistence } from 'hooks/useNavigationPersistence'
import { HomeView } from 'view/HomeView/HomeView'
import { CreateCampaignView } from 'view/CreateCampaignView/CreateCampaignView'
import { CampaignInvitesView } from 'view/CampaignInvitesView/CampaignInvitesView'
import { SendCampaignInviteView } from 'view/SendCampaignInviteView/SendCampaignInviteView'
import { ContactPickerView } from 'view/ContactPickerView/ContactPickerView'
import { ViewCampaignEntriesView } from 'view/CampaignEntriesView/CampaignEntriesView'
import { PrivacyPolicyView } from 'view/PrivacyPolicyView/PrivacyPolicyView'
import { PortalHost } from '@gorhom/portal'
import { ViewEntryImageView } from 'view/ViewEntryImageView/ViewEntryImageView'

const Stack = createNativeStackNavigator<AppNavigationRoutes>()

export const AppNavigation: FunctionComponent = () => {
  const { isConnected, isInternetReachable } = useNetInfo()
  const storage = useAppStorage()
  const persistence = useNavigationPersistence()

  if (!storage.isInitialised) {
    return <LoadingView />
  }

  if (storage.error) {
    return (
      <ErrorDisplay
        title="Error initialising app storage"
        error={storage.error}
        fullScreen
      />
    )
  }

  if (!isConnected || !isInternetReachable) {
    if (isConnected === false || isInternetReachable === false) {
      return <OfflineView />
    }

    return <LoadingView />
  }

  if (!persistence.skip && persistence.isLoading) {
    return <LoadingView />
  }

  return (
    <NavigationContainer
      linking={{
        prefixes: ['http://localhost:19006/'],
        config: { screens: LINKING_ROUTES_CONFIG },
        enabled: true
      }}
      theme={AppNavigationTheme}
      initialState={persistence.initialState}
      onStateChange={persistence.persistState}
    >
      <PortalHost name="navigation-start" />

      <Stack.Navigator {...defaultStackNavigatorProps}>
        <Stack.Screen name="Home" component={HomeView} />
        <Stack.Screen name="SignUp" component={SignUpView} />
        <Stack.Screen name="SignIn" component={SignInView} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyView} />

        <Stack.Screen
          name="VerificationCode"
          component={VerificationCodeView}
          options={{ presentation: 'modal', gestureEnabled: true }}
        />

        <Stack.Screen
          name="ContactPicker"
          component={ContactPickerView}
          options={{ presentation: 'modal', gestureEnabled: true }}
        />

        <Stack.Screen name="Entry" component={EntryView} />
        <Stack.Screen name="ListUserEntries" component={ListUserEntriesView} />
        <Stack.Screen name="CreateCampaign" component={CreateCampaignView} />
        <Stack.Screen name="CampaignInvites" component={CampaignInvitesView} />
        <Stack.Screen name="NewEntryByType" component={GenerateWizardView} />
        <Stack.Screen
          name="SendCampaignInvite"
          component={SendCampaignInviteView}
        />
        <Stack.Screen
          name="ViewCampaignEntries"
          component={ViewCampaignEntriesView}
        />
        <Stack.Screen
          name="ViewEntryImage"
          component={ViewEntryImageView}
          options={{ presentation: 'modal' }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  )
}
