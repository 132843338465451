import { themed } from 'hooks/useTheme'
import type { TextStyle } from 'react-native'

type HeadingType = 'title' | 'subtitle'

const options: Record<
  HeadingType,
  { size: number; special?: boolean; light?: boolean }
> = {
  title: { size: 34, special: true },
  subtitle: { size: 20, light: true }
}

export const { use: useStyles } = themed<
  TextStyle,
  { type: HeadingType; center?: boolean }
>(({ palette, colors, fonts }, { type, center }): TextStyle => {
  const { size, special, light } = options[type]

  return {
    color: colors.text.default,
    fontSize: size,
    fontFamily: special ? fonts.special : light ? fonts.light : fonts.main,
    textAlign: center ? 'center' : undefined,
    textShadowColor: palette.background,
    textShadowRadius: 10,
    textShadowOffset: { width: 3, height: 3 }
  }
})
