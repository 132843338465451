import { FooterFormWrap } from 'components/FooterFormWrap/FooterFormWrap'
import { Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'
import { Text } from 'components/Text/Text'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import { CreateCampaignForm } from './CrateCampaign.form'

export const CreateCampaignView: FunctionComponent = () => {
  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Title>New campaign</Title>

        <Spacer />

        <Text>
          Choose a name for your campaign and invite your party to join.
        </Text>
      </DefaultLayout.Header>

      <DefaultLayout.Content />

      <FooterFormWrap>
        <DefaultLayout.Footer>
          <CreateCampaignForm />
        </DefaultLayout.Footer>
      </FooterFormWrap>
    </DefaultLayout>
  )
}
