import { HeadImage, HeadImageProps } from 'components/HeadImage/HeadImage'
import { RefreshControl } from 'components/RefreshControl/RefreshControl'
import { forwardRef, useState } from 'react'
import { ScrollView, ScrollViewProps } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useStyles } from './ScrollViewWithHeadImage.styles'

export type ScrollViewWithHeadImageProps = ScrollViewProps & {
  headImage: HeadImageProps
  backgroundOpacity?: number
  isLoading?: boolean
  reload?: () => void
}

const BACKGROUND_SCROLL_THRESHOLD = 10
const BACKGROUND_BLUR_AMOUNT = 5

export const ScrollViewWithHeadImage = forwardRef<
  ScrollView,
  ScrollViewWithHeadImageProps
>(
  (
    {
      headImage: headImageProps,
      onScroll,
      children,
      backgroundOpacity = 0.1,
      isLoading = false,
      reload,
      style,
      ...props
    },
    ref
  ) => {
    const [scrolled, setScrolled] = useState(0)
    const styles = useStyles()
    const hasScrolled = scrolled > BACKGROUND_SCROLL_THRESHOLD

    return (
      <>
        <HeadImage
          {...headImageProps}
          canBlur
          blur={hasScrolled ? BACKGROUND_BLUR_AMOUNT : 0}
          subtle={hasScrolled ? 1 - backgroundOpacity : false}
          blurred={hasScrolled}
        />

        <ScrollView
          {...props}
          style={[styles.container, style]}
          ref={ref}
          onScroll={(e) => {
            setScrolled(e.nativeEvent.contentOffset.y)
            onScroll?.(e)
          }}
          refreshControl={
            reload ? (
              <RefreshControl
                spaced
                safe
                onRefresh={reload}
                refreshing={isLoading}
              />
            ) : undefined
          }
        >
          <SafeAreaView style={styles.content} edges={['top', 'left', 'right']}>
            {children}
          </SafeAreaView>
        </ScrollView>
      </>
    )
  }
)
