import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import { Button } from 'components/Button/Button'
import { withResult } from 'hoc/withAsyncResult'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { IconType } from 'components/Icon/Icon'
import { getEntryImageService } from 'services/entries'
import { downloadEntryImage } from 'utils/downloads'
import { FadingImage } from 'components/FadingImage/FadingImage'
import { Spacer } from 'components/Spacer/Spacer'
import { useNavigation } from 'hooks/useNavigation'
import { Row } from 'components/Row/Row'
import { useStyles } from './ViewEntryImageView.styles'

export const ViewEntryImageView = withResult(
  ({
    route: {
      params: { entryId, imageId }
    }
  }: RouteProps<'ViewEntryImage'>) => {
    const getImage = useService(getEntryImageService)

    return async () => {
      return (await getImage({ entryId, imageId })).data
    }
  },
  ({ entry, image }) => {
    const styles = useStyles()
    const { pop } = useNavigation()

    return (
      <DefaultLayout style={styles.container}>
        <DefaultLayout.Content>
          <FadingImage source={{ uri: image.url }} style={styles.image} />
        </DefaultLayout.Content>

        <DefaultLayout.Footer>
          <Row>
            <Button
              iconLeft={IconType.Download}
              type="primary"
              inline
              size="tiny"
              onPress={() =>
                downloadEntryImage({ entryId: entry.id, imageId: image.id })
              }
            >
              Download
            </Button>

            <Spacer horizontal />

            <Button
              outline
              inline
              size="tiny"
              iconLeft={IconType.Cross}
              type="secondary"
              onPress={() => pop()}
            >
              Close
            </Button>
          </Row>
        </DefaultLayout.Footer>
      </DefaultLayout>
    )
  }
)
