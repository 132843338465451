import { useEffect, useState } from 'react'

export type AsyncAction<T, FnArgs extends any[]> = {
  start: (...args: FnArgs) => Promise<T>
  reset: () => void
} & (
  | { result: T; isLoading: false; error: null; success: false }
  | { result: null; isLoading: false; error: null; success: false }
  | { result: T | null; isLoading: false; error: null; success: false }
  | { result: null; isLoading: false; error: Error; success: false }
)

type UseAsyncResultOptions<FnArgs extends any[]> =
  | { initRun?: false; initWith?: undefined }
  | { initRun: true; initWith: FnArgs }

export const useAsync = <Result, FnArgs extends any[] = []>(
  fn: (...fnArgs: FnArgs) => Promise<Result>,
  { initRun = false, initWith }: UseAsyncResultOptions<FnArgs> = {}
): AsyncAction<Result, FnArgs> => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [result, setResult] = useState<Result | null>(null)
  const [success, setSuccess] = useState(false)

  const reset = () => {
    setIsLoading(false)
    setError(null)
    setResult(null)
    setSuccess(false)
  }

  const start = async (...args: FnArgs) => {
    setIsLoading(true)
    setError(null)
    setSuccess(false)

    try {
      setResult(await fn(...args))
      setSuccess(true)
      return result
    } catch (err) {
      if (err instanceof Error) {
        setError(err)
        setSuccess(false)
        return null
      }
    } finally {
      setIsLoading(false)
      setSuccess(true)
      return null
    }
  }

  initRun &&
    useEffect(() => {
      start(...(initWith as FnArgs))
    }, [])

  return { result, error, isLoading, start, reset, success } as AsyncAction<
    Result,
    FnArgs
  >
}
