import { Icon } from 'components/Icon/Icon'
import { IconType } from 'components/Icon/Icon.types'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { Row } from 'components/Row/Row'
import { FunctionComponent, PropsWithChildren } from 'react'
import { View } from 'react-native'
import { useStyles } from './RowWithIcon.styles'

type RowWithIconButtonProps = PropsWithChildren<{
  onPress?: () => void
  icon?: IconType | null
}>

export const RowWithIcon: FunctionComponent<RowWithIconButtonProps> = ({
  icon,
  onPress,
  children
}) => {
  const styles = useStyles()
  return icon ? (
    <Row>
      <View style={styles.spacer} />

      <View style={styles.mainContainer}>{children}</View>

      <MaybePressable style={styles.iconContainer} onPress={onPress}>
        <Icon type={icon} style={styles.icon} />
      </MaybePressable>
    </Row>
  ) : (
    <>{children}</>
  )
}
