import { typedService } from 'core/api'
import { ShortEntryInfo, DetailedEntryInfo } from 'types/entries'
import { ImageInfo } from 'types/images'
import { TextBit } from 'types/text-bits'

export const getEntryByIdService = typedService<
  { id: number },
  { entry: DetailedEntryInfo; canEdit: boolean }
>('get', 'entries/{id}')

export const deleteEntryByIdService = typedService<{ id: number }, void>(
  'delete',
  'entries/{id}'
)

export const editEntryByIdService = typedService<
  { id: number; displayName?: string; textBits?: Array<Partial<TextBit>> },
  { entry: DetailedEntryInfo }
>('put', 'entries/{id}')

export const listUserEntriesService = typedService<
  void,
  { entries: ShortEntryInfo[] }
>('get', 'entries/user')

export const setDefaultImageService = typedService<
  { imageId: number; entryId: number },
  { entry: DetailedEntryInfo }
>('put', 'entries/{entryId}/images/default')

export const getEntryImageService = typedService<
  { imageId: number; entryId: number },
  { image: ImageInfo; entry: DetailedEntryInfo }
>('get', 'entries/{entryId}/images/{imageId}')
