import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import { Button } from 'components/Button/Button'
import { FieldLabel } from 'components/Field/Field'
import { FooterFormWrap } from 'components/FooterFormWrap/FooterFormWrap'
import { IconType } from 'components/Icon/Icon.types'
import { ItemsList, ItemsListItem } from 'components/ItemsList/ItemsList'
import { Spacer } from 'components/Spacer/Spacer'
import { Text } from 'components/Text/Text'
import { TextInput } from 'components/TextInput/TextInput'
import { useModalResultId } from 'context/modal-result'
import { requestPermissionsAsync, getContactsAsync } from 'expo-contacts'
import { useNavigation } from 'hooks/useNavigation'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent, useState } from 'react'
import { LoadingView } from 'view/LoadingView/LoadingView'
import { useAsync } from 'hooks/useAsync'
import { ErrorView } from 'view/ErrorView/ErrorView'
import { useStyles } from './ContactPickerView.styles'
import { getMobileNumbersFromContacts, NumberItem } from 'utils/contacts'
import { Platform } from 'react-native'

const MAX_SHOWN = 4

export const ContactPickerView: FunctionComponent<
  RouteProps<'ContactPicker'>
> = ({ route }) => {
  const { onComplete } = useModalResultId(route.params.resultId)
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)
  const [numberItems, setNumberItems] = useState<NumberItem[]>([])
  const [query, setQuery] = useState('')
  const { pop } = useNavigation()
  const close = (value: string | null) => {
    onComplete(value)
    pop()
  }
  const results = numberItems.filter(({ contact: c }) =>
    `${c.firstName} ${c.lastName} ${c.company} ${c.middleName} ${c.name}`
      .replace(/[\s\t\n]+/g, '')
      .toLowerCase()
      .includes(query.replace(/[\s\t\n]+/g, '').toLowerCase())
  )
  const styles = useStyles()

  const init = useAsync(
    async () => {
      const permissions = await requestPermissionsAsync()
      const hasGranted = permissions.status === 'granted'

      setHasPermission(hasGranted)

      if (!hasGranted) {
        return close(null)
      }

      const contacts = (await getContactsAsync()).data
      const processed = getMobileNumbersFromContacts(contacts)

      setNumberItems(processed)
    },
    { initRun: true, initWith: [] }
  )

  if (init.error) {
    return <ErrorView error={init.error} />
  }

  if (!hasPermission || init.isLoading) {
    return <LoadingView />
  }

  return (
    <DefaultLayout safe={Platform.select({ ios: false })}>
      <FooterFormWrap style={styles.formWrap}>
        <DefaultLayout.Content style={styles.content}>
          {!!query ? (
            <>
              <ItemsList
                stretch
                inverted
                contentContainerStyle={styles.listContent}
                data={results.slice(0, MAX_SHOWN)}
                renderItem={({ item: { display, contact, normalised } }) => (
                  <ItemsListItem
                    key={contact.id}
                    icon={IconType.Mobile}
                    onPress={() => close(normalised)}
                  >
                    <Text style={styles.contactNameText}>{contact.name}</Text>
                    <Text>{display}</Text>
                  </ItemsListItem>
                )}
              />

              {results.length > MAX_SHOWN && (
                <>
                  <Spacer />
                  <Text style={styles.truncatedText}>
                    {results.length - MAX_SHOWN} more...
                  </Text>
                </>
              )}
            </>
          ) : (
            <Text style={styles.placeholderText}>Type a name to search</Text>
          )}
        </DefaultLayout.Content>

        <DefaultLayout.Footer>
          <FieldLabel>Search contacts</FieldLabel>

          <TextInput
            autoFocus
            value={query}
            onChangeText={setQuery}
          ></TextInput>

          <Spacer />

          <Button
            type="secondary"
            iconLeft={IconType.Cross}
            outline
            onPress={() => close(null)}
          >
            Cancel
          </Button>
        </DefaultLayout.Footer>
      </FooterFormWrap>
    </DefaultLayout>
  )
}
