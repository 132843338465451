import { Portal } from '@gorhom/portal'
import { useBackHandler } from '@react-native-community/hooks'
import { AbsoluteCenter } from 'components/AbsoluteCenter/AbsoluteCenter'
import { FadingView } from 'components/FadingView/FadingView'
import { Icon } from 'components/Icon/Icon'
import { IconType } from 'components/Icon/Icon.types'
import { Overlay } from 'components/Overlay/Overlay'
import { Text } from 'components/Text/Text'
import { FunctionComponent, useState } from 'react'
import { Pressable, ScrollView, View, ViewProps } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useStyles } from './DropDown.styles'
import { TextStyle } from 'react-native-phone-input'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { Spinner } from 'components/Spinner/Spinner'
import { ComponentWithAction } from 'components/ComponentWithAction/ComponentWithAction'

const TRANSLATE_Y = 100

export type DropDownFrom = 'top' | 'bottom'

export type DropDownButtonProps = Omit<DropDownProps, 'isShown' | 'close'> & {
  icon?: IconType | null
  buttonTextStyle?: TextStyle
} & ViewProps

export const DropDownButton: FunctionComponent<DropDownButtonProps> = ({
  style,
  icon = IconType.DotsVertical,
  buttonTextStyle,
  ...dropDownProps
}) => {
  const [isShown, setIsShown] = useState(false)
  const styles = useStyles()

  return (
    <Pressable
      onPress={() => setIsShown(!isShown)}
      style={[styles.button, style]}
    >
      <DropDown
        {...dropDownProps}
        isShown={isShown}
        close={() => setIsShown(false)}
      />
      {icon !== null && (
        <Icon type={icon} style={[styles.buttonIconOpen, buttonTextStyle]} />
      )}
    </Pressable>
  )
}

export interface DropDownProps {
  title?: string
  isShown?: boolean
  close?: () => void
  items: DropDownItemProps[]
  from?: DropDownFrom
}

export const DropDown: FunctionComponent<DropDownProps> = ({
  items,
  isShown,
  close: onClose,
  from
}) => {
  const styles = useStyles({ from })
  const translateDir = from === 'top' ? -1 : 1
  const [shownSubmenu, setShownSubmenu] = useState<string | null>(null)

  const close = () => {
    onClose?.()
    setShownSubmenu(null)
  }

  useBackHandler(() => {
    close()
    return !!(onClose && isShown)
  })

  return (
    <FadingView show={isShown} unmount>
      <Portal hostName="root">
        <Overlay show={isShown} opacity={0.5} blur={15} />

        <FadingView
          show={isShown}
          translateY={TRANSLATE_Y * translateDir}
          fadeIn
          style={styles.wrapper}
        >
          <Pressable style={styles.exitTrap} onPress={close} />

          <ScrollView
            style={styles.container}
            pointerEvents={isShown ? 'auto' : 'none'}
          >
            <SafeAreaView edges={['bottom']}>
              {items
                .filter((item) =>
                  !shownSubmenu ? !item.submenu : shownSubmenu === item.submenu
                )
                .map((item) => (
                  <DropDownItem
                    {...item}
                    key={item.label}
                    setShownSubmenu={setShownSubmenu}
                    isShown={isShown}
                    close={close}
                  />
                ))}
            </SafeAreaView>
          </ScrollView>
        </FadingView>
      </Portal>
    </FadingView>
  )
}

export interface DropDownItemProps {
  label: string
  icon?: IconType
  disabled?: boolean
  tight?: boolean
  active?: boolean
  onSelect?: () => any | Promise<any>
  requireConfirmation?: boolean
  textAlign?: 'left' | 'center'
  submenu?: string
  opensSubmenu?: string
}

export const DropDownItem: FunctionComponent<
  DropDownItemProps & {
    isShown?: boolean
    close?: () => void
    setShownSubmenu: (submenu: string) => void
  }
> = ({
  icon,
  label,
  onSelect,
  requireConfirmation,
  isShown,
  close,
  disabled,
  active,
  tight,
  textAlign,
  opensSubmenu,
  setShownSubmenu
}) => {
  const styles = useStyles({ disabled, active, tight, textAlign })
  const [isConfirming, setIsConfirming] = useState(false)
  const getOnPressHandler = (onPress: () => void) => {
    if (opensSubmenu) {
      return () => setShownSubmenu(opensSubmenu)
    }

    if (disabled) {
      return undefined
    }

    if (requireConfirmation && !isConfirming) {
      return () => setIsConfirming(true)
    }

    return onPress
  }

  return (
    <ComponentWithAction
      fn={async () => {
        await onSelect?.()
        close?.()
      }}
    >
      {({ isLoading, start: onSelectHandler }) => (
        <MaybePressable
          style={styles.item}
          onPress={getOnPressHandler(onSelectHandler)}
        >
          <View
            style={[
              styles.itemInner,
              {
                opacity: +!!(isShown && !isConfirming && !isLoading)
              }
            ]}
          >
            {icon && <Icon type={icon} style={styles.itemIcon} />}
            <Text numberOfLines={1} style={[styles.itemLabel]}>
              {label}
            </Text>
          </View>

          {isLoading && (
            <AbsoluteCenter>
              <Spinner size="small" />
            </AbsoluteCenter>
          )}

          {requireConfirmation && isConfirming && (
            <View style={[styles.itemInner, styles.confirmation]}>
              <Text style={[styles.itemLabel, styles.confirmationLabel]}>
                Tap again to confirm
              </Text>
            </View>
          )}
        </MaybePressable>
      )}
    </ComponentWithAction>
  )
}
