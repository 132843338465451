import { Text, TextProps, TextStyle } from 'components/Text/Text'
import upperFirst from 'lodash/upperFirst'
import { Fragment, FunctionComponent } from 'react'
import { StyleProp } from 'react-native'
import { ShortEntryInfo } from 'types/entries'
import { getDisplayedChoicesRows } from 'utils/choices'
import { notNil } from 'utils/object'
import { useStyles } from './EntryChoiceLabels.styles'

type EntryChoiceLabelProps = TextProps & {
  choices: ShortEntryInfo['choices']
  dividerStyle?: StyleProp<TextStyle>
  type?: string
}

export const EntryChoiceLabels: FunctionComponent<EntryChoiceLabelProps> = ({
  dividerStyle,
  choices,
  type,
  ...props
}) => {
  const styles = useStyles()
  const displayedChoices = [type, ...getDisplayedChoicesRows(choices)].filter(
    notNil
  )

  return (
    <Text {...props} style={[styles.text, props.style]}>
      {displayedChoices.map((choice, i) => {
        const label = typeof choice === 'string' ? choice : choice.label
        const key = typeof choice === 'string' ? i : choice.id

        return (
          choice && (
            <Fragment key={key}>
              {upperFirst(label)}
              {'  '}
              {i < displayedChoices.length - 1 && (
                <Text style={[styles.text, styles.divider, dividerStyle]}>
                  |
                </Text>
              )}
              {'  '}
            </Fragment>
          )
        )
      })}
    </Text>
  )
}
