import { Z_INDEX } from 'constants/ui'
import { themed } from 'hooks/useTheme'
import { ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface Styles {
  container: ViewStyle
}

export const { use: useStyles } = themed<Styles>((): Styles => {
  const insets = useSafeAreaInsets()

  return {
    container: {
      position: 'absolute',
      left: 0,
      top: insets.top || 0,
      width: '100%',
      padding: 20,
      paddingBottom: 0,
      zIndex: Z_INDEX.TOASTER
    }
  }
})
