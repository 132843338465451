import { AbsoluteCenter } from 'components/AbsoluteCenter/AbsoluteCenter'
import { DropDownButton, DropDownItemProps } from 'components/DropDown/DropDown'
import { FadingSpinner } from 'components/FadingSpinner/FadingSpinner'
import { IconType } from 'components/Icon/Icon.types'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { Text } from 'components/Text/Text'
import { FunctionComponent } from 'react'
import { Image, ImageProps, View, ViewProps } from 'react-native'
import { useStyles } from './ImageDisplay.styles'

type ImageDisplayProps = ImageProps & {
  containerProps?: ViewProps
  disabled?: boolean
  tag?: string
  onPress?: (() => void) | null
  isLoading?: boolean
  actions?: DropDownItemProps[]
}

export const ImageDisplay: FunctionComponent<ImageDisplayProps> = ({
  disabled = false,
  containerProps = {},
  onPress,
  tag,
  isLoading = false,
  actions,
  ...props
}) => {
  const styles = useStyles({ disabled })

  return (
    <MaybePressable
      {...containerProps}
      style={[styles.container, containerProps.style]}
      onPress={onPress}
    >
      <Image {...props} style={[styles.image, props.style]} />

      {tag && (
        <AbsoluteCenter>
          <View style={styles.tag}>
            <Text style={styles.tagText}>{tag}</Text>
          </View>
        </AbsoluteCenter>
      )}

      <AbsoluteCenter>
        <FadingSpinner show={isLoading} size="small" />
      </AbsoluteCenter>

      {actions && (
        <DropDownButton
          icon={IconType.Dots}
          items={actions}
          style={styles.dropDownButton}
          buttonTextStyle={styles.dropDownButtonIcon}
        />
      )}
    </MaybePressable>
  )
}
