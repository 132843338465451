import { forwardRef } from 'react'
import { View } from 'react-native'
import { SafeAreaView, SafeAreaViewProps } from 'react-native-safe-area-context'

export type MaybeSafeViewProps = SafeAreaViewProps & { safe?: boolean }

export const MaybeSafeView = forwardRef<View, MaybeSafeViewProps>(
  ({ safe = false, ...props }, ref) =>
    safe ? <SafeAreaView {...props} ref={ref} /> : <View {...props} />
)
