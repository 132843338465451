import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  Platform,
  TextInputProps,
  TextInputTextInputEventData
} from 'react-native'

export const useInputAutoResize = (
  props: TextInputProps
): Partial<TextInputProps> => {
  if (!props.multiline || Platform.OS !== 'web') {
    return {}
  }

  const adjustTextInputSize = (
    e: LayoutChangeEvent | NativeSyntheticEvent<TextInputTextInputEventData>
  ) => {
    const el = (e?.target ||
      ('target' in e?.nativeEvent &&
        e?.nativeEvent?.target)) as unknown as HTMLTextAreaElement

    if (el) {
      el.style.height = '0'
      const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight
      el.style.height = `${newHeight}px`
    }
  }

  return {
    onLayout: (e) => {
      adjustTextInputSize(e)
      props.onLayout?.(e)
    },
    onTextInput: (e) => {
      adjustTextInputSize(e)
      props.onTextInput?.(e)
    }
  }
}
