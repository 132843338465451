import { themed } from 'hooks/useTheme'
import { ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'

interface Styles {
  formWrap: ViewStyle
  content: ViewStyle
  listContent: ViewStyle
  placeholderText: TextStyle
  truncatedText: TextStyle
  contactNameText: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ colors, fonts }): Styles => ({
    formWrap: { flex: 1 },

    content: { paddingBottom: 0 },

    listContent: { flexDirection: 'column-reverse' },

    placeholderText: {
      fontFamily: fonts.mainItalic,
      color: colors.text.light,
      fontSize: 18
    },

    truncatedText: {
      fontFamily: fonts.mainItalic,
      color: colors.text.light,
      alignSelf: 'stretch',
      textAlign: 'left'
    },

    contactNameText: { fontFamily: fonts.bold }
  })
)
