import { Text, TextProps } from 'components/Text/Text'
import { FieldContext } from 'context/field'
import { FieldState } from 'hooks/useForm'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { View } from 'react-native'
import { useStyles } from './Field.styles'

type FieldProps = PropsWithChildren<{
  field: FieldState<any>
  label?: ReactNode
}>

export const Field: FunctionComponent<FieldProps> = ({
  children,
  field,
  label
}) => (
  <FieldContext.Provider value={field}>
    {label && <FieldLabel>{label}</FieldLabel>}

    {children}

    {field.error && !field.pristine && (
      <>
        <FieldError>{field.error}</FieldError>
      </>
    )}
  </FieldContext.Provider>
)

export const FieldError: FunctionComponent<TextProps> = ({
  style,
  ...props
}) => {
  const styles = useStyles()

  return (
    <View style={styles.errorContainer}>
      <View style={styles.error}>
        <Text style={[styles.errorText, style]} {...props} />
      </View>
    </View>
  )
}

export const FieldLabel: FunctionComponent<TextProps> = ({
  style,
  ...props
}) => {
  const styles = useStyles()

  return <Text style={[styles.label, style]} {...props} />
}
