import { EntryChoiceLabels } from 'components/EntryChoiceLabels/EntryChoiceLabels'
import { IconType } from 'components/Icon/Icon.types'
import { ItemsList, ItemsListItem } from 'components/ItemsList/ItemsList'
import { Text } from 'components/Text/Text'
import { useNavigation } from 'hooks/useNavigation'
import { useService } from 'hooks/useService'
import { FunctionComponent } from 'react'
import { ListRenderItemInfo } from 'react-native'
import { deleteEntryByIdService } from 'services/entries'
import { ShortEntryInfo } from 'types/entries'
import { useStyles } from './EntriesList.styles'
import placeholderImage from './assets/placeholder.png'
import { Campaign } from 'types/campaigns'
import { notNil } from 'utils/object'
import { DropDownItemProps } from 'components/DropDown/DropDown'
import {
  addEntryToCampaignService,
  removeEntryFromCampaignService
} from 'services/campaigns'

interface EntriesListProps {
  entries: ShortEntryInfo[]
  campaigns?: Campaign[]
  reload: () => void
  isLoading: boolean
}

type EntriesListItemProps = Omit<EntriesListProps, 'entries' | 'isLoading'> &
  ListRenderItemInfo<ShortEntryInfo>

export const EntriesList: FunctionComponent<EntriesListProps> = ({
  entries,
  reload,
  isLoading,
  campaigns,
  ...props
}) => (
  <ItemsList
    onRefresh={reload}
    isLoading={isLoading}
    stretch={false}
    data={entries}
    renderItem={(item) => (
      <EntriesListItem
        reload={reload}
        campaigns={campaigns}
        {...props}
        {...item}
        key={item.item.id}
      />
    )}
  />
)

const EntriesListItem: FunctionComponent<EntriesListItemProps> = ({
  item: {
    id,
    defaultImage,
    displayName,
    choices,
    campaigns: entryCampaigns,
    type
  },
  campaigns = [],
  reload
}) => {
  const styles = useStyles()
  const { push } = useNavigation()
  const deleteEntry = useService(deleteEntryByIdService)
  const addToCampaign = useService(addEntryToCampaignService)
  const removeFromCampaign = useService(removeEntryFromCampaignService)
  const entryCampaignsIds = entryCampaigns.map(({ id }) => id)
  const availableToAddCampaigns = campaigns.filter(
    ({ id }) => !entryCampaignsIds.includes(id)
  )

  return (
    <ItemsListItem
      onPress={() => push('Entry', { id: id })}
      imageSource={defaultImage.url ? { uri: defaultImage.url } : undefined}
      placeholderImage={placeholderImage}
      dropDown={{
        items: [
          {
            icon: IconType.Trash,
            label: 'Delete',
            onSelect: async () => {
              await deleteEntry({ id })
              reload()
            },
            requireConfirmation: true
          },

          availableToAddCampaigns.length
            ? {
                icon: IconType.Plus,
                label: 'Add to campaign',
                opensSubmenu: 'add-to-campaign'
              }
            : null,

          entryCampaigns.length
            ? {
                icon: IconType.Minus,
                label: 'Remove from campaign',
                opensSubmenu: 'remove-from-campaign'
              }
            : null,

          ...availableToAddCampaigns.map(
            ({ title, id: campaignId }): DropDownItemProps => ({
              icon: IconType.Book,
              submenu: 'add-to-campaign',
              label: title,
              onSelect: async () => {
                await addToCampaign({ campaignId, entryId: id })
                reload()
              }
            })
          ),

          ...entryCampaigns.map(
            ({ title, id: campaignId }): DropDownItemProps => ({
              icon: IconType.Book,
              submenu: 'remove-from-campaign',
              label: title,
              onSelect: async () => {
                await removeFromCampaign({ campaignId, entryId: id })
                reload()
              }
            })
          )
        ].filter(notNil)
      }}
    >
      <Text style={styles.displayName} numberOfLines={1}>
        {displayName}
      </Text>

      <EntryChoiceLabels
        type={type}
        choices={choices}
        style={styles.choiceLabels}
      />
    </ItemsListItem>
  )
}
