import { useMedia } from 'hooks/useMedia'
import { themed } from 'hooks/useTheme'
import { ImageStyle, Platform, TextStyle, ViewStyle } from 'react-native'
import color from 'tinycolor2'

interface Styles {
  list: ViewStyle
  container: ViewStyle
  inner: ViewStyle
  content: ViewStyle
  imageContainer: ViewStyle
  image: ImageStyle
  itemIcon: TextStyle
  placeholderImage: ViewStyle
  inlineActions: ViewStyle
  iconButton: ViewStyle
  sectionHeader: ViewStyle
  sectionHeaderIcon: TextStyle
  sectionHeaderTitle: TextStyle
}

const IMAGE_SIZE = 70

export const { use: useStyles } = themed<Styles, { stretch?: boolean } | void>(
  ({ misc, palette, colors }, { stretch = true } = {}): Styles => {
    const { isTablet } = useMedia()

    return {
      list: { alignSelf: 'stretch', marginHorizontal: stretch ? -20 : 0 },

      container: {
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: color(palette.background)
          .lighten(Platform.select({ default: -5, web: -20, ios: -5 }))
          .saturate(Platform.select({ android: 10, default: 10 }))
          .setAlpha(Platform.select({ default: 0.4, web: 0.15, ios: 0.1 }))
          .toRgbString(),
        marginBottom: isTablet ? 10 : 2,
        width: '100%',
        maxWidth: Platform.select({ default: undefined, web: 600 }),
        borderRadius: isTablet ? misc.radius : 0
      },

      inner: {
        alignSelf: 'stretch',
        paddingVertical: 5,
        paddingHorizontal: 5,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
      },

      content: { flex: 1, justifyContent: 'center', height: IMAGE_SIZE },

      imageContainer: {
        height: IMAGE_SIZE,
        aspectRatio: 1,
        borderRadius: misc.imageRadius,
        margin: 5,
        marginRight: 20
      },

      image: { width: '100%', height: '100%' },

      itemIcon: {
        fontSize: 25,
        marginHorizontal: 15,
        textAlign: 'center',
        color: color(colors.text.light).setAlpha(0.4).toRgbString()
      },

      placeholderImage: { opacity: 0.3 },

      inlineActions: {
        paddingRight: 20,
        flexDirection: 'row',
        alignItems: 'center'
      },

      iconButton: { marginLeft: 10 },

      sectionHeader: {
        paddingHorizontal: 20,
        minHeight: 40,
        alignItems: 'center',
        flexDirection: 'row'
      },

      sectionHeaderIcon: {
        marginRight: 15,
        color: palette.foreground,
        fontSize: 20,
        marginTop: 2
      },

      sectionHeaderTitle: {
        color: colors.text.light
      }
    }
  }
)
