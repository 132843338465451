import { Button, ButtonProps } from 'components/Button/Button'
import { Icon, IconType } from 'components/Icon/Icon'
import { Text } from 'components/Text/Text'
import { FunctionComponent } from 'react'

type ButtonWithPriceProps = ButtonProps & { price: number }

export const ButtonWithPrice: FunctionComponent<ButtonWithPriceProps> = ({
  price,
  ...props
}) => (
  <Button
    {...props}
    iconRight={({ textStyle }) => (
      <>
        <Icon type={IconType.Credit} style={[textStyle, { fontSize: 18 }]} />
        <Text
          bold
          style={[
            textStyle,
            { fontSize: 16, top: -2, marginLeft: 5, color: textStyle.color }
          ]}
        >
          {price}
        </Text>
      </>
    )}
  />
)
