import { Portal } from '@gorhom/portal'
import { useBackHandler } from '@react-native-community/hooks'
import { AbsoluteCenter } from 'components/AbsoluteCenter/AbsoluteCenter'
import { FadingImageView } from 'components/FadingImage/FadingImage'
import { FadingView } from 'components/FadingView/FadingView'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { Overlay } from 'components/Overlay/Overlay'
import { Text } from 'components/Text/Text'
import { LinearGradient } from 'expo-linear-gradient'
import { ReactElement } from 'react'
import { ImageSourcePropType, Pressable, ScrollView, View } from 'react-native'
import { useStyles } from './OptionsTray.styles'
import { SimpleGrid } from 'react-native-super-grid'
import { Button } from 'components/Button/Button'
import { isRandom } from 'utils/choices'

interface OptionsTrayOption<T> {
  value: T
  label: string | null
  imageSource?: ImageSourcePropType
}

interface OptionsTrayProps<T> {
  options: OptionsTrayOption<T>[]
  show?: boolean
  showImages?: boolean
  onClose?: () => void
  multiple?: boolean
  value?: T | T[]
  onChange?: (value: T | T[] | null) => void
}

type OptionsTrayOptionDisplayProps<T> = {
  option: OptionsTrayOption<T>
  showImages?: boolean
  onPress: () => void
  selected?: boolean
  value?: T
}

export function OptionsTray<T>({
  options,
  value,
  onChange,
  showImages,
  show,
  multiple,
  onClose
}: OptionsTrayProps<T>): ReactElement {
  const styles = useStyles({ showImages })
  useBackHandler(() => {
    show && onClose?.()
    return !!show
  })

  return (
    <Portal hostName="root">
      <Overlay show={show} opacity={0.8} />

      <AbsoluteCenter
        pointerEvents={show ? 'auto' : 'none'}
        style={{ justifyContent: 'space-between' }}
        safe
      >
        <Pressable style={styles.exitTrap} onPress={onClose} />

        <View style={styles.spacer} pointerEvents="none" />

        <FadingView
          unmount
          show={show}
          fadeIn
          translateY={20}
          scaleX={0.8}
          scaleY={0.8}
        >
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={styles.scrollContent}
          >
            <SimpleGrid
              data={options}
              style={styles.container}
              itemDimension={100}
              spacing={5}
              renderItem={({ item: option }) => (
                <OptionsTrayOptionDisplay<T>
                  key={option.label}
                  option={option}
                  showImages={showImages}
                  onPress={() => {
                    if (option.value === null || isRandom(option.value)) {
                      return onChange?.(option.value)
                    }

                    if (multiple) {
                      const cur: T[] = Array.isArray(value) ? value : []
                      const index = value ? cur.indexOf(option.value) : -1
                      const newVal =
                        index === -1
                          ? [...cur, option.value]
                          : [...cur.slice(0, index), ...cur.slice(index + 1)]
                      onChange?.(newVal)
                    } else {
                      onChange?.(option.value)
                      onChange?.(option.value)
                    }
                    !multiple && onClose?.()
                  }}
                  selected={
                    (option.value === null && !value) ||
                    (Array.isArray(value)
                      ? value.includes(option.value)
                      : option.value === value)
                  }
                />
              )}
            />

            {multiple ? (
              <Button
                style={styles.closeButton}
                type="primary"
                onPress={onClose}
                size="small"
              >
                Done
              </Button>
            ) : null}
          </ScrollView>
        </FadingView>
      </AbsoluteCenter>
    </Portal>
  )
}

export function OptionsTrayOptionDisplay<T>({
  option: { label, imageSource },
  showImages,
  selected,
  onPress
}: OptionsTrayOptionDisplayProps<T>): ReactElement {
  const styles = useStyles({ showImages, selected })

  return (
    <MaybePressable onPress={onPress} style={styles.option}>
      {imageSource ? (
        <>
          <FadingImageView
            source={imageSource}
            variant="dark"
            containerProps={{ style: styles.optionImage }}
          />

          <View style={styles.labelContainer}>
            <LinearGradient {...styles.labelGradientProps} />
            <Text style={[styles.label, styles.imageLabel]}>{label}</Text>
          </View>
        </>
      ) : (
        <View style={styles.tag}>
          <Text style={[styles.label, styles.tagLabel]}>{label}</Text>
        </View>
      )}
    </MaybePressable>
  )
}
