import { Button } from 'components/Button/Button'
import { Field } from 'components/Field/Field'
import { Spacer } from 'components/Spacer/Spacer'
import { PhoneInput, usePhoneInputRef } from 'components/TextInput/PhoneInput'
import { TextInput } from 'components/TextInput/TextInput'
import { useField, useForm } from 'hooks/useForm'
import { FunctionComponent } from 'react'
import * as v from 'utils/validation'
import omit from 'lodash/omit'
import { useAsync } from 'hooks/useAsync'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { useService } from 'hooks/useService'
import { registerUserService } from 'services/user'
import { useNavigation } from 'hooks/useNavigation'
import { IconType } from 'components/Icon/Icon.types'

export const SignUpForm: FunctionComponent = () => {
  const navigation = useNavigation()
  const phoneRef = usePhoneInputRef()

  const displayName = useField<string>('', 'display name', [
    v.required(),
    v.minLength(3),
    v.maxLength(20)
  ])

  const mobileNumber = useField<string>('', 'mobile number', [
    v.required(),
    v.mobileNumber(phoneRef)
  ])

  const registerUser = useService(registerUserService)

  const signUp = useAsync<void, []>(async () => {
    await registerUser(values)
    navigation.navigate('VerificationCode')
  })

  const { submit, isValid, values } = useForm(
    { displayName, mobileNumber },
    signUp
  )

  return (
    <>
      <Field field={displayName} label="Display name">
        <TextInput {...displayName.props.textInput} />
      </Field>

      <Spacer />

      <Field field={mobileNumber} label="Mobile number">
        <PhoneInput
          ref={phoneRef}
          onChangePhoneNumber={mobileNumber.onChange}
          initialValue=""
          textProps={{
            maxLength: 15,
            ...omit(mobileNumber.props.textInput, 'onChangeText', 'value')
          }}
        />
      </Field>

      <Spacer />

      <ErrorDisplay error={signUp.error} />

      <Button
        type="primary"
        onPress={submit}
        iconRight={IconType.CaretRight}
        loading={signUp.isLoading}
        disabled={!isValid}
      >
        Continue
      </Button>
    </>
  )
}
