import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import { useAsync } from 'hooks/useAsync'
import { useService } from 'hooks/useService'
import { FunctionComponent } from 'react'
import { loadGenerationOptionsService } from 'services/generation'
import { ErrorView } from 'view/ErrorView/ErrorView'
import { LoadingView } from 'view/LoadingView/LoadingView'
import { LoadedWizardView } from './LoadedWizardView'

const DEFAULT_TYPE = 'character'

export const GenerateWizardView: FunctionComponent<
  Partial<RouteProps<'NewEntryByType'>>
> = ({ route: { params: { type = DEFAULT_TYPE } = {} } = {} }) => {
  const loadOptions = useService(loadGenerationOptionsService)

  const { result, error, isLoading } = useAsync(
    async () => loadOptions({ type }),
    { initRun: true, initWith: [] }
  )

  if (isLoading) {
    return <LoadingView />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (!result?.data) {
    return <ErrorView error={new Error('Could not load generation data')} />
  }

  return (
    <LoadedWizardView
      tree={result.data.tree}
      options={result.data.options}
      type={type}
    />
  )
}
