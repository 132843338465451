import { TextStyle } from 'react-native'
import { themed } from 'hooks/useTheme'
import { useStyles as useHeadingStyles } from 'components/Heading/Heading.styles'
import { ViewStyle } from 'react-native-phone-input'

interface Styles {
  focus: ViewStyle
  blur: ViewStyle
  title: TextStyle
  largeQuote: TextStyle
  long: TextStyle
}

export const { use: useStyles } = themed<Styles, { longish?: boolean }>(
  ({ fonts, colors }, { longish }): Styles => {
    const titleStyle = useHeadingStyles({ type: 'title' })
    const subtitleStyle = useHeadingStyles({ type: 'subtitle' })

    return {
      focus: {
        backgroundColor: colors.input
      },

      blur: {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: colors.input,
        backgroundColor: 'transparent'
      },

      //   editableText: {
      //     flex: 1,
      //   },

      title: {
        ...titleStyle,
        textAlign: 'center',
        ...(longish ? { fontSize: 26 } : {})
      },

      largeQuote: {
        ...subtitleStyle,
        fontFamily: fonts.mainItalic,
        textAlign: 'center'
      },

      long: { fontSize: 16, color: colors.text.light, textAlign: 'left' }
    }
  }
)
