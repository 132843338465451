import { ToastOptions } from 'components/Toasters/Toast'
import { useDeferred } from 'hooks/useDeferred'
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState
} from 'react'

export type ToastState = ToastOptions & {
  id: number
  status: 'in' | 'show' | 'out'
  timer?: NodeJS.Timer
}

interface ToasterContextState {
  increment: number
  toasts: ToastState[]
  showToast: (props: ToastOptions) => void
  closeToast: (id: number) => void
}

export const TOAST_TRANSITION_MS = 200

const TOAST_LIFETIME_MS = 3000

const ToasterContext = createContext<ToasterContextState>({
  increment: 0,
  toasts: [],
  showToast: () => null,
  closeToast: () => null
})

export const useToaster = () => useContext(ToasterContext)

export const ToasterProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children
}) => {
  const [toasts, setToasts] = useState<ToastState[]>([])
  const [increment, setIncrement] = useState(0)

  const getIndex = useDeferred((id: number) => {
    const match = toasts.find((t) => t.id === id)
    const index = match ? toasts.indexOf(match) : -1
    return index === -1 ? null : index
  })

  const updateToast = useDeferred((id: number, toast: Partial<ToastState>) => {
    const index = getIndex(id)

    if (index !== null && toasts[index]) {
      setToasts([
        ...toasts.slice(0, index),
        { ...toasts[index]!, ...toast },
        ...toasts.slice(index + 1)
      ])
    }
  })

  const onToastClosed = useDeferred((id: number) => {
    const index = getIndex(id)

    if (index !== null) {
      setToasts([...toasts.slice(0, index), ...toasts.slice(index + 1)])
    }
  })

  const closeToast = useDeferred((id: number) => {
    const index = getIndex(id)

    if (index !== null) {
      const toast = toasts[index]
      toast?.timer && clearTimeout(toast?.timer)

      const timer = setTimeout(() => onToastClosed(id), TOAST_TRANSITION_MS)

      updateToast(id, { timer, status: 'out' })
    }
  })

  const onToastEntered = useDeferred((id: number) => {
    const timer = setTimeout(() => closeToast(id), TOAST_LIFETIME_MS)
    updateToast(id, { status: 'show', timer })
  })

  const showToast = useDeferred((props: ToastOptions) => {
    const id = increment
    const timer = setTimeout(() => onToastEntered(id), TOAST_TRANSITION_MS)

    setToasts([...toasts, { id: increment, ...props, status: 'in', timer }])
    setIncrement(increment + 1)
  })

  return (
    <ToasterContext.Provider
      value={{ increment, toasts, showToast, closeToast }}
    >
      {children}
    </ToasterContext.Provider>
  )
}
