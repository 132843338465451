import { useAuth } from 'hooks/useAuth'
import { FunctionComponent } from 'react'
import { LandingView } from 'view/LandingView/LandingView'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { AccountView } from 'view/AccountView/AccountView'
import { AppNavigationRoutes } from 'components/AppNavigation/AppNavigation.types'
import { defaultTabNavigatorProps } from 'components/AppNavigation/AppNavigation.constants'
import { ListUserEntriesView } from 'view/ListUserEntriesView/ListUserEntriesView'
import { CampaignsView } from 'view/CampaignsView/CampaignsView'
import { CreditsView } from 'view/CreditsView/CreditsView'
import { NewEntryView } from 'view/NewEntryView/NewEntryView'

const Tab = createBottomTabNavigator<AppNavigationRoutes>()

export const HomeView: FunctionComponent = () => {
  const { isSignedIn } = useAuth()

  if (!isSignedIn) {
    return <LandingView />
  }

  return (
    <Tab.Navigator {...defaultTabNavigatorProps}>
      <Tab.Screen name="Main" component={ListUserEntriesView} />
      <Tab.Screen name="Campaigns" component={CampaignsView} />
      <Tab.Screen name="NewEntry" component={NewEntryView} />
      <Tab.Screen name="Credits" component={CreditsView} />
      <Tab.Screen name="Account" component={AccountView} />
    </Tab.Navigator>
  )
}
