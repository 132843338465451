import { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { ThemeContext } from 'context/theme'
import { darkTheme } from 'styles/theme-dark'
import { setBackgroundColorAsync } from 'expo-system-ui'
import { Theme } from 'types/theme'

type ThemeProviderProps = PropsWithChildren<{
  theme?: Theme
}>

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  children,
  theme = darkTheme
}) => {
  useEffect(() => {
    setBackgroundColorAsync(theme.palette.background)
  }, [])

  return (
    <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>
  )
}
