import { AxiosError } from 'axios'
import { Text } from 'components/Text/Text'
import { FunctionComponent } from 'react'
import { View } from 'react-native'
import { useStyles } from './ErrorDisplay.styles'
import { sentenceCase } from 'sentence-case'
import { BulletList } from 'components/BulletList/BulletList'
import { TextStyle } from 'react-native-phone-input'

interface ErrorDisplayProps {
  error: Error | null
  fullScreen?: boolean
  title?: string
}

interface Issue {
  message: string
  path?: string[]
}

export const ErrorDisplay: FunctionComponent<ErrorDisplayProps> = ({
  error,
  fullScreen = false,
  title
}) => {
  const styles = useStyles(fullScreen)

  if (!error) {
    return null
  }

  return (
    <View style={styles.container}>
      {title && <Text style={styles.text}>{title}</Text>}

      <RawErrorDisplay error={error} style={styles.text} />
    </View>
  )
}

export const RawErrorDisplay: FunctionComponent<{
  error: Error | null
  style?: TextStyle
}> = ({ error, style }) => {
  let message = error?.message
  let messages: string[] = []

  if (!error) {
    return null
  }

  if (error instanceof AxiosError) {
    const errorData = error.response?.data?.error

    message = errorData?.message || message

    if (errorData?.issues instanceof Array) {
      messages = [
        ...messages,
        ...errorData?.issues.map((issue: Issue) =>
          [issue.path && sentenceCase(issue.path.join('.')), issue.message]
            .filter(Boolean)
            .join(': ')
        )
      ]
    }
  }

  return (
    <>
      {!messages.length && <Text style={style}>{message}</Text>}

      {messages && (
        <BulletList>
          {messages.map((message) => (
            <BulletList.Item key={message} style={style}>
              {message}
            </BulletList.Item>
          ))}
        </BulletList>
      )}
    </>
  )
}
