import { FunctionComponent } from 'react'
import { TextProps, Text } from 'components/Text/Text'
import { useStyles } from './Heading.styles'

type HeadingProps = TextProps & {
  center?: boolean
}

export const Title: FunctionComponent<HeadingProps> = ({
  style,
  center,
  ...props
}) => <Text style={[useStyles({ type: 'title', center }), style]} {...props} />

export const Subtitle: FunctionComponent<HeadingProps> = ({
  style,
  center,
  ...props
}) => (
  <Text style={[useStyles({ type: 'subtitle', center }), style]} {...props} />
)
