import { CSSProperties, FunctionComponent } from 'react'
import { View } from 'react-native'
import { ViewStyle } from 'react-native-phone-input'
import { FadingMaskViewProps, FadingMaskViewType } from './FadingMaskView'
import boxMask from './assets/box.png'

const MASK_GRADIENT_ABRUPT = `gradient(${[
  'linear',
  'center top',
  'center bottom',
  'color-stop(0.00,  rgba(0,0,0,1))',
  'color-stop(0.50,  rgba(0,0,0,1))',
  'color-stop(1.00,  rgba(0,0,0,0))'
].join(', ')})`

const MASK_GRADIENT_LINEAR = `gradient(${[
  'linear',
  'center top',
  'center bottom',
  'color-stop(0.00,  rgba(0,0,0,1))',
  'color-stop(1.00,  rgba(0,0,0,0))'
].join(', ')})`

const masks: Record<FadingMaskViewType, Array<[string, string]>> = {
  none: [],

  linear: [
    ['maskImage', MASK_GRADIENT_ABRUPT],
    ['WebkitMaskImage', `-webkit-${MASK_GRADIENT_ABRUPT}`]
  ],
  abrupt: [
    ['maskImage', MASK_GRADIENT_LINEAR],
    ['WebkitMaskImage', `-webkit-${MASK_GRADIENT_LINEAR}`]
  ],
  box: [
    ['maskImage', `url(${boxMask})`],
    ['WebkitMaskImage', `url(${boxMask})`]
  ]
}

export const FadingMaskView: FunctionComponent<FadingMaskViewProps> = ({
  style,
  type = 'linear',
  ...props
}) => {
  const styles: Record<string, string> = {}

  for (const [attr, value] of masks[type]) {
    styles[attr] = value
  }

  return (
    <View
      style={[
        {
          ...styles,
          maskSize: '100% 100%',
          WebkitMaskSize: '100% 100%'
        } as CSSProperties as ViewStyle,
        style
      ]}
      {...props}
    ></View>
  )
}
