import { FadingView } from 'components/FadingView/FadingView'
import { Icon } from 'components/Icon/Icon'
import { IconType } from 'components/Icon/Icon.types'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { Spinner } from 'components/Spinner/Spinner'
import { Text } from 'components/Text/Text'
import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useState
} from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { useStyles } from './Button.styles'

export type ButtonSize = 'medium' | 'small' | 'tiny'

export type ButtonType = 'primary' | 'secondary' | 'bright' | 'danger'

type IconProp =
  | IconType
  | (({ textStyle }: { textStyle: TextStyle }) => ReactNode)

export type ButtonProps = PropsWithChildren<{
  style?: StyleProp<ViewStyle>
  outline?: boolean
  subtle?: boolean
  type?: ButtonType
  size?: ButtonSize
  disabled?: boolean
  loading?: boolean
  iconLeft?: IconProp
  iconRight?: IconProp
  inline?: boolean
  onPress?: () => void
}>

export const Button: FunctionComponent<ButtonProps> = (props) => {
  const {
    loading = false,
    style,
    disabled,
    onPress,
    children,
    iconLeft,
    iconRight
  } = props
  const [pressed, setPressed] = useState(false)
  const styles = useStyles({ ...props, pressed })

  return (
    <MaybePressable
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      style={[styles.container, style]}
      onPress={onPress}
      disabled={disabled}
      android_ripple={styles.ripple}
    >
      <FadingView style={styles.spinnerContainer} show={loading}>
        <Spinner {...styles.spinnerProps} size="small" />
      </FadingView>

      <FadingView show={!loading} style={styles.inner}>
        <ButtonIcon icon={iconLeft} otherIcon={iconRight} {...props} />

        <Text style={styles.text}>{children}</Text>

        <ButtonIcon icon={iconRight} otherIcon={iconLeft} {...props} />
      </FadingView>
    </MaybePressable>
  )
}

const ButtonIcon: FunctionComponent<
  Omit<ButtonProps, 'icon' | 'otherIcon'> & {
    icon?: IconProp
    otherIcon?: IconProp
  }
> = ({ icon, otherIcon, inline, ...props }) => {
  const hasIcon = !!(icon || otherIcon)
  const styles = useStyles(props)

  if (hasIcon) {
    if (icon) {
      return (
        <View style={styles.icon}>
          {typeof icon === 'function' ? (
            icon({ textStyle: styles.iconText })
          ) : (
            <Icon type={icon} style={styles.iconText} />
          )}
        </View>
      )
    } else if (!inline) {
      return <View style={styles.iconSpacer} />
    }
  }

  return null
}
