import { Text } from 'components/Text/Text'
import { HeadImage } from 'components/HeadImage/HeadImage'
import headImage from './assets/head-image.png'
import { Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'
import { CodeInput } from 'components/CodeInput/CodeInput'
import { useAsync } from 'hooks/useAsync'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent, useEffect, useState } from 'react'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { KeyboardPaddedView } from 'components/KeyboardPaddedView/KeyboardPaddedView'
import { verifyCodeService } from 'services/auth'
import { useAuth } from 'hooks/useAuth'
import { useService } from 'hooks/useService'
import { useNavigation } from 'hooks/useNavigation'
import { FadingSpinner } from 'components/FadingSpinner/FadingSpinner'

const VERIFICATION_CODE_LENGTH = 6

export const VerificationCodeView: FunctionComponent = () => {
  const [code, setCode] = useState('')
  const { onSignedIn, signOut } = useAuth()
  const verifyCode = useService(verifyCodeService)
  const { popToTop, navigate } = useNavigation()

  const { error, isLoading, reset, start } = useAsync(async () => {
    const result = await verifyCode({ code })

    if (result.data.action === 'activation' || result.data.action === 'login') {
      await onSignedIn(result.data.token, result.data.user, result.data.pricing)
      popToTop()
      navigate('Home')
    } else if (result.data.action === 'deletion') {
      await signOut()
      popToTop()
      navigate('Home')
    }
  })

  useEffect(() => {
    error && setCode('')
  }, [error])

  useEffect(() => {
    code && reset()
  }, [code])

  const onCodeEntered = start

  return (
    <DefaultLayout>
      <HeadImage source={headImage} subtle />

      <DefaultLayout.Content>
        <KeyboardPaddedView>
          <Spacer size={10} />

          <Title center>Confirmation code</Title>

          <Spacer />

          <Text center>
            We’ve just sent you a verification code on your mobile number
          </Text>

          <Spacer size={4} />

          <CodeInput
            value={code}
            onChange={setCode}
            length={VERIFICATION_CODE_LENGTH}
            onComplete={onCodeEntered}
          />

          <Spacer size={4} />

          <ErrorDisplay error={error} />

          <FadingSpinner show={isLoading} size="small" />
        </KeyboardPaddedView>
      </DefaultLayout.Content>
    </DefaultLayout>
  )
}
