import { forwardRef } from 'react'
import { ActivityIndicator, ActivityIndicatorProps } from 'react-native'
import { useStyles } from './Spinner.styles'

export type SpinnerVariant = 'default' | 'foreground' | 'subtle'

export type SpinnerProps = ActivityIndicatorProps & {
  variant?: SpinnerVariant
}

export const Spinner = forwardRef<ActivityIndicator, SpinnerProps>(
  ({ size = 'large', color, variant, ...props }, ref) => {
    const styles = useStyles()

    return (
      <ActivityIndicator
        ref={ref}
        size={size}
        color={
          color || (variant && styles[variant]?.color) || styles.default.color
        }
        {...props}
      />
    )
  }
)
