import { INITIAL_AUTH_STATE } from 'context/app-storage'
import { useToaster } from 'context/toaster'
import { getCurrentUserService } from 'services/user'
import { AuthState } from 'types/auth'
import { PrivateUserInfo, UserPricing } from 'types/user'
import { useAppStorage } from './useAppStorage'
import { useDeferred } from './useDeferred'
import { useService } from './useService'

export const useAuth = () => {
  const storage = useAppStorage()
  const getCurrentuser = useService(getCurrentUserService)
  const authState = useAppStorage().data.auth
  const { token } = authState
  const { showToast } = useToaster()

  const reloadAuth = useDeferred(async () => {
    if (!token) {
      return
    }

    try {
      const {
        data: { user, pricing }
      } = await getCurrentuser()

      storage.setValue({ auth: { token, isSignedIn: true, user, pricing } })
    } catch (error) {
      showToast({ type: 'error', error: error as Error })
      signOut()
    }
  })

  const signOut = () => storage.setValue({ auth: INITIAL_AUTH_STATE })

  const onSignedIn = async (
    token: string,
    user: PrivateUserInfo,
    pricing: UserPricing
  ) => {
    const auth: AuthState = { isSignedIn: true, token, user, pricing }
    await storage.setValue({ auth })
  }

  return { ...authState, onSignedIn, signOut, reloadAuth }
}
