import { Spinner } from 'components/Spinner/Spinner'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'

export const LoadingView: FunctionComponent = () => (
  <DefaultLayout>
    <DefaultLayout.Content
      style={{ alignContent: 'center', justifyContent: 'center' }}
    >
      <Spinner size="large" />
    </DefaultLayout.Content>
  </DefaultLayout>
)
