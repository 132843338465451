import { Theme } from 'types/theme'

const palette: Theme['palette'] = {
  background: '#1E1C26',
  foreground: '#39344c',
  mid: '#D3DCEB',
  contrast: '#F56C7A',
  accent: '#FFD57A',
  white: '#FFFFFF',
  black: '#000000',
  error: '#DD1142',
  success: '#15D3A6',
  subtle: 'rgba(255, 255, 255, .1)',
  imageBackground: '#111015'
}

export const darkTheme: Theme = {
  name: 'Dark',
  palette,
  misc: { dark: true, radius: 10, imageRadius: 5 },
  colors: {
    text: {
      default: palette.white,
      light: palette.mid,
      overlay: palette.white,
      error: palette.white,
      success: '#005C46'
    },
    overlay: palette.black,
    input: palette.subtle,
    header: {
      main: palette.black,
      text: palette.white,
      active: palette.accent
    }
  },
  fonts: {
    main: 'IBMPlexSans_400Regular',
    mainItalic: 'IBMPlexSans_400Regular_Italic',
    light: 'IBMPlexSans_300Light',
    lightItalic: 'IBMPlexSans_300Light_Italic',
    bold: 'IBMPlexSans_600SemiBold',
    boldItalic: 'IBMPlexSans_600SemiBold_Italic',
    special: 'IBMPlexSerif_600SemiBold',
    specialItalic: 'IBMPlexSerif_600SemiBold_Italic'
  },
  sizes: {
    pageWidth: 1000,
    smallContentWidth: 600
  }
}
