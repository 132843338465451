import { themed } from 'hooks/useTheme'
import type { ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'

interface Styles {
  spacer: ViewStyle
  mainContainer: ViewStyle
  iconContainer: ViewStyle
  icon: TextStyle
}

const ICON_CONTAINER_SIZE = 50

export const { use: useStyles } = themed<Styles>(
  (): Styles => ({
    spacer: { width: ICON_CONTAINER_SIZE },

    mainContainer: { flex: 1 },

    iconContainer: {
      width: ICON_CONTAINER_SIZE,
      height: ICON_CONTAINER_SIZE,
      marginTop: 8,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent'
    },

    icon: { textAlign: 'right', fontSize: 25, marginRight: -20 }
  })
)
