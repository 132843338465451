import { Z_INDEX } from 'constants/ui'
import { StatusBarProps } from 'expo-status-bar'
import { useMedia } from 'hooks/useMedia'
import { themed } from 'hooks/useTheme'
import { Platform, ViewStyle } from 'react-native'

interface Styles {
  background: ViewStyle
  outer: ViewStyle
  container: ViewStyle
  content: ViewStyle
  scrollContent: ViewStyle
  header: ViewStyle
  headerAndContent: ViewStyle
  footer: ViewStyle
  statusBarProps: StatusBarProps
}

const CONTAINER_STYLES: ViewStyle = {
  alignItems: 'center',
  width: '100%',
  maxWidth: 1000,
  padding: 20,
  marginHorizontal: 'auto',
  alignSelf: 'stretch'
}

export const { use: useStyles } = themed<Styles>(({ palette, misc }) => {
  const { isDesktop } = useMedia()

  return {
    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: palette.background,
      zIndex: Z_INDEX.BACKGROUND
    },

    outer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },

    container: {
      width: '100%',
      ...(Platform.OS === 'web' && isDesktop
        ? ({
            position: 'static',
            overflow: 'auto',
            display: 'block',
            maxHeight: '100%'
          } as unknown as ViewStyle)
        : { position: 'absolute', height: '100%' })
    },

    content:
      Platform.OS === 'web' && isDesktop
        ? {
            ...CONTAINER_STYLES,
            height: 'auto',
            width: '100%',
            flex: 1
          }
        : { flex: 1, ...CONTAINER_STYLES, justifyContent: 'center' },

    scrollContent: { flex: 1 },

    header: { ...CONTAINER_STYLES },

    headerAndContent: {
      ...CONTAINER_STYLES,
      paddingTop: isDesktop ? 0 : 200,
      flex: 1
    },

    footer: {
      ...CONTAINER_STYLES,
      ...(Platform.OS === 'web' ? { maxWidth: 500 } : {})
    },

    statusBarProps: { style: misc.dark ? 'light' : 'dark' }
  }
})
