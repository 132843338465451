import { NavigationProp } from 'components/AppNavigation/AppNavigation.types'
import { DropDownItemProps } from 'components/DropDown/DropDown'
import { IconType } from 'components/Icon/Icon.types'
import { downloadEntryImage } from './downloads'

export const getEntryImageDropDownItems = ({
  entryId,
  imageId,
  navigation: { push }
}: Record<'entryId' | 'imageId', number> & {
  navigation: NavigationProp
}): DropDownItemProps[] => [
  {
    icon: IconType.Eye,
    label: 'View',
    onSelect: () => push('ViewEntryImage', { entryId, imageId })
  },
  {
    icon: IconType.Download,
    label: 'Download',
    onSelect: () => downloadEntryImage({ entryId, imageId })
  }
]
