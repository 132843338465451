import { FunctionComponent, ReactElement } from 'react'
import { View, ViewProps } from 'react-native'
import { useStyles } from './Grid.styles'

export interface GridOptions {
  columns: number
  columnsTablet?: number
  columnsDesktop?: number
  gutter?: number
}

type GridProps = Omit<ViewProps, 'children'> & {
  children: ReactElement[]
} & GridOptions

export const Grid: FunctionComponent<GridProps> = ({
  style,
  columns,
  columnsTablet,
  columnsDesktop,
  children,
  gutter,
  ...props
}) => (
  <View
    {...props}
    style={[
      useStyles({ columns, columnsTablet, columnsDesktop, gutter }).container,
      style
    ]}
  >
    {children.map((child, i) => (
      <GridCell
        columns={columns}
        columnsTablet={columnsTablet}
        columnsDesktop={columnsDesktop}
        gutter={gutter}
        key={i}
      >
        {child}
      </GridCell>
    ))}
  </View>
)

const GridCell: FunctionComponent<ViewProps & GridOptions> = ({
  style,
  columns,
  columnsDesktop,
  columnsTablet,
  gutter,
  ...props
}) => (
  <View
    {...props}
    style={[
      useStyles({ columns, gutter, columnsDesktop, columnsTablet }).cell,
      style
    ]}
  />
)
