import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { getConfig } from 'core/config'
import { Platform } from 'react-native'
import { version as appVersion } from '../../package.json'
import { AppEvent, getAppEvents } from './events'

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete'

export const getApi = (authToken: string | null = null) =>
  axios.create({
    baseURL: getConfig().apiUrl,
    headers: {
      ...(authToken ? { authorization: authToken } : {}),
      'x-app-platform': Platform.OS,
      'x-app-version': appVersion
    }
  })

export const typedService =
  <Payload, Result>(
    method: Method,
    path: string,
    { reloadAuth = false }: { reloadAuth?: boolean } = {}
  ) =>
  async (api: AxiosInstance, payload: Payload) => {
    let url = path
    const data = { ...payload }

    for (const param in data) {
      if (url.includes(`{${param}}`)) {
        url = url.replace(`{${param}}`, String(data[param]))
        delete data[param]
      }
    }

    const res = await api.request<Payload, AxiosResponse<Result>>({
      method,
      url,
      data: method === 'get' ? undefined : data
    })

    if (reloadAuth) {
      getAppEvents().emit(AppEvent.ReloadAuth)
    }

    return res
  }
