import { PhoneInputRef } from 'components/TextInput/PhoneInput'
import { Validator } from 'hooks/useForm'
import upperFirst from 'lodash/upperFirst'
import { pluralise } from './string'

export const required =
  (message?: string): Validator<unknown> =>
  (value, key) => {
    if (typeof value === 'number' && !Number.isNaN(value)) {
      return null
    }

    return !!value ? null : message || `${upperFirst(key)} is required`
  }

export const minLength =
  (length: number, message?: string): Validator<string> =>
  (value, key) =>
    value.length >= length
      ? null
      : message ||
        `${upperFirst(key)} must be at least ${length} ${pluralise(
          'character',
          length
        )} long`

export const maxLength =
  (length: number, message?: string): Validator<string> =>
  (value, key) =>
    value.length <= length
      ? null
      : message ||
        `${upperFirst(key)} cannot be longer than ${length} ${pluralise(
          'character',
          length
        )}`

export const mobileNumber =
  (phoneRef: PhoneInputRef): Validator<string> =>
  () => {
    if (!phoneRef.current?.isValidNumber) {
      return 'Must be a valid mobile number'
    }

    if (phoneRef.current?.getNumberType() !== 'MOBILE') {
      return 'You can only use mobile numbers to register'
    }

    return null
  }
