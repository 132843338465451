import { useBackHandler } from '@react-native-community/hooks'
import { usePolling } from 'hooks/usePolling'
import { useService } from 'hooks/useService'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { editEntryByIdService } from 'services/entries'
import { DetailedEntryInfo } from 'types/entries'
import { TextBitDisplayProps } from './TextBitDisplay/TextBitDisplay'

type EditableData = Pick<DetailedEntryInfo, 'textBits' | 'displayName'>

export const useEntryViewState = (
  entry: DetailedEntryInfo,
  reload: (entry?: DetailedEntryInfo) => void
) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [editData, setEditData] = useState<EditableData>(entry)
  const textBitProps: Pick<TextBitDisplayProps, 'isEditMode'> = {
    isEditMode
  }
  const editEntryById = useService(editEntryByIdService)

  const startEdit = () => setIsEditMode(true)
  const cancelEdit = () => setIsEditMode(false)
  const saveEdit = async () => {
    const textBits = editData.textBits.map((text, i) => ({
      ...text,
      priority: i + 1
    }))

    reload(
      (
        await editEntryById({
          id: entry.id,
          displayName: editData.displayName,
          textBits
        })
      ).data.entry
    )

    setIsEditMode(false)
  }

  useBackHandler(() => {
    if (isEditMode) {
      setIsEditMode(false)
      return true
    }

    return false
  })

  useEffect(() => {
    isEditMode &&
      setEditData(
        cloneDeep({
          textBits: entry.textBits,
          displayName: entry.displayName
        })
      )
  }, [isEditMode])

  const displayData = isEditMode ? editData : entry

  return {
    displayData,
    isEditMode,
    setIsEditMode,
    textBitProps,
    startEdit,
    editData,
    setEditData,
    cancelEdit,
    saveEdit
  }
}

export const useEntryPolling = (
  entry: DetailedEntryInfo,
  reload: () => void,
  isReloading: boolean
) => {
  usePolling(entry.queued, () => !!entry.queued, reload)

  return { isLoading: !!(entry.queued || isReloading) }
}
