import { Title } from 'components/Heading/Heading'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import {
  deleteCampaignByIdService,
  listCampaignsService
} from 'services/campaigns'
import { Text } from 'components/Text/Text'
import { Button } from 'components/Button/Button'
import { IconType } from 'components/Icon/Icon.types'
import { useNavigation } from 'hooks/useNavigation'
import { ItemsList, ItemsListItem } from 'components/ItemsList/ItemsList'
import { withResult } from 'hoc/withAsyncResult'
import { useTabFocus } from 'hooks/useTabFocus'
import { Campaign } from 'types/campaigns'
import headImage from './assets/head-image.png'

export const CampaignsView = withResult(
  () => {
    const listCampaigns = useService(listCampaignsService)

    return async () => ({
      campaigns: (await listCampaigns()).data.campaigns
    })
  },
  ({ campaigns, reload }) => {
    const { navigate, push } = useNavigation()
    const deleteCampaignById = useService(deleteCampaignByIdService)

    const deleteCampaign = async (campaignId: number) => {
      await deleteCampaignById({ campaignId })
      reload()
    }

    useTabFocus(() => {
      reload()
    })

    return (
      <DefaultLayout toBottomEdge headImage={{ source: headImage, subtle: 2 }}>
        <DefaultLayout.Header>
          <Title center>Campaigns</Title>
        </DefaultLayout.Header>

        {!campaigns?.length ? (
          <DefaultLayout.Content>
            <Text center>
              There’s nothing here yet.
              {'\n\n'}
              Create your first campaign and invite your party to start sharing
              creations!
            </Text>
          </DefaultLayout.Content>
        ) : (
          <ItemsList<Campaign>
            stretch={false}
            onRefresh={reload}
            data={campaigns}
            renderItem={({ item: campaign }) => (
              <ItemsListItem
                icon={IconType.Book}
                key={campaign.id}
                onPress={() => push('ViewCampaignEntries', { id: campaign.id })}
                dropDown={
                  campaign.isOwner
                    ? {
                        items: [
                          {
                            icon: IconType.Envelope,
                            label: 'Invites',
                            onSelect: () =>
                              navigate('CampaignInvites', { id: campaign.id })
                          },
                          {
                            icon: IconType.Trash,
                            label: 'Delete',
                            requireConfirmation: true,
                            onSelect: () => deleteCampaign(campaign.id)
                          }
                        ]
                      }
                    : undefined
                }
              >
                <Text>{campaign.title}</Text>
              </ItemsListItem>
            )}
          />
        )}

        <DefaultLayout.Footer>
          <Button
            type="primary"
            iconLeft={IconType.Plus}
            onPress={() => push('CreateCampaign')}
          >
            New campaign
          </Button>
        </DefaultLayout.Footer>
      </DefaultLayout>
    )
  }
)
