import { FieldState } from 'hooks/useForm'
import { createContext, useContext } from 'react'

const emptyFn = () => null

export const FieldContext = createContext<FieldState<any>>({
  available: false,
  value: null,
  onChange: emptyFn,
  onFocus: emptyFn,
  onBlur: emptyFn,
  focused: false,
  pristine: true,
  submitted: false,
  setSubmitted: emptyFn,
  valid: false,
  submit: () => {},
  submitRef: { current: () => {} },
  error: null,
  props: { textInput: {} }
})

export const useFieldState = () => useContext(FieldContext)
