import { themed } from 'hooks/useTheme'
import { TextStyle, ViewStyle } from 'react-native'
import { ToastType } from './Toast'

type Styles = Record<
  ToastType | 'base',
  {
    container: ViewStyle
    inner?: ViewStyle
    icon?: TextStyle
    text?: TextStyle
    content?: ViewStyle
  }
>

export const { use: useStyles } = themed<Styles>(
  ({ palette, misc, sizes, colors }): Styles => ({
    base: {
      container: {
        backgroundColor: palette.foreground,
        marginBottom: 10,
        borderRadius: misc.radius,
        maxWidth: sizes.smallContentWidth,
        marginHorizontal: 'auto'
      },

      inner: { alignSelf: 'stretch', padding: 20, flexDirection: 'row' },

      content: {},

      text: { color: colors.text.default },

      icon: { marginRight: 15, marginTop: 4 }
    },

    error: {
      container: { backgroundColor: palette.error },
      text: { color: colors.text.error }
    },

    success: {
      container: { backgroundColor: palette.success },
      text: { color: colors.text.success }
    }
  })
)
