import { Subtitle, Title } from 'components/Heading/Heading'
import { Spacer } from 'components/Spacer/Spacer'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import { GenerationOption, GeneratorTreeOptions } from 'types/generation'
import { useWizardState } from './GenerateWizardView.hooks'
import { ChoicesMenu } from './ChoicesMenu/ChoicesMenu'
import { Row } from 'components/Row/Row'
import { Button } from 'components/Button/Button'
import { useAsync } from 'hooks/useAsync'
import { generateEntryService } from 'services/generation'
import { useNavigation } from 'hooks/useNavigation'
import { useService } from 'hooks/useService'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { IconType } from 'components/Icon/Icon.types'
import { ButtonWithPrice } from 'components/ButtonWithPrice/ButtonWithPrice'
import { useAuth } from 'hooks/useAuth'
import { getEntryTypeImageUrl } from 'utils/entry-type'
import { PURE_CHOICES } from 'constants/features'

interface LoadedWizardViewProps {
  type: string
  tree: GeneratorTreeOptions
  options: GenerationOption[]
}

export const LoadedWizardView: FunctionComponent<LoadedWizardViewProps> = ({
  type,
  tree,
  options
}) => {
  const wizardState = useWizardState(type, tree, options)
  const {
    setAllRandom,
    hasSetAllRandom,
    clearOptional,
    hasClearedOptional,
    reset,
    hasReset,
    getChoicesPayload,
    isValid
  } = wizardState
  const { navigate } = useNavigation()
  const { pricing } = useAuth()
  const generateEntry = useService(generateEntryService)
  const generate = useAsync(async () => {
    const { entry } = (
      await generateEntry({ type, choices: getChoicesPayload() })
    ).data
    navigate('Entry', { id: entry.id })
  })

  return (
    <DefaultLayout
      headImage={{
        source: { uri: getEntryTypeImageUrl(type, '512') },
        subtle: 2
      }}
      toBottomEdge
    >
      <DefaultLayout.Header>
        <Title>New {tree.name}</Title>
        <Subtitle>Pick your {tree.name.toLocaleLowerCase()}’s traits</Subtitle>
        <Spacer />

        <Row>
          <Button
            inline
            type="bright"
            outline
            size="tiny"
            onPress={setAllRandom}
            iconLeft={IconType.Dice}
            disabled={hasSetAllRandom}
          >
            Random
          </Button>

          <Spacer horizontal />

          <Button
            inline
            type="bright"
            outline
            size="tiny"
            onPress={PURE_CHOICES ? reset : clearOptional}
            iconLeft={IconType.Clear}
            disabled={PURE_CHOICES ? hasReset : hasClearedOptional}
          >
            {PURE_CHOICES ? 'Reset' : 'Clear optional'}
          </Button>
        </Row>
      </DefaultLayout.Header>

      <DefaultLayout.ScrollContent>
        <ChoicesMenu wizardState={wizardState}></ChoicesMenu>
      </DefaultLayout.ScrollContent>

      <DefaultLayout.Footer>
        {generate.error && <ErrorDisplay error={generate.error} />}

        <ButtonWithPrice
          disabled={!isValid}
          price={pricing!.generation}
          type="primary"
          iconLeft={IconType.Magic}
          loading={generate.isLoading}
          onPress={generate.start}
        >
          Generate
        </ButtonWithPrice>
      </DefaultLayout.Footer>
    </DefaultLayout>
  )
}
