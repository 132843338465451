import { Button } from 'components/Button/Button'
import { Field } from 'components/Field/Field'
import { Spacer } from 'components/Spacer/Spacer'
import { PhoneInput, usePhoneInputRef } from 'components/TextInput/PhoneInput'
import { useField, useForm } from 'hooks/useForm'
import { FunctionComponent } from 'react'
import * as v from 'utils/validation'
import omit from 'lodash/omit'
import { useAsync } from 'hooks/useAsync'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { useService } from 'hooks/useService'
import { useNavigation } from 'hooks/useNavigation'
import { requestSignInService } from 'services/auth'
import { IconType } from 'components/Icon/Icon.types'

export const SignInForm: FunctionComponent = () => {
  const { navigate } = useNavigation()
  const phoneRef = usePhoneInputRef()

  const mobileNumber = useField<string>('', 'mobile number', [
    v.required(),
    v.mobileNumber(phoneRef)
  ])

  const requestSignIn = useService(requestSignInService)

  const signUp = useAsync<void, []>(async () => {
    await requestSignIn(values)
    navigate('VerificationCode')
  })

  const { submit, isValid, values } = useForm({ mobileNumber }, signUp)

  return (
    <>
      <Field field={mobileNumber} label="Mobile number">
        <PhoneInput
          ref={phoneRef}
          onChangePhoneNumber={mobileNumber.onChange}
          initialValue=""
          textProps={{
            maxLength: 15,
            ...omit(mobileNumber.props.textInput, 'onChangeText', 'value')
          }}
        />
      </Field>

      <Spacer />

      <ErrorDisplay error={signUp.error} />

      <Button
        type="primary"
        onPress={submit}
        iconRight={IconType.CaretRight}
        loading={signUp.isLoading}
        disabled={!isValid}
      >
        Continue
      </Button>
    </>
  )
}
