import { useToaster } from 'context/toaster'
import { AsyncAction, useAsync } from 'hooks/useAsync'
import { ReactElement, useEffect } from 'react'

interface ComponentWithActionProps<T = void> {
  fn?: (() => T) | (() => Promise<T>)
  children: (action: AsyncAction<T | undefined, []>) => ReactElement
}

export function ComponentWithAction<T>({
  fn,
  children
}: ComponentWithActionProps<T>): ReactElement {
  const action = useAsync<T | undefined>(async () => fn?.())
  const { showToast } = useToaster()

  useEffect(() => {
    action.error && showToast({ type: 'error', error: action.error })
  }, [action.error])

  return children(action)
}
