import { forwardRef } from 'react'
import {
  RefreshControl as BaseRefreshControl,
  RefreshControlProps
} from 'react-native'
import { useStyles } from './RefreshControl.styles'

export const RefreshControl = forwardRef<
  BaseRefreshControl,
  RefreshControlProps & { spaced?: boolean; safe?: boolean }
>(({ style, spaced = false, safe = false, ...props }, ref) => {
  const styles = useStyles({ spaced, safe })

  return (
    <BaseRefreshControl
      {...styles.refreshControlProps}
      {...props}
      style={[styles.style, style]}
      ref={ref}
    />
  )
})
