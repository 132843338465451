import { typedService } from 'core/api'
import { Campaign, CampaignInvite, CampaignUserInfo } from 'types/campaigns'
import { ShortEntryInfo } from 'types/entries'

export const listCampaignsService = typedService<
  void,
  { campaigns: Campaign[] }
>('get', 'campaigns')

export const createCampaignsService = typedService<
  { title: string },
  { campaign: Campaign }
>('post', 'campaigns')

export const getCampaignByIdService = typedService<
  { campaignId: number },
  { campaign: Campaign }
>('get', 'campaigns/{campaignId}')

export const deleteCampaignByIdService = typedService<
  { campaignId: number },
  { campaign: Campaign; invites: CampaignInvite[] }
>('delete', 'campaigns/{campaignId}')

export const listCampaignInvitesService = typedService<
  { campaignId: number },
  { campaign: Campaign; invites: CampaignInvite[]; users: CampaignUserInfo[] }
>('get', 'campaigns/{campaignId}/invites')

export const sendCampaignInviteService = typedService<
  { campaignId: number; mobileNumber: string },
  void
>('post', 'campaigns/{campaignId}/invites')

export const listCampaignEntriesService = typedService<
  { campaignId: number },
  { entries: ShortEntryInfo[] }
>('get', 'campaigns/{campaignId}/entries')

export const addEntryToCampaignService = typedService<
  { campaignId: number; entryId: number },
  void
>('post', 'campaigns/{campaignId}/entries/{entryId}')

export const removeEntryFromCampaignService = typedService<
  { campaignId: number; entryId: number },
  void
>('delete', 'campaigns/{campaignId}/entries/{entryId}')
