import { Overlay } from 'components/Overlay/Overlay'
import { Z_INDEX } from 'constants/ui'
import { useKeyboard } from 'hooks/useKeyboard'
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react'
import { Easing, View } from 'react-native'
import { Animated } from 'react-native'
import { ViewStyle } from 'react-native-phone-input'

export type FooterFormWrapProps = PropsWithChildren<{ style?: ViewStyle }>

export const FooterFormWrap: FunctionComponent<FooterFormWrapProps> = ({
  children,
  style
}) => {
  const { keyboardShown, keyboardWillBeShown, keyboardHeight } = useKeyboard()

  const [height, setHeight] = useState(0)
  const bottom = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(bottom, {
      toValue: keyboardWillBeShown ? -keyboardHeight : 0,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.in(Easing.elastic(1))
    }).start()
  }, [keyboardWillBeShown])

  return (
    <>
      <Animated.View
        style={[
          style,
          {
            height,
            alignSelf: 'stretch',
            position: 'relative',
            display: keyboardShown ? undefined : 'none'
          }
        ]}
      />

      <Overlay show={keyboardWillBeShown} />

      <View style={style}>
        <Animated.View
          onLayout={(e) =>
            !keyboardShown && setHeight(e.nativeEvent.layout.height)
          }
          style={[
            style,
            {
              width: '100%',
              alignSelf: 'stretch',
              bottom: 0,
              transform: [{ translateY: bottom }]
            },

            keyboardShown && {
              position: 'absolute',
              left: 0,
              justifyContent: 'flex-end',
              zIndex: Z_INDEX.FOOTER_FORM_WRAP
            }
          ]}
        >
          {children}
        </Animated.View>
      </View>
    </>
  )
}
