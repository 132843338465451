import { themed } from 'hooks/useTheme'
import type { TextStyle } from 'react-native'

interface Styles {
  balanceValue: TextStyle
  creditIcon: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ palette }): Styles => ({
    balanceValue: { color: palette.accent },
    creditIcon: { color: palette.accent }
  })
)
