import { themed } from 'hooks/useTheme'
import { ViewStyle } from 'react-native'

interface Styles {
  spinner: ViewStyle
  container: ViewStyle
  content: ViewStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ media: { isTablet } }): Styles => {
    return {
      spinner: { alignSelf: 'center' },

      container: {},

      content: { paddingTop: isTablet ? 320 : 220 }
    }
  }
)
