import { themed } from 'hooks/useTheme'
import type { ViewStyle } from 'react-native'
import { GridOptions } from './Grid'

const DEFAULT_GUTTER = 10

interface Styles {
  container: ViewStyle
  cell: ViewStyle
}

export const { use: useStyles } = themed<Styles, GridOptions>(
  (
    { media },
    {
      columns: columnsMobile,
      columnsTablet = columnsMobile,
      columnsDesktop = columnsMobile,
      gutter = DEFAULT_GUTTER
    }
  ): Styles => {
    let columns = columnsMobile

    if (media.isDesktop) {
      columns = columnsDesktop
    } else if (media.isTablet) {
      columns = columnsTablet
    }

    return {
      container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginHorizontal: gutter * -0.5,
        alignSelf: 'stretch'
      },

      cell: { width: `${100 / columns}%`, padding: gutter / 2 }
    }
  }
)
