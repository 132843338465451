import { HEADER_HEIGHT } from 'constants/ui'
import { themed } from 'hooks/useTheme'
import type { ViewStyle } from 'react-native'
import { TextStyle } from 'react-native-phone-input'

interface Styles {
  header: ViewStyle
  inner: ViewStyle
  navigation: ViewStyle
  navLink: ViewStyle
  navLinkActive: TextStyle
  navLinkText: TextStyle
  navLinkTextActive: TextStyle
  navLinkIcon: TextStyle
  navLinkIconActive: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ sizes, colors }): Styles => {
    return {
      header: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.header.main
      },

      inner: {
        width: sizes.pageWidth,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },

      navigation: {
        flexDirection: 'row'
      },

      navLink: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20
      },

      navLinkText: {
        color: colors.header.text,
        textAlignVertical: 'center',
        lineHeight: HEADER_HEIGHT
      },

      navLinkIcon: {
        color: colors.header.text,
        opacity: 0.3,
        textAlignVertical: 'center',
        marginRight: 10
      },

      navLinkActive: {},

      navLinkIconActive: { color: colors.header.active },

      navLinkTextActive: { color: colors.header.active }
    }
  }
)
