import { Subtitle, Title } from 'components/Heading/Heading'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import headImage from './assets/head-image.png'
import { Text } from 'components/Text/Text'
import * as InAppPurchases from 'expo-in-app-purchases'
import { withResult } from 'hoc/withAsyncResult'
import { ItemsList, ItemsListItem } from 'components/ItemsList/ItemsList'
import { IconType } from 'components/Icon/Icon.types'
import { useAsync } from 'hooks/useAsync'
import { Spinner } from 'components/Spinner/Spinner'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { useEffect } from 'react'
import { useDeferred } from 'hooks/useDeferred'
import { useService } from 'hooks/useService'
import { confirmPurchaseService } from 'services/credits'
import { useAuth } from 'hooks/useAuth'
import { Platform } from 'react-native'
import { useTabFocus } from 'hooks/useTabFocus'
import { Icon } from 'components/Icon/Icon'
import { useStyles } from './CreditView.styles'
import { Spacer } from 'components/Spacer/Spacer'
import { useToaster } from 'context/toaster'

const PRODUCT_IDS = ['credits_10', 'credits_20', 'credits_50', 'credits_100']

export const CreditsView = withResult(
  () => {
    return async () => {
      if (Platform.OS === 'web') {
        return { products: [] }
      }

      await InAppPurchases.connectAsync()

      const productsRes = await InAppPurchases.getProductsAsync(PRODUCT_IDS)

      if (productsRes.responseCode !== InAppPurchases.IAPResponseCode.OK) {
        throw new Error('Failed to load products')
      }

      return { products: productsRes.results }
    }
  },
  ({ products }) => {
    const styles = useStyles()
    const { showToast } = useToaster()
    const confirmPurchase = useService(confirmPurchaseService)

    const initPurchase = async (productId: string) =>
      InAppPurchases.purchaseItemAsync(productId)

    const { reloadAuth, user } = useAuth()

    useTabFocus(() => {
      reloadAuth()
    })

    const {
      isLoading,
      error,
      success,
      start: confirm
    } = useAsync(async (purchase: InAppPurchases.InAppPurchase) => {
      await confirmPurchase({ purchase })

      if (!purchase.acknowledged) {
        await InAppPurchases.finishTransactionAsync(purchase, true)
      }

      await reloadAuth()
    })

    const onPurchase = useDeferred(
      (
        response: InAppPurchases.IAPQueryResponse<InAppPurchases.InAppPurchase>
      ) => {
        if (response.responseCode === InAppPurchases.IAPResponseCode.OK) {
          confirm(response.results![0]!)
        }
      }
    )

    useEffect(() => {
      Platform.OS !== 'web' && InAppPurchases.setPurchaseListener(onPurchase)
    }, [])

    useEffect(() => {
      success &&
        showToast({ type: 'success', text: 'Purchase completed. Thank you!' })
    }, [success])

    return (
      <DefaultLayout headImage={{ source: headImage, subtle: 1 }}>
        <DefaultLayout.Header>
          <Title>Balance</Title>
          <Subtitle>View your balance and top up</Subtitle>

          <Spacer />

          <Text>
            Your current balance:{'  '}
            <Icon style={styles.balanceValue} type={IconType.Credit} />{' '}
            <Text style={styles.balanceValue} bold>
              {user?.credits}
            </Text>
          </Text>
        </DefaultLayout.Header>

        {Platform.OS === 'web' ? (
          <DefaultLayout.Content>
            <Text>Please sign in onto the app to top up credits</Text>
          </DefaultLayout.Content>
        ) : (
          <DefaultLayout.Content>
            {error && <ErrorDisplay error={error} />}
            {isLoading ? (
              <Spinner />
            ) : (
              <ItemsList
                data={products?.sort((a, b) =>
                  Number(a.productId.split('_')[1]) >
                  Number(b.productId.split('_')[1])
                    ? 1
                    : -1
                )}
                renderItem={({ item: product }) => (
                  <ItemsListItem
                    key={product.productId}
                    icon={IconType.Credit}
                    iconStyle={styles.creditIcon}
                    onPress={() => initPurchase(product.productId)}
                  >
                    <Text bold>{product.title}</Text>
                    <Text>{product.price}</Text>
                  </ItemsListItem>
                )}
              />
            )}
          </DefaultLayout.Content>
        )}
      </DefaultLayout>
    )
  }
)
