import { useState } from 'react'
import isEqual from 'lodash/isEqual'
import {
  GenerationChoices,
  GenerationChoiceValue,
  GenerationOption,
  GeneratorTreeOptions,
  RANDOM_VALUE
} from 'types/generation'
import {
  getOptionalClearedChoices,
  getChoicesPayload,
  getRandomChoices,
  getStepChoices as _getStepChoices
} from './GenerateWizardView.utils'
import { isRandom } from 'utils/choices'
import { PURE_CHOICES } from 'constants/features'
import { pickRandom } from 'utils/array'

export const useWizardState = (
  type: string,
  tree: GeneratorTreeOptions,
  options: GenerationOption[]
) => {
  const [choices, setChoices] = useState<GenerationChoices>(
    PURE_CHOICES ? {} : getRandomChoices(tree.steps, options, {})
  )
  const getStepConfig = (step: string) => tree.steps[step] || null

  const validate = () => {
    for (const step of Object.keys(tree.steps)) {
      if (!tree.steps[step]?.optional && !choices[step]) {
        return false
      }
    }

    return true
  }

  const isValid = validate()

  const getStepChoices = (
    withStep: string,
    withChoices: GenerationChoices = choices,
    allowMeta?: boolean
  ) => _getStepChoices(tree.steps, withStep, options, withChoices, allowMeta)

  const setAllRandom = () =>
    setChoices(getRandomChoices(tree.steps, options, choices))

  const hasSetAllRandom = isEqual(
    choices,
    getRandomChoices(tree.steps, options, choices)
  )

  const clearOptional = () =>
    setChoices(getOptionalClearedChoices(tree.steps, choices))

  const hasClearedOptional = isEqual(
    choices,
    getOptionalClearedChoices(tree.steps, choices)
  )

  const reset = () => setChoices({})

  const hasReset = !Object.values(choices).find(Boolean)

  const chooseOption = (
    step: string,
    value: GenerationChoiceValue | GenerationChoiceValue[]
  ) => {
    const newChoices: GenerationChoices = { ...choices, [step]: value }

    for (const step of Object.keys(newChoices)) {
      const value = newChoices[step]
      const available = getStepChoices(step, newChoices, !PURE_CHOICES).map(
        (c) => c.value
      )

      if (typeof value === 'string' && !available.includes(value)) {
        step.includes('tone') &&
          console.log('..', value, available.includes(value))
        if (PURE_CHOICES) {
          delete newChoices[step]
        } else {
          newChoices[step] = RANDOM_VALUE
        }
      }
    }

    setChoices(newChoices)
  }

  const chooseRandom = (step: string) => {
    if (PURE_CHOICES) {
      const { value } = pickRandom(getStepChoices(step, choices, false)) || {}
      const { multiple } = tree.steps[step]!

      chooseOption(step, multiple ? [value] : value)
    } else {
      chooseOption(step, { random: true })
    }
  }

  const getChoiceData = (
    step: string,
    choice: GenerationChoiceValue | GenerationChoiceValue[]
  ) => {
    if (!choice) {
      return { label: 'None' }
    } else if (isRandom(choice)) {
      return { label: 'Random' }
    }

    return options.find((o) => o.type === step && o.id === choice)
  }

  return {
    chooseOption,
    chooseRandom,
    type,
    tree,
    choices,
    options,
    setAllRandom,
    hasSetAllRandom,
    clearOptional,
    hasClearedOptional,
    reset,
    hasReset,
    getStepChoices,
    getChoicesPayload: () => getChoicesPayload(tree.steps, options, choices),
    getChoiceData,
    getStepConfig,
    isValid
  }
}
