import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import { EntriesList } from 'components/EntriesList/EntriesList'
import { Title } from 'components/Heading/Heading'
import { Text } from 'components/Text/Text'
import { withResult } from 'hoc/withAsyncResult'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import {
  listCampaignEntriesService,
  listCampaignsService
} from 'services/campaigns'

export const ViewCampaignEntriesView = withResult(
  ({ route }: RouteProps<'ViewCampaignEntries'>) => {
    const listCampaignEntries = useService(listCampaignEntriesService)
    const listUserCampaigns = useService(listCampaignsService)
    const campaignId = Number(route.params.id)

    return async () => {
      const [entriesRes, campaignsRes] = await Promise.all([
        listCampaignEntries({ campaignId }),
        listUserCampaigns()
      ])

      const { campaigns } = campaignsRes.data
      const campaign = campaignsRes.data.campaigns.find(
        (c) => c.id === campaignId
      )

      if (!campaign) {
        throw new Error('Couldn’t find campaign')
      }

      return {
        campaign,
        campaigns,
        entries: entriesRes.data.entries
      }
    }
  },
  ({ campaign, campaigns, entries, reload, isReloading }) => (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Title>{campaign.title}</Title>
      </DefaultLayout.Header>

      {entries.length ? (
        <EntriesList
          entries={entries}
          campaigns={campaigns}
          reload={reload}
          isLoading={isReloading}
        />
      ) : (
        <DefaultLayout.Content>
          <Text center>
            There’s nothing here yet.
            {'\n\n'}
            Add creations to this campaign from "your creations" screens
          </Text>
        </DefaultLayout.Content>
      )}
    </DefaultLayout>
  )
)
