import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber'
import { PhoneNumber as ContactPhoneNumber } from 'expo-contacts'
import { Contact } from 'expo-contacts'

export type NumberItem = {
  phoneNumber: ContactPhoneNumber
  contact: Contact
  parsed: ParsedPhoneNumber
  normalised: string
  display: string
}

export const getMobileNumbersFromContacts = (
  contacts: Contact[]
): NumberItem[] => {
  const out: NumberItem[] = []

  for (const contact of contacts) {
    if (contact.lastName === 'Pileri') {
    }
    for (const phoneNumber of contact.phoneNumbers || []) {
      const number = phoneNumber.number?.trim().replace(/^00/, '+')
      const countryCode = !(
        phoneNumber.number?.startsWith('+') ||
        number?.startsWith('00') ||
        number?.startsWith('(')
      )
        ? phoneNumber.countryCode?.toUpperCase()
        : undefined

      const parsed =
        number && parsePhoneNumber(number, { regionCode: countryCode })

      if (!parsed || !parsed.typeIsMobile || !parsed.number?.e164) {
        continue
      }

      const current: NumberItem = {
        phoneNumber,
        contact,
        parsed,
        normalised: parsed.number.e164,
        display: parsed.number.international
      }

      const hasDuplicate = !!out.find(
        (p) => p.normalised === current.normalised
      )

      !hasDuplicate && out.push(current)
    }
  }

  return out
}
