import { BASE_UI_IMAGES_URL } from 'constants/ui'
import {
  GenerationChoiceValue,
  GenerationOption,
  RandomValue
} from 'types/generation'
import { getOne } from './array'
import { notNil } from './object'

const DISPLAYED_SUMMARY_CHOICES = [
  'character-specie',
  'character-class',
  'character-alignment',

  'location-setting',
  'location-environment',
  'location-time',

  'quest-task',
  'quest-difficulty'
]

const DISPLAYED_SUMMARY_CHOICES_OVERRIDES: Record<string, string[]> = {
  'character-race': ['character-lineage']
}

export const getDisplayedChoicesRows = (
  choices: Record<string, GenerationOption>
) =>
  Object.keys(choices)
    .map((step) => {
      if (!DISPLAYED_SUMMARY_CHOICES.includes(step)) {
        return null
      }

      const value = choices[step] || null

      if (!value) {
        return null
      }

      const overrideSteps = DISPLAYED_SUMMARY_CHOICES_OVERRIDES[step] || []

      for (const overrideStep of overrideSteps) {
        const overrideValue = choices[overrideStep]

        if (overrideValue) {
          return overrideValue
        }
      }

      return value
    })
    .filter(notNil)

type ImageSize = '140' | '200'

type ImageExt = 'png' | 'jpeg'

export const isRandom = (value: any): value is RandomValue =>
  typeof value === 'object' && value?.random === true

export const getChoiceImagePath = (
  step: string,
  value: GenerationChoiceValue,
  displayRandom = false,
  displayNone = true
) => {
  if (displayRandom && isRandom(value)) {
    return 'generation-options/misc/random'
  }

  if (displayNone && !value) {
    return 'generation-options/misc/none'
  }

  const key = typeof value === 'string' ? value : 'default'

  return `generation-options/${step}/${
    typeof key === 'string' ? key : 'default'
  }`
}

export const getChoiceImageUrl = (
  step: string,
  value: GenerationChoiceValue | GenerationChoiceValue[],
  size: ImageSize | null = null,
  ext: ImageExt = 'png',
  displayMeta = false
) =>
  [
    BASE_UI_IMAGES_URL,
    `${getChoiceImagePath(step, getOne(value) || 'default', displayMeta)}${
      size ? `_${size}` : ''
    }.${ext}`
  ].join('/')
