import { FunctionComponent } from 'react'
import { Image, ImageProps, Platform } from 'react-native'

const image = Platform.select({
  default: require('./assets/logo-80.png'),
  web: require('./assets/logo.svg')
})

export enum LogoSize {
  Small = 40,
  Medium = 80
}

export type LogoProps = ImageProps & { size?: LogoSize }

export const Logo: FunctionComponent<LogoProps> = ({
  size = LogoSize.Medium,
  ...props
}) => (
  <Image
    {...props}
    source={image}
    style={[{ width: size, height: size }, props.style]}
  />
)
