import { themed } from 'hooks/useTheme'
import {
  Platform,
  PressableAndroidRippleConfig,
  TextStyle,
  ViewStyle
} from 'react-native'
import { Theme } from 'types/theme'
import { ButtonProps, ButtonSize, ButtonType } from './Button'
import color from 'tinycolor2'
import { SpinnerProps } from 'components/Spinner/Spinner'

interface Styles {
  container: ViewStyle
  inner: ViewStyle
  text: TextStyle
  icon: ViewStyle
  iconText: TextStyle
  iconSpacer: ViewStyle
  ripple: PressableAndroidRippleConfig
  spinnerContainer: ViewStyle
  spinnerProps: SpinnerProps
}

const SIZE_OPTIONS: Record<
  ButtonSize,
  {
    padding: [number, number]
    fontSize: number
    iconSpace: number
    iconFontSize: number
  }
> = {
  medium: { padding: [30, 20], fontSize: 18, iconSpace: 30, iconFontSize: 14 },
  small: { padding: [20, 15], fontSize: 15, iconSpace: 20, iconFontSize: 12 },
  tiny: { padding: [15, 7], fontSize: 12, iconSpace: 15, iconFontSize: 12 }
}

const COLOR_OPTIONS: Record<ButtonType, { colorKey: keyof Theme['palette'] }> =
  {
    secondary: { colorKey: 'contrast' },
    primary: { colorKey: 'accent' },
    bright: { colorKey: 'mid' },
    danger: { colorKey: 'error' }
  }

export const { use: useStyles } = themed<
  Styles,
  ButtonProps & { pressed?: boolean }
>(
  (
    { palette, misc },
    {
      size = 'medium',
      outline = false,
      type = 'secondary',
      disabled,
      subtle,
      pressed,
      loading,
      inline
    }
  ): Styles => {
    const { fontSize, padding, iconSpace, iconFontSize } = SIZE_OPTIONS[size]
    const { colorKey } = COLOR_OPTIONS[type]
    const baseOpacity = subtle ? 0.1 : 1
    const bgColor = color(palette[colorKey])
      .desaturate(disabled ? 0.8 : 0)
      .setAlpha(disabled ? baseOpacity / 2 : baseOpacity)
      .lighten(Platform.OS === 'ios' && pressed ? 10 : 0)
      .toRgbString()

    const textColor = color(outline || subtle ? bgColor : palette.background)
      .setAlpha(disabled ? 0.5 : 1)
      .toRgbString()

    return {
      container: {
        alignSelf: 'stretch',
        borderRadius: misc.radius,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: padding[1],
        paddingHorizontal: padding[0],
        overflow: 'hidden',

        ...(outline
          ? {
              borderColor: bgColor,
              borderWidth: 2,
              backgroundColor: color(palette.background)
                .setAlpha(0.2)
                .toRgbString(),
              ...Platform.select({
                default: {},
                web: { backdropFilter: 'blur(30px)' }
              })
            }
          : { backgroundColor: bgColor })
      },

      inner: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        alignItems: 'center'
      },

      text: {
        fontSize,
        color: textColor,
        flexGrow: 1,
        textAlign: 'center',
        marginHorizontal: inline ? 10 : undefined
      },

      icon: { flexDirection: 'row', width: iconSpace },

      iconText: {
        color: textColor,
        textAlign: 'center',
        fontSize: iconFontSize
      },

      iconSpacer: { width: iconSpace, backgroundColor: 'red' },

      ripple: {
        ...(outline
          ? { color: color(bgColor).setAlpha(0.2).toRgbString() }
          : { color: palette.white })
      },

      spinnerContainer: {
        opacity: loading ? 1 : 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },

      spinnerProps: { color: outline ? bgColor : textColor }
    }
  }
)
