import { Text } from 'components/Text/Text'
import { TextInput } from 'components/TextInput/TextInput'
import { FunctionComponent, useState } from 'react'
import { TEXT_DISPLAY_OPTIONS } from './TextBitDisplay.constants'
import { useStyles } from './TextBitsDisplay.styles'

export interface TextBitDisplayProps {
  value: string
  isEditMode?: boolean
  onChange?: (value: string) => void
  type?: string
}

export const TextBitDisplay: FunctionComponent<TextBitDisplayProps> = ({
  value,
  type,
  onChange,
  isEditMode
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const { multiline, style, renderDisplay } =
    (type && TEXT_DISPLAY_OPTIONS[type]) || {}
  const wordCount = value.split(' ').length
  const styles = useStyles({ longish: wordCount > 3 })

  const displayText = renderDisplay?.(value) || value
  const textStyle = style && styles[style]

  if (!isEditMode) {
    return <Text style={textStyle}>{displayText}</Text>
  }

  return (
    <TextInput
      containerStyle={isFocused ? styles.focus : styles.blur}
      style={textStyle}
      multiline={multiline}
      value={value}
      onChangeText={onChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  )
}
