import { ItemsListView } from 'components/ItemsList/ItemsList'
import { OptionsTray } from 'components/OptionsTray/OptionsTray'
import { PURE_CHOICES } from 'constants/features'
import { FunctionComponent, useState } from 'react'
import {
  GenerationChoiceValue,
  GeneratorTreeStepOptions
} from 'types/generation'
import { getChoiceImageUrl, isRandom } from 'utils/choices'
import { WizardState } from '../GenerateWizardView.types'
import { ChoiceButton } from './ChoiceButton/ChoiceButton'

interface ChoicesMenuProps {
  wizardState: WizardState
}

export const ChoicesMenu: FunctionComponent<ChoicesMenuProps> = ({
  wizardState
}) => {
  const { tree, chooseOption, chooseRandom, choices } = wizardState
  const [showTray, setShowTray] = useState(false)
  const [openStep, setOpenStep] = useState<string | null>(null)
  const options = openStep ? wizardState.getStepChoices(openStep) : []
  const getImageUrl = (choice: GenerationChoiceValue) =>
    openStep
      ? getChoiceImageUrl(openStep, choice, '200', 'jpeg', true)
      : undefined
  const openStepOptions: Partial<GeneratorTreeStepOptions> =
    (openStep && tree.steps[openStep]) || {}

  return (
    <>
      <ItemsListView stretch={false}>
        {Object.keys(tree.steps).map((step) => {
          const value = choices[step] || null
          const { optional, showImages } = tree.steps[step] || {}
          const stepChoices = wizardState.getStepChoices(step)
          const hasRealChoices = !!stepChoices.find(
            ({ value }) => !!value && !isRandom(value)
          )

          return !PURE_CHOICES || hasRealChoices ? (
            <ChoiceButton
              key={step}
              step={step}
              showImages={showImages}
              wizardState={wizardState}
              onPress={() => {
                setOpenStep(step)
                setShowTray(!showTray)
              }}
              onPressEmpty={
                (!!value && optional && (() => chooseOption(step, null))) ||
                undefined
              }
              onPressRandom={
                (!isRandom(value) && (() => chooseRandom(step))) || undefined
              }
            />
          ) : null
        })}
      </ItemsListView>

      <OptionsTray<GenerationChoiceValue>
        onClose={() => setShowTray(false)}
        multiple={openStepOptions.multiple}
        show={showTray}
        showImages={openStepOptions.showImages}
        value={(openStep && choices[openStep]) || undefined}
        onChange={(choice) => openStep && chooseOption(openStep, choice)}
        options={[
          ...(openStep
            ? options.map((option) => ({
                label:
                  (!option.value ||
                    isRandom(option.value) ||
                    openStepOptions?.showLabels) ??
                  true
                    ? option.label || null
                    : null,
                imageSource: openStepOptions.showImages
                  ? { uri: getImageUrl(option.value) }
                  : undefined,
                value: option.value
              }))
            : [])
        ]}
      />
    </>
  )
}
