import { TinyEmitter } from 'tiny-emitter'

type AppEvents = {
  on: (e: AppEvent, callback: Function) => AppEvents
  off: (e: AppEvent, callback: Function) => AppEvents
  emit: (e: AppEvent) => AppEvents
}

let appEvents: AppEvents | undefined

export enum AppEvent {
  ReloadAuth = 'reload-auth'
}

export const getAppEvents = () =>
  appEvents || (appEvents = new TinyEmitter() as AppEvents)
