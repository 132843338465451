import { useRef } from 'react'

export const useDeferred = <T extends (...args: any[]) => any>(
  inputFn: T
): T => {
  const updated = useRef<T>(inputFn)

  updated.current = inputFn

  const deferred = ((...args: Parameters<T>) =>
    updated.current && updated.current(...args)) as T

  return deferred
}
