import { FunctionComponent, useEffect } from 'react'
import { StatusBar } from 'expo-status-bar'
import { AppNavigation } from 'components/AppNavigation/AppNavigation'
import { compareVersions } from 'compare-versions'
import { FontsProvider } from './FontsProvider'
import { ThemeProvider } from 'components/ThemeProvider/ThemeProvider'
import { AppStorageProvider } from 'components/AppStorageProvider/AppStorageProvider'
import { useAuth } from 'hooks/useAuth'
import { useNetInfo } from '@react-native-community/netinfo'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { PortalHost, PortalProvider } from '@gorhom/portal'
import { ToasterProvider } from 'context/toaster'
import { ToasterOverlay } from 'components/Toasters/ToasterOverlay'
import { ModalResultProvider } from 'context/modal-result'
import { AppEvent, getAppEvents } from 'core/events'
import { useService } from 'hooks/useService'
import { getAppStatusService } from 'services/misc'
import { useAsync } from 'hooks/useAsync'
import { LoadingView } from 'view/LoadingView/LoadingView'
import { ErrorView } from 'view/ErrorView/ErrorView'
import { version as appVersion } from '../../../package.json'
import { UpgradeRequiredView } from 'view/UpgradeRequiredView/UpgradeRequiredView'
import { AppSuspendedView } from 'view/AppSuspendedView/AppSuspendedView'
import { Alert } from 'react-native'
import { useTheme } from 'hooks/useTheme'
import { Platform } from 'expo-modules-core'

const AppRoot: FunctionComponent = () => {
  const { reloadAuth, token } = useAuth()
  const { isConnected, isInternetReachable } = useNetInfo()

  useEffect(() => {
    token && isConnected && isInternetReachable && reloadAuth()
  }, [isConnected, token])

  useEffect(() => {
    getAppEvents().on(AppEvent.ReloadAuth, reloadAuth)

    return () => {
      getAppEvents().off(AppEvent.ReloadAuth, reloadAuth)
    }
  }, [])

  return <AppNavigation />
}

export const withProviders =
  (Root: FunctionComponent): FunctionComponent =>
  () =>
    (
      <SafeAreaProvider>
        <ModalResultProvider>
          <FontsProvider>
            <AppStorageProvider>
              <ThemeProvider>
                <StatusBar style="auto" />
                <ToasterProvider>
                  <PortalProvider>
                    <Root />
                    <PortalHost name="foreground" />
                    <PortalHost name="root" />
                    <ToasterOverlay />
                  </PortalProvider>
                </ToasterProvider>
              </ThemeProvider>
            </AppStorageProvider>
          </FontsProvider>
        </ModalResultProvider>
      </SafeAreaProvider>
    )

export const withStatus = (Root: FunctionComponent) => () => {
  const getAppStatus = useService(getAppStatusService)
  const {
    isLoading,
    error,
    result: appStatus
  } = useAsync(async () => (await getAppStatus()).data, {
    initRun: true,
    initWith: []
  })
  const theme = useTheme()

  useEffect(() => {
    if (appStatus?.startupAlert) {
      const { title, text, dismissText } = appStatus?.startupAlert

      appStatus?.startupAlert &&
        Alert.alert(title, text, [{ text: dismissText }], {
          cancelable: true,
          userInterfaceStyle: theme.misc.dark ? 'dark' : undefined
        })
    }
  }, [appStatus?.startupAlert])

  if (isLoading || !appStatus) {
    return <LoadingView />
  }

  if (error) {
    return <ErrorView error={error} />
  }

  if (
    Platform.OS !== 'web' &&
    appStatus.appMinVersion &&
    compareVersions(appVersion, appStatus.appMinVersion) === -1
  ) {
    return (
      <UpgradeRequiredView screenOptions={appStatus.upgradeScreenOptions} />
    )
  }

  if (appStatus.currentStatus === 'suspended') {
    return <AppSuspendedView screenOptions={appStatus.suspendedScreenOptions} />
  }

  return <Root />
}

export default withProviders(withStatus(AppRoot))
