import { Subtitle, Title } from 'components/Heading/Heading'
import { ItemsList, ItemsListItem } from 'components/ItemsList/ItemsList'
import { Text } from 'components/Text/Text'
import { withResult } from 'hoc/withAsyncResult'
import { useNavigation } from 'hooks/useNavigation'
import { useService } from 'hooks/useService'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import { loadGenerationTypesService } from 'services/generation'
import { getEntryTypeImageUrl } from 'utils/entry-type'

export const NewEntryView: FunctionComponent = withResult(
  () => {
    const loadTypes = useService(loadGenerationTypesService)

    return async () => (await loadTypes()).data
  },
  ({ types }) => {
    const { push } = useNavigation()

    return (
      <DefaultLayout>
        <DefaultLayout.Header>
          <Title>New creation</Title>
          <Subtitle>Select a category</Subtitle>
        </DefaultLayout.Header>

        <ItemsList
          stretch={false}
          data={Object.keys(types) as Array<keyof typeof types>}
          renderItem={({ item: type }) => {
            const { name, description } = types[type]!

            return (
              <ItemsListItem
                imageSource={{ uri: getEntryTypeImageUrl(type, '140') }}
                onPress={() => push('NewEntryByType', { type })}
              >
                <Text bold>{name}</Text>
                <Text light lightColor>
                  {description}
                </Text>
              </ItemsListItem>
            )
          }}
        />
      </DefaultLayout>
    )
  }
)
