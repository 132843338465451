import { themed } from 'hooks/useTheme'
import type { TextStyle } from 'react-native'

interface Styles {
  displayName: TextStyle
  choiceLabels: TextStyle
}

export const { use: useStyles } = themed<Styles>(
  ({ fonts }): Styles => ({
    displayName: { fontSize: 16, marginBottom: 5, fontFamily: fonts.bold },

    choiceLabels: { fontSize: 14, marginBottom: 5 }
  })
)
