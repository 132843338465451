import { AppNavigationRoutes } from 'components/AppNavigation/AppNavigation.types'
import { Icon } from 'components/Icon/Icon'
import { IconType } from 'components/Icon/Icon.types'
import { FunctionComponent } from 'react'
import { StyleProp, TextStyle } from 'react-native'

export const NAV_ICONS: Partial<Record<keyof AppNavigationRoutes, IconType>> = {
  Main: IconType.List,
  Account: IconType.User,
  NewEntry: IconType.Plus,
  Campaigns: IconType.Book,
  Credits: IconType.Credit
}

export interface NavigationIconProps {
  screenName: keyof AppNavigationRoutes
  style?: StyleProp<TextStyle>
}

export const NavigationIcon: FunctionComponent<NavigationIconProps> = ({
  screenName,
  style
}) => {
  const icon = NAV_ICONS[screenName]

  return icon ? <Icon type={icon} style={style} /> : null
}
