import { Text } from 'components/Text/Text'
import { FunctionComponent, useEffect, useRef } from 'react'
import { Animated, Platform, TextInput } from 'react-native'
import { useStyles } from './CodeInput.styles'

export type CodeInputProps = {
  value: string
  onChange: (value: string) => void
  onComplete?: (value: string) => void
  length: number
}

const DIGITS_ONLY_REGEX = /^[0-9]*$/

export const CodeInput: FunctionComponent<CodeInputProps> = ({
  length,
  value,
  onChange,
  onComplete
}) => {
  const styles = useStyles()
  const ref = useRef<TextInput>(null)
  const isCompleted = value.length >= length
  const completedValue = useRef(new Animated.Value(0)).current

  useEffect(() => {
    value === '' && ref.current?.focus()
  }, [value])

  useEffect(() => {
    if (isCompleted) {
      ref.current?.blur()
      onComplete?.(value)
    }

    Animated.timing(completedValue, {
      toValue: +isCompleted,
      duration: 500,
      useNativeDriver: true
    }).start()
  }, [isCompleted])

  const opacity = completedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.3]
  })

  return (
    <Animated.View style={[styles.container, { opacity }]}>
      <TextInput
        ref={ref}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        onBlur={() => Platform.OS === 'web' && ref.current?.focus()}
        autoFocus
        value={value}
        style={styles.input}
        onChangeText={(val) =>
          !isCompleted && DIGITS_ONLY_REGEX.test(val) && onChange(val)
        }
      />
      {Array(length)
        .fill(null)
        .map((_, i) => (
          <Text
            style={[styles.cell, i === value.length && styles.focusCell]}
            key={i}
          >
            {isCompleted ? '•' : value[i] || ''}
          </Text>
        ))}
    </Animated.View>
  )
}
