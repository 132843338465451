import { RouteProp } from '@react-navigation/native'
import { BottomTabBar } from '@react-navigation/bottom-tabs'
import { Text } from 'components/Text/Text'
import { useTheme } from 'hooks/useTheme'
import { FunctionComponent } from 'react'
import { Platform, View } from 'react-native'
import { AppNavigationRoutes } from './AppNavigation.types'
import color from 'tinycolor2'
import { useMedia } from 'hooks/useMedia'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { HeaderNavigation } from './HeaderNavigation/HeaderNavigation'
import { NavigationIcon } from 'components/NavigationIcon/NavigationIcon'
import { useAuth } from 'hooks/useAuth'

interface Props {
  route: RouteProp<AppNavigationRoutes, keyof AppNavigationRoutes>
  focused?: boolean
}

export const TabsBackground: FunctionComponent = () => {
  const backgroundColor = color(useTheme().palette.background)
    .darken(5)
    .toRgbString()

  return <View style={{ backgroundColor, flex: 1 }} />
}

export const TabIcon: FunctionComponent<Props> = ({ route, focused }) => {
  const { palette } = useTheme()
  const { user } = useAuth()
  const credits = user?.credits || 0
  const icon = (
    <NavigationIcon
      screenName={route.name}
      style={{ color: focused ? palette.accent : palette.mid, fontSize: 20 }}
    />
  )

  if (user && route.name === 'Credits') {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {icon}
        <Text
          bold
          style={{
            color: focused ? palette.accent : palette.mid,
            fontSize: 14,
            marginLeft: Platform.select({ web: 8, default: 3 }),
            marginTop: Platform.select({ web: -20, default: -2 })
          }}
        >
          {' '}
          {credits}
        </Text>
      </View>
    )
  }

  return icon
}

export const TabLabel: FunctionComponent<Props> = ({ route, focused }) => {
  const { palette } = useTheme()

  return (
    <Text
      style={{
        color: focused ? palette.accent : palette.mid,
        fontSize: 12,
        display: 'none'
      }}
    >
      {route.name}
    </Text>
  )
}

export const TabBar: FunctionComponent<BottomTabBarProps> = (props) =>
  useMedia().isDesktop && Platform.OS === 'web' ? (
    <HeaderNavigation {...props} />
  ) : (
    <BottomTabBar {...props} />
  )
