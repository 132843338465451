import { ReactNode } from 'react'
import { useStyles } from './TextBitsDisplay.styles'

export const TEXT_DISPLAY_OPTIONS: Record<
  string,
  {
    multiline?: boolean
    style?: keyof ReturnType<typeof useStyles>
    renderDisplay?: (value: string) => ReactNode
  }
> = {
  quote: {
    multiline: false,
    style: 'largeQuote',
    renderDisplay: (value) => <>&ldquo;{value}&rdquo;</>
  },
  headline: {
    multiline: false,
    style: 'largeQuote',
    renderDisplay: (value) => value
  },
  background: { multiline: true, style: 'long' },
  displayName: { style: 'title' }
}
