import { DropDownButton, DropDownProps } from 'components/DropDown/DropDown'
import { FadingImageView } from 'components/FadingImage/FadingImage'
import { Icon } from 'components/Icon/Icon'
import { IconType } from 'components/Icon/Icon.types'
import { IconButton, IconButtonProps } from 'components/IconButton/IconButton'
import { MaybeBlurView } from 'components/MaybeBlurView/MaybeBlurView'
import { MaybePressable } from 'components/MaybePressable/MaybePressable'
import { RefreshControl } from 'components/RefreshControl/RefreshControl'
import { Text } from 'components/Text/Text'
import { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import {
  FlatList,
  FlatListProps,
  ImageProps,
  ImageSourcePropType,
  Platform,
  SectionList,
  SectionListData as BaseSectionListData,
  SectionListProps,
  StyleProp,
  View,
  ViewProps
} from 'react-native'
import { TextStyle, ViewStyle } from 'react-native-phone-input'
import { useStyles } from './ItemsList.style'

interface ExtraProps {
  stretch?: boolean
}

interface ExtraListProps {
  onRefresh?: () => void
  isLoading?: boolean
}

export function ItemsList<T>({
  stretch,
  isLoading = false,
  onRefresh,
  ...props
}: FlatListProps<T> & ExtraProps & ExtraListProps): ReactElement {
  const styles = useStyles({ stretch })

  return (
    <FlatList
      {...props}
      style={styles.list}
      refreshControl={
        onRefresh && (
          <RefreshControl refreshing={isLoading} onRefresh={onRefresh} />
        )
      }
    />
  )
}

interface SectionData {
  title: string
  icon?: IconType
}

export function SectionItemsList<T>({
  stretch,
  isLoading = false,
  onRefresh,
  renderSectionHeader,
  ...props
}: SectionListProps<T, SectionData> &
  ExtraProps &
  ExtraListProps): ReactElement {
  const styles = useStyles({ stretch })

  return (
    <SectionList<T, SectionData>
      {...props}
      renderSectionHeader={
        renderSectionHeader ||
        (({ section }) => (
          <SectionItemsListHeader section={{ ...section }} stretch={stretch} />
        ))
      }
      style={styles.list}
      refreshControl={
        onRefresh && (
          <RefreshControl refreshing={isLoading} onRefresh={onRefresh} />
        )
      }
    />
  )
}

export type SectionListData<T> = BaseSectionListData<T, SectionData>

export const SectionItemsListHeader: FunctionComponent<
  {
    section: SectionListData<any>
  } & ExtraProps
> = ({ section: { icon, title }, stretch }) => {
  const styles = useStyles({ stretch })

  return (
    <View style={styles.sectionHeader}>
      {icon && <Icon type={icon} style={styles.sectionHeaderIcon} />}
      <Text style={styles.sectionHeaderTitle}>{title}</Text>
    </View>
  )
}

export const ItemsListView: FunctionComponent<ViewProps & ExtraProps> = ({
  stretch,
  style,
  ...props
}) => {
  const styles = useStyles({ stretch })

  return <View style={[styles.list, style]} {...props} />
}

type ItemsListItemProps = PropsWithChildren<{
  onPress?: () => void
  imageSource?: ImageSourcePropType
  placeholderImage?: ImageSourcePropType
  imageProps?: Omit<ImageProps, 'source'>
  inlineActions?: IconButtonProps[]
  icon?: IconType
  iconStyle?: TextStyle
  style?: StyleProp<ViewStyle>
  dropDown?: DropDownProps
}>

export const ItemsListItem: FunctionComponent<ItemsListItemProps> = ({
  imageSource,
  placeholderImage,
  children,
  inlineActions,
  icon,
  iconStyle,
  onPress,
  imageProps = {},
  dropDown
}) => {
  const styles = useStyles()

  return (
    <MaybePressable style={styles.container} onPress={onPress}>
      <MaybeBlurView
        style={styles.inner}
        intensity={20}
        tint="dark"
        blur={Platform.OS === 'ios'}
      >
        {(imageSource || placeholderImage) && (
          <FadingImageView
            source={imageSource || placeholderImage}
            {...imageProps}
            style={[styles.image, imageProps.style]}
            containerProps={{
              style: [
                styles.imageContainer,
                (!imageSource && styles.placeholderImage) || undefined
              ]
            }}
          />
        )}

        {icon && <Icon type={icon} style={[styles.itemIcon, iconStyle]} />}

        <View style={styles.content}>{children}</View>

        <View style={styles.inlineActions}>
          {inlineActions?.map((props, i) => (
            <IconButton
              key={i}
              {...props}
              style={[styles.iconButton, props.style]}
            />
          ))}

          {dropDown && <DropDownButton {...dropDown} />}
        </View>
      </MaybeBlurView>
    </MaybePressable>
  )
}
