import { useKeyboard as _useKeyboard } from '@react-native-community/hooks'
import { useEffect, useRef, useState } from 'react'
import { EmitterSubscription, Keyboard, Platform } from 'react-native'

export const useKeyboard = () => {
  const keyboard = _useKeyboard()

  if (Platform.OS === 'android') {
    return { ...keyboard, keyboardWillBeShown: keyboard.keyboardShown }
  }

  const [keyboardWillBeShown, setKeyboardWillBeShown] = useState(false)
  const keyboardHeight = useRef(0)

  const showListener = useRef<EmitterSubscription>()
  const hideListener = useRef<EmitterSubscription>()

  useEffect(() => {
    showListener.current = Keyboard.addListener('keyboardWillShow', (e) => {
      keyboardHeight.current = e.endCoordinates.height
      setKeyboardWillBeShown(true)
    })

    hideListener.current = Keyboard.addListener('keyboardWillHide', () =>
      setKeyboardWillBeShown(false)
    )

    return () => {
      showListener.current?.remove()
      hideListener.current?.remove()
    }
  }, [])

  return {
    ...keyboard,
    keyboardWillBeShown,
    keyboardHeight: keyboardHeight.current
  }
}
