import { useKeyboard } from 'hooks/useKeyboard'
import { FunctionComponent } from 'react'
import { Platform, View, ViewProps } from 'react-native'

type KeyboardPaddedViewProps = ViewProps & {
  iosOnly?: boolean
}

export const KeyboardPaddedView: FunctionComponent<KeyboardPaddedViewProps> = ({
  style,
  iosOnly = true,
  ...props
}) => {
  const { keyboardHeight } = useKeyboard()
  const shouldPad = iosOnly ? Platform.OS === 'ios' : true

  return (
    <View
      style={shouldPad ? [{ paddingBottom: keyboardHeight }, style] : style}
      {...props}
    />
  )
}
