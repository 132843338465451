import { RouteProps } from 'components/AppNavigation/AppNavigation.types'
import { Button } from 'components/Button/Button'
import { FooterFormWrap } from 'components/FooterFormWrap/FooterFormWrap'
import { Subtitle, Title } from 'components/Heading/Heading'
import { IconType } from 'components/Icon/Icon.types'
import { Row } from 'components/Row/Row'
import { Spacer } from 'components/Spacer/Spacer'
import { Text } from 'components/Text/Text'
import { useModalResult } from 'context/modal-result'
import { useNavigation } from 'hooks/useNavigation'
import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent, useState } from 'react'
import { Platform } from 'react-native'
import { SendCampaignInviteForm } from './SendCampaignInviteView.form'

export const SendCampaignInviteView: FunctionComponent<
  RouteProps<'SendCampaignInvite'>
> = ({
  route: {
    params: { id: campaignId }
  }
}) => {
  const { push } = useNavigation()
  const { expectResult } = useModalResult()
  const [prefillNumber, setPrefillNumber] = useState('')
  const openContactPicker = async () => {
    const { result, resultId } = expectResult()

    push('ContactPicker', { resultId })

    const mobileNumber = await result

    typeof mobileNumber === 'string' &&
      !!mobileNumber &&
      setPrefillNumber(mobileNumber)
  }

  return (
    <DefaultLayout>
      <DefaultLayout.Header>
        <Title>New invite</Title>
        <Subtitle>Invite a party member</Subtitle>
        <Spacer size={4} />

        <Text>
          Enter a mobile number to invite a new party member to this campaign
          {'\n\n'}
          They will be able to see the creations you share in the campaign.
          {'\n\n'}
          If they are not already on the app, they will receive an invite to
          join be added automatically.
        </Text>

        {Platform.OS !== 'web' && (
          <>
            <Spacer size={4} />

            <Row>
              <Button
                type="secondary"
                outline
                size="small"
                inline
                onPress={openContactPicker}
                iconLeft={IconType.Magnifier}
              >
                Search from contacts
              </Button>
            </Row>
          </>
        )}
      </DefaultLayout.Header>

      <DefaultLayout.Content />

      <FooterFormWrap>
        <DefaultLayout.Footer>
          <SendCampaignInviteForm
            campaignId={campaignId}
            prefillNumber={prefillNumber}
          />
        </DefaultLayout.Footer>
      </FooterFormWrap>
    </DefaultLayout>
  )
}
