import { themed } from 'hooks/useTheme'
import { ViewStyle } from 'react-native'

interface Styles {
  outer: ViewStyle
  container: ViewStyle
  button: ViewStyle
  inner: ViewStyle
}

export const { use: useStyles } = themed<Styles>(({ colors }): Styles => {
  return {
    outer: {
      position: 'absolute',
      bottom: 0,
      width: '100%'
    },

    container: { backgroundColor: colors.overlay },

    inner: {
      paddingVertical: 15,
      paddingHorizontal: 10
    },

    button: { flex: 1, marginHorizontal: 10 }
  }
})
