import { createContext } from 'react'
import { AuthState } from 'types/auth'

export const INITIAL_AUTH_STATE: AuthState = {
  token: null,
  user: null,
  pricing: null,
  isSignedIn: false
}

export interface AppStorageData {
  version: null | number
  auth: AuthState
}

export type AppStorageState = {
  data: AppStorageData
  isInitialised: boolean
  isLoading: boolean
  error: Error | null
  setValue: (value: Partial<AppStorageData>) => Promise<void>
}

export const INITIAL_APP_STORAGE_STATE: AppStorageState = {
  data: { version: null, auth: INITIAL_AUTH_STATE },
  isInitialised: false,
  isLoading: true,
  error: null,
  setValue: Promise.resolve
}

export const AppStorageContext = createContext<AppStorageState>(
  INITIAL_APP_STORAGE_STATE
)
