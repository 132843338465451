import { Button } from 'components/Button/Button'
import { Field } from 'components/Field/Field'
import { Spacer } from 'components/Spacer/Spacer'
import { useField, useForm } from 'hooks/useForm'
import { FunctionComponent } from 'react'
import * as v from 'utils/validation'
import { useAsync } from 'hooks/useAsync'
import { ErrorDisplay } from 'components/ErrorDisplay/ErrorDisplay'
import { useNavigation } from 'hooks/useNavigation'
import { IconType } from 'components/Icon/Icon.types'
import { TextInput } from 'components/TextInput/TextInput'
import { useService } from 'hooks/useService'
import { createCampaignsService } from 'services/campaigns'

export const CreateCampaignForm: FunctionComponent = () => {
  const { pop, navigate, push } = useNavigation()

  const title = useField<string>('', 'title', [
    v.required(),
    v.minLength(3),
    v.maxLength(50)
  ])

  const createCampaign = useService(createCampaignsService)

  const create = useAsync<void, []>(async () => {
    const campaign = (await createCampaign(values)).data.campaign
    pop()
    navigate('CampaignInvites', { id: campaign.id })
    push('SendCampaignInvite', { id: campaign.id })
  })

  const { submit, isValid, values } = useForm({ title }, create)

  return (
    <>
      <Field field={title} label="Display name">
        <TextInput {...title.props.textInput} />
      </Field>

      <Spacer />

      <ErrorDisplay error={create.error} />

      <Button
        type="primary"
        onPress={submit}
        iconRight={IconType.CaretRight}
        loading={create.isLoading}
        disabled={!isValid}
      >
        Continue
      </Button>
    </>
  )
}
