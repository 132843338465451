import { Button, ButtonProps } from 'components/Button/Button'
import { ComponentWithAction } from 'components/ComponentWithAction/ComponentWithAction'
import { FadingView } from 'components/FadingView/FadingView'
import { FooterOverlay } from 'components/FooterOverlay/FooterOverlay'
import { Row } from 'components/Row/Row'
import { FunctionComponent } from 'react'
import { useStyles } from './FooterActions.styles'

interface FooterActionsProps {
  show?: boolean
  buttons: Array<Omit<ButtonProps, 'children'> & { text: string }>
}

export const FooterActions: FunctionComponent<FooterActionsProps> = ({
  show,
  buttons
}) => {
  const styles = useStyles()

  return (
    <FadingView show={show} style={styles.outer} unmount>
      <FooterOverlay style={styles.container}>
        <Row style={styles.inner}>
          {buttons.map(({ text, onPress, ...buttonProps }, i) => (
            <ComponentWithAction fn={onPress} key={i}>
              {({ start, isLoading }) => (
                <Button
                  type="bright"
                  outline
                  loading={isLoading}
                  size="tiny"
                  onPress={start}
                  {...buttonProps}
                  style={styles.button}
                >
                  {text}
                </Button>
              )}
            </ComponentWithAction>
          ))}
        </Row>
      </FooterOverlay>
    </FadingView>
  )
}
