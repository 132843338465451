import { themed } from 'hooks/useTheme'
import { Platform, TextStyle, ViewStyle } from 'react-native'
import color from 'tinycolor2'

interface Styles {
  label: TextStyle
  error: ViewStyle
  errorContainer: ViewStyle
  errorText: TextStyle
}

export const { use: useStyles } = themed<Styles>(({ palette, misc }) => ({
  label: {
    alignSelf: 'stretch',
    marginBottom: 10,
    color: palette.mid,

    display: Platform.select({ web: 'inline-block' as ViewStyle['display'] })
  },

  errorContainer: {
    position: 'relative',
    alignSelf: 'stretch',
    marginHorizontal: 14,
    zIndex: 1
  },

  error: {
    position: 'absolute',
    top: -14,
    alignSelf: 'flex-end',
    backgroundColor: palette.error,
    paddingTop: 4,
    paddingBottom: 6,
    paddingHorizontal: 20,
    marginHorizontal: 10,
    borderRadius: misc.radius
  },

  errorText: {
    fontSize: 12,
    color: color(palette.error).lighten(50).toRgbString()
  }
}))
