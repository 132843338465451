import { DropDown } from 'components/DropDown/DropDown'
import { useWrappedRef } from 'hooks/useWrappedRef'
import { forwardRef, RefObject, useRef, useState } from 'react'
import ReactNativePhoneInput, { PickerData } from 'react-native-phone-input'
import BasePhoneInput, {
  ReactNativePhoneInputProps
} from 'react-native-phone-input'
import { RawTextInput } from './TextInput'
import { useStyles } from './TextInput.styles'

export type PhoneInputRef = RefObject<ReactNativePhoneInput>

export const PhoneInput = forwardRef<
  ReactNativePhoneInput,
  ReactNativePhoneInputProps
>((props: ReactNativePhoneInputProps, forwardedRef) => {
  const styles = useStyles({ multiline: false })
  const { ref, getCurrent: getPhone } = useWrappedRef(forwardedRef)
  const [options, setOptions] = useState<PickerData[]>([] as PickerData[])
  const [showPicker, setShowPicker] = useState(false)

  const onPressFlag = () => {
    const data = getPhone()?.getPickerData()

    if (data) {
      setOptions(data as unknown as PickerData[])
      setShowPicker(true)
    }
  }

  return (
    <>
      {options.length ? (
        <DropDown
          close={() => setShowPicker(false)}
          items={options.map(({ label, dialCode, iso2 }) => ({
            textAlign: 'left',
            label: `${dialCode}   ${label}`,
            onSelect: () => getPhone()?.selectCountry(iso2),
            tight: true
          }))}
          isShown={showPicker}
        />
      ) : null}

      <BasePhoneInput
        ref={ref}
        onPressFlag={onPressFlag}
        style={[styles.container, styles.phoneContainer]}
        initialCountry="gb"
        textComponent={RawTextInput}
        textStyle={[styles.text, styles.phoneText]}
        {...props}
      />
    </>
  )
})

export const usePhoneInputRef = () => useRef<ReactNativePhoneInput>(null)
