import { DefaultLayout } from 'layouts/DefaultLayout/DefaultLayout'
import { FunctionComponent } from 'react'
import { Subtitle, Title } from 'components/Heading/Heading'
import { BulletList } from 'components/BulletList/BulletList'
import { Spacer } from 'components/Spacer/Spacer'
import { Logo } from 'components/Logo/Logo'
import { Button } from 'components/Button/Button'
import { useNavigation } from 'hooks/useNavigation'
import headImage from './assets/head-image.png'
import { IconType } from 'components/Icon/Icon.types'

export const LandingView: FunctionComponent = () => {
  const { navigate } = useNavigation()

  return (
    <DefaultLayout headImage={{ source: headImage }}>
      <DefaultLayout.Content>
        <Logo />
        <Spacer size={2} />

        <Title center>Dungeon Minion</Title>
        <Subtitle center>
          AI art and content generation for your fantasy campaign
        </Subtitle>
        <Spacer size={4} />

        <BulletList>
          <BulletList.Item>Generate characters</BulletList.Item>
          <BulletList.Item>Generate landscapes</BulletList.Item>
          <BulletList.Item>Share assets with your team</BulletList.Item>
          <BulletList.Item>Keep campaign notes</BulletList.Item>
        </BulletList>
      </DefaultLayout.Content>

      <DefaultLayout.Footer>
        <Button
          outline
          onPress={() => navigate('SignIn')}
          iconLeft={IconType.SignIn}
        >
          Sign in
        </Button>

        <Spacer />

        <Button
          type="primary"
          onPress={() => navigate('SignUp')}
          iconLeft={IconType.Plus}
        >
          Sign up
        </Button>
      </DefaultLayout.Footer>
    </DefaultLayout>
  )
}
