import headImage from './assets/head-image.png'
import { getStatusScreen } from 'components/StatusScreen/StatusScreen'

export const AppSuspendedView = getStatusScreen({
  headImage,
  defaultScreenOptions: {
    title: 'App down',
    subtitle: 'Please bear with us',
    text: [
      'The app is currently not running.',
      'It may be due to maintainance or for a suspension of service.',
      'Please check the website for more information.'
    ].join('\n')
  }
})
