import { FunctionComponent } from 'react'
import { View, ViewProps } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

export const AbsoluteCenter: FunctionComponent<
  ViewProps & { safe?: boolean }
> = ({ safe, ...props }) => {
  const Component = safe ? SafeAreaView : View

  return (
    <Component
      {...props}
      style={[
        {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        },
        props.style
      ]}
    />
  )
}
