import { FadingMaskViewType } from 'components/FadingMaskView/FadingMaskView'
import { Z_INDEX } from 'constants/ui'
import { themed } from 'hooks/useTheme'
import { ImageStyle, Platform } from 'react-native'
import { ViewStyle } from 'react-native-phone-input'

interface Styles {
  container: ViewStyle
  mask: ViewStyle
  image: ImageStyle
  maskType: FadingMaskViewType
  dropDownButton: ViewStyle
  dropDownButtonContainer: ViewStyle
  clickTrapSpacing: number
}

export const { use: useStyles } = themed<Styles, { contentRelevant: boolean }>(
  ({ media: { isTablet }, sizes }, { contentRelevant }): Styles => ({
    clickTrapSpacing: Platform.select({ default: 50, web: 0 }),

    container: {
      width: '100%',
      height: '100%',
      maxHeight: contentRelevant ? 350 : undefined,
      position: 'absolute',
      overflow: 'hidden',
      top: 0,
      zIndex: Z_INDEX.HEAD_IMAGE,
      alignSelf: 'center',

      ...(isTablet && !contentRelevant
        ? { maxHeight: undefined, opacity: 0.2, filter: 'blur(10px)' }
        : {})
    },

    mask: {
      position: 'absolute',
      top: 0,

      ...(!isTablet ? { width: '100%', height: '100%', maxHeight: 350 } : {}),

      ...(isTablet && contentRelevant
        ? {
            width: 400,
            height: 360,
            alignSelf: 'center',
            alignItems: 'center'
          }
        : {})
    },

    image: {
      width: '100%',
      aspectRatio: 1,
      left: 0,
      top: 0,
      position: 'absolute'
    },

    maskType: isTablet
      ? contentRelevant
        ? 'box'
        : 'none'
      : contentRelevant
      ? 'abrupt'
      : 'linear',

    dropDownButtonContainer: {
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      flexDirection: 'row',
      justifyContent: 'center',
      zIndex: Z_INDEX.FOREGROUND
    },

    dropDownButton: {
      padding: 0,
      height: '100%',
      width: sizes.smallContentWidth,
      maxWidth: '100%'
    }
  })
)
